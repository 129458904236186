import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../../containers/AlternativeNavbar/Navbar';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { IconContext } from 'react-icons/lib';
import { GiReceiveMoney } from 'react-icons/gi';
import { FaHandHoldingWater } from 'react-icons/fa';
import { ImPriceTag } from 'react-icons/im';
import { CgTimelapse } from 'react-icons/cg';
import { withStyles } from '@material-ui/core/styles';
import { changeCookieShow } from '../../services/users/userReducer';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import classes from './PageWrapper.module.css';
import AddressPage from '../AddressPage/AddressPage';
import DrawingPage from '../DrawingPage/DrawingPage';
import RoofSlopePage from '../RoofSlopePage/RoofSlopePage';
import { Helmet } from "react-helmet";
import InvoiceInfoPage from '../InvoiceInfoPage/InvoiceInfoPageRedux';
import SummaryInfoComp from '../SummaryPage/SummaryInfoComp';
import ContactInfoComp from '../SummaryPage/ContactInfoComp';
import QuoteSent from '../QuoteSentPage/QuoteSent';
import Footer from '../../containers/UserPageFooter/UserPageFooter';
import StepProgress from '../../components/StepProgress';
import i18n from '../../i18n';

const StyledAlert = withStyles({
  root: {
    backgroundColor: 'var(--primary-color)'
  }
})(MuiAlert);

function Alert(props) {
    return <StyledAlert elevation={6} variant="filled" {...props} />;
  }

  export default function PageWrapper(props) {
    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const { cookieShow } = useSelector(state => state.users);
    const { firmaKodu, cookiesLink, firstScreenAtEnd } = useSelector(state => state.companyInfo);
    const { stepNumber, lastInfo } = useSelector(state => state.steps);
    const { yillikKazanc, yaklasikMaliyet, yaklasikAmortisman, amortismanYilAy} = useSelector(state => state.summary);
    const { module } = useSelector(state => state.module);
    const [cookieShowState, setCookieShow] = React.useState(!cookieShow);
  
    const willMount = useRef(true);
    if (willMount.current) {
      willMount.current = false;
      if (JSON.parse(localStorage.getItem('loggedIn'))) {
        dispatch(changeStep({stepNumber: 1}));
      }
    }
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setCookieShow(false);
      dispatch(changeCookieShow({ cookieShow: true }))
    };

    const stepSettings = {
      "firstContactThenSummary": {
        "first": 4,
        "second": 6
      },
      "firstSummaryThenContact": {
        "first": 6,
        "second": 6
      }
    }

    const stepSettingsCompany = () => {
      if (firstScreenAtEnd === "contact") {
        return stepSettings.firstContactThenSummary
      } else {
        return stepSettings.firstSummaryThenContact
      }
    }

    const stepperClasses = () => {
      if (firstScreenAtEnd === "contact") {
        return classes.stepWrapperLast
      } else {
        return classes.stepWrapperLastQuoteSent
      }
    }

    const heightController = () => {
        return classes.stepWrapper
    }
    
    return (
      <>
        <main className={classes.mainWrapper}>
          <Navbar/>
          <h1 className={classes.headerOne}>{t('ProgressBar.header')}</h1>
          <div className={classes.outerWrapper}>
              <div className={classes.wrapper}>
                  <div className={classes.solarCalculator}>
                    <div className={classes.anotherWrapper}>
                      <div className={classes.motionDiv}>
                        <div className={stepNumber !== stepSettingsCompany().first ? heightController() : stepperClasses() }>
                          {stepNumber !== stepSettingsCompany().second &&
                          <div className={classes.stepper}>
                            <div style={{
                              margin: 0,
                              minWidth: 0,
                              height: '100%',
                              width: '100%',
                              backgroundColor: 'white'
                            }}>
                              <div className={classes.leftStepper}>
                                <img src="/images/sun.png" style={{width: '40px' }} />
                                <StepProgress />
                                <img src="/images/solar-panel-home.png" style={{width: '40px' }} />
                              </div>
                            </div>
                            <div className={stepNumber === 3 ? classes.rightStepperAnimated : classes.rightStepper}>
                              <div className={classes.costSavingWrapper}>
                                <IconContext.Provider value={{ color: '#ffffff', size: 30 }}>
                                  <FaHandHoldingWater />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  <span className={classes.costSavingText}>{yillikKazanc} lt</span>
                                  <span className={classes.costSavingText2}>Günlük Sıcak Su İhtiyacı</span>
                                </div>
                              </div>
                            </div>
                          </div>}
                          {stepNumber === 0 && <AddressPage />}
                          {stepNumber === 1 && <DrawingPage />}
                          {stepNumber === 2 && <RoofSlopePage />}
                          {stepNumber === 3 && <InvoiceInfoPage />}
                          {stepNumber === 4 && <SummaryInfoComp />}
                          {stepNumber === 5 && <ContactInfoComp />}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <Footer />
        </main>
        
    </>
    )
  }