import React, { useState , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../components/axios/axiosApi';
import {
    changeIsim,
    changeMail,
    changeSoyisim,
    changeTelefon,
    changeEndUserCompanyName,
    changeVKN,
    changeTCKN,
} from '../../services/products/product/summaryReducer';
import {
    changeDetailedAdress
} from '../../services/products/product/reducer';
import {
    Box,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Container,
    TextField,
    Divider,
    InputAdornment,
    IconButton,
    Snackbar,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    withStyles
} from '@material-ui/core';
import _ from 'lodash';
import i18n from '../../i18n';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MuiAlert from '@material-ui/lab/Alert';
import NavbarMain from '../../containers/NavbarMainPage/NavbarMain';
import classes from './UserAccountPage.module.css';
import MaskedInput from 'react-text-mask';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['0', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

const StyledDialogTitle = withStyles({
    root: {
      fontSize: '1rem',
      fontWeight: 'bold',
      paddingBottom: 0
    }
  })(DialogTitle);

const Account = () => {

    const dispatch = useDispatch();
    const { t, } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [snackbarState, setSnackbarState] = useState(false);
    const [snackbarType, setSnackbarType] = useState('warning');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [contactInfo, setContactInfo] = useState({});
    const [contactInfoValues, setContactInfoValues] = useState({});

    const mapContactInfo = (value) => {
        switch (value) {
          case 'name':
            return 'isim';
          case 'surname':
            return 'soy_isim';
          case 'phoneNumber':
            return 'telefon';
          case 'companyName':
            return 'customer_firm';
          case 'referenceCode':
            return 'subcon_reference_code';
          default:
            return value;
        }
      };

    const inputChangeHandler = (event) => {
        const value = event.target.value;
        // checkValidity(value, event.target.name);
        setContactInfoValues({
            ...contactInfoValues,
            [event.target.name]: value
        });
    }

    const [passwords, setPasswords] = useState({
        currentPassword: '',
        showCurrentPassword: false,
        newPassword: '',
        showNewPassword: false,
        newPasswordConfirm: '',
        showNewPasswordConfirm: false,
      });

    const handleChangePassword = (event) => {
        setPasswords({
            ...passwords,
            [event.target.name]: event.target.value
        });
    };

    const handleClickShowPassword = (prop) => {
        setPasswords({
            ...passwords,
            [prop]: !passwords[prop]
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const fetchUserInfo = async () => {
        let conctInfoTemp = {};

        await axiosInstance.get(`input/get_company/?is_must_inputs=1`)
            .then((response) => {
                if (response.data.status) {
                setContactInfo(response.data.active_inputs);
                conctInfoTemp = response.data.active_inputs;
                }
            })
            .catch((error) => console.log(error));

        await axiosInstance.get('/customer/fetch_profile_info/?format=json')
            .then(response => {
                const data = response.data.data;
                let contactInfoValuesTemp = {};

                conctInfoTemp.map(tempContact => {
                contactInfoValuesTemp[tempContact.title] = data[mapContactInfo(tempContact.title)];
                });

                setContactInfoValues(contactInfoValuesTemp);

                if (data.hasOwnProperty('isim') && data.isim) dispatch(changeIsim({ isim: data.isim }));
                if (data.hasOwnProperty('soy_isim') && data.soy_isim) dispatch(changeSoyisim({ soyisim: data.soy_isim }));
                if (data.hasOwnProperty('telefon') && data.telefon) dispatch(changeTelefon({ telefon: data.telefon }));
                if (data.hasOwnProperty('tckn') && data.tckn) dispatch(changeTCKN({ tckn: data.tckn }));
                if (data.hasOwnProperty('vkn') && data.vkn) dispatch(changeVKN({ vkn: data.vkn }));
                if (data.hasOwnProperty('address') && data.address) dispatch(changeDetailedAdress({ detailedAdress: data.address }));
                if (data.hasOwnProperty('username') && data.username) dispatch(changeMail({ mail: data.username }));
            })
            .catch(error => {
                console.log(error);
            })
    };

    const handleChangePw = () => {
        // http://api.localhost/settings/save?domain=localhost&variables=[mail_gonder|maliyet_x|maliyet_y|maliyet_z|mw_alan|panel_gucu|kayip_yuzdesi|co2_katsayi|agac_katsayi|komisyon_orani]
        axiosInstance.post('change_pw?format=json', {
          new_password: passwords.newPassword,
          new_password_confirm: passwords.newPasswordConfirm,
          current_password: passwords.currentPassword,
        })
          .then((response) => {
            setOpen(false);
            if (response.data.status) {
              setSnackbarMessage(response.data.message);
              setSnackbarType('success');
              setSnackbarState(true);
            } else {
              setSnackbarMessage(response.data.message);
              setSnackbarType('warning');
              setSnackbarState(true);
            }
          })
          .catch(() => {
            setOpen(false);
            setSnackbarMessage('Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz.');
            setSnackbarType('warning');
            setSnackbarState(true);
          });
      };

    const updateUserInfo = async () => {
        const contactInfoValuesKeys = Object.keys(contactInfoValues);
        const sendData = {};
        contactInfoValuesKeys.map(key => {
        sendData[mapContactInfo(key)] = contactInfoValues[key];
        });

        await axiosInstance.post('/customer/save_profile_info/?format=json', sendData)
        .then((response) => {
            if (response.data.status) {
                if (contactInfoValues.hasOwnProperty("name") && contactInfoValues.name) dispatch(changeIsim({ isim: contactInfoValues.name }));
                if (contactInfoValues.hasOwnProperty("surname") && contactInfoValues.surname) dispatch(changeSoyisim({ soyisim: contactInfoValues.surname }));
                if (contactInfoValues.hasOwnProperty("phoneNumber") && contactInfoValues.phoneNumber) dispatch(changeTelefon({ telefon: contactInfoValues.phoneNumber }));
                if (contactInfoValues.hasOwnProperty("address") && contactInfoValues.address) dispatch(changeDetailedAdress({ detailedAdress: contactInfoValues.address }));
                if (contactInfoValues.hasOwnProperty("companyName") && contactInfoValues.companyName) dispatch(changeEndUserCompanyName({ endUserCompanyName: contactInfoValues.companyName }));
                if (contactInfoValues.hasOwnProperty("vkn") && contactInfoValues.vkn) dispatch(changeVKN({ vkn: contactInfoValues.vkn }));
                if (contactInfoValues.hasOwnProperty("tckn") && contactInfoValues.tckn) dispatch(changeTCKN({ tckn: contactInfoValues.tckn }));
                setSnackbarMessage(response.data.message);
                setSnackbarType('success');
                setSnackbarState(true);
            } else {
                setSnackbarMessage(response.data.message);
                setSnackbarType('warning');
                setSnackbarState(true);
            }
        })
        .catch(error => {
            setSnackbarMessage('Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz.');
            setSnackbarType('warning');
            setSnackbarState(true);
        })
    };

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
      };
    

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackbarState(false);
      };

    useEffect(() => {
        fetchUserInfo();
    }, []);

    return (
    <>
        <Helmet>
            <title>{t('ProfilePage.cardTitle')}</title>
        </Helmet>
        <div className={classes.outerWrapper}>
            <NavbarMain />
            <Container 
                maxWidth={true}
                style={{ height: '100%', backgroundColor:'#F4F6F8', paddingTop:'40px' }}
            >
                <Grid
                container
                spacing={3}
                >
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                    >
                        <form
                            autoComplete="on"
                            noValidate
                            >
                            <Card>
                                <CardHeader
                                subheader={t('ProfilePage.cardSubHeader')}
                                title={t('ProfilePage.cardTitle')}
                                />
                                <Divider />
                                <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    {!(_.isEmpty(contactInfo)) && contactInfo.map((contactItem, index) => (
                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            xl={6}
                                            xs={12}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span>{(localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en') ? contactItem.name_en : contactItem.name_tr}</span>
                                                <TextField 
                                                    fullWidth
                                                    name={contactItem.title}
                                                    type={contactItem.type}
                                                    id={contactItem.autocomplete}
                                                    autoComplete={contactItem.autocomplete}
                                                    onChange={(event) => inputChangeHandler(event)}
                                                    value={contactInfoValues[contactItem.title]}
                                                    /* helperText={!validity[contactItem.title] ? contactItem.placeholder_tr : ""}
                                                    error={!validity[contactItem.title]} */
                                                    variant="outlined"
                                                    size="small"
                                                    InputProps={contactItem.title === "phoneNumber" ? {inputComponent: TextMaskCustom} : {}}
                                                    inputComponent={contactItem.title === "phoneNumber" ? TextMaskCustom : {}}
                                                />
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                                </CardContent>
                                <Divider />
                                <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    padding: 15
                                }}
                                >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => updateUserInfo()}
                                >
                                    {t('ProfilePage.save')}
                                </Button>
                                </div>
                            </Card>
                        </form>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xl={12}
                        xs={12}
                    >
                        <Card>
                        <CardHeader
                            title={t('ProfilePage.updatePassword')}
                        />
                        <Divider />
                        <CardContent>
                            <Grid
                            container
                            spacing={3}
                            >
                            <Grid
                                item
                                md={4}
                                lg={4}
                                xs={12}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{t('ProfilePage.currentPasswordLabel')}</span>
                                    <TextField
                                        fullWidth
                                        name="currentPassword"
                                        onChange={handleChangePassword}
                                        required
                                        value={passwords.currentPassword}
                                        variant="outlined"
                                        type={passwords.showCurrentPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('showCurrentPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {passwords.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                lg={4}
                                xs={12}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{t('ProfilePage.newPasswordLabel')}</span>
                                    <TextField
                                    fullWidth
                                    name="newPassword"
                                    onChange={handleChangePassword}
                                    required
                                    value={passwords.newPassword}
                                    variant="outlined"
                                    type={passwords.showNewPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showNewPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                            >
                                            {passwords.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                lg={4}
                                xs={12}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{t('ProfilePage.newPasswordCheckLabel')}</span>
                                    <TextField
                                        fullWidth
                                        name="newPasswordConfirm"
                                        onChange={handleChangePassword}
                                        required
                                        value={passwords.newPasswordConfirm}
                                        variant="outlined"
                                        type={passwords.showNewPasswordConfirm ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('showNewPasswordConfirm')}
                                                onMouseDown={handleMouseDownPassword}
                                                >
                                                {passwords.showNewPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            </Grid>
                        </CardContent>
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            p={2}
                        >
                            <div>
                            <Button
                                color="primary"
                                endIcon={<ArrowRightIcon />}
                                size="small"
                                variant="contained"
                                onClick={handleClickOpen}
                            >
                                {t('ProfilePage.update')}
                            </Button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('ProfilePage.updatePasswordQuestion')}</StyledDialogTitle>
                                <DialogContent>
                                <p style={{ padding: '8px 0' }}>
                                {t('ProfilePage.updatePasswordText')}
                                </p>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                {t('ProfilePage.back')}
                                </Button>
                                <Button onClick={handleChangePw} color="primary" variant="contained">
                                {t('ProfilePage.update')}
                                </Button>
                                </DialogActions>
                            </Dialog>
                            </div>
                        </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    </>
    );
}

export default Account;