import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../../../../containers/AlternativeNavbar/Navbar';
import { useSelector } from 'react-redux';
import classes from './KVKKPagesStyle.module.css';
import { Helmet } from "react-helmet";
import Footer from '../../../../containers/AlternativeFooter/Footer';
import testCompanyInfo from './testData';


export default function PageWrapper(props) {
  const { t, } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    companyName,
    companyPhone,
    companyMail,
    companyAddress,
    vergiDairesi,
    vergiNo,
    mersisNo,
    companyContactPerson
  } = useSelector(state => state.companyInfo);

  return (
    <>
      <Helmet>
      <title>Çerezler ve Çerez Kullanımı İzinleri Hakkında Bildirim</title>
      </Helmet>
      <main className={classes.mainWrapper}>
        <Navbar/>
        <h2 className={classes.headerOne}>Çerezler ve Çerez Kullanımı İzinleri Hakkında Bildirim</h2>
        <div className={classes.outerWrapper}>
          <h2>Çerezler ve Çerez Kullanımı İzinleri Hakkında Bildirim</h2>
          <p className={classes.standartParagraph}>“{companyAddress}” adresinde kurulu bulunan {companyName} (Kısaca “<span className={classes.boldItalicSpan}>ŞİRKET</span>” olarak anılacaktır) olarak bizim tarafımızdan size sağlanan ve yönetilen {window.location.host} adresli internet sitesi ve/veya mobil uygulama ve sair dijital alanların tamamı (“<span className={classes.boldItalicSpan}>Hizmetler</span>”) çerçevesinde olmak üzere sizlerin veri gizliliği ile ilgili endişelerinize saygı duyuyor ve de bu süreçteki sorularınıza da yanıt olması açısından işbu bildirim metnini dikkatle okumanızı rica ediyoruz.</p> 
          <p className={classes.standartParagraph}>Sizlerin <span className={classes.boldItalicSpan}>Hizmetler</span> üzerindeki deneyimlerinizi zenginleştirebilmemize ve bizim de söz konusu <span className={classes.boldItalicSpan}>Hizmetler</span>’imizi daha da geliştirmemize ve iyileştirmemize yardımcı olacak bilgileri toplayabilmek için <span className={classes.boldItalicSpan}>Hizmetler</span> üzerinde değişik teknolojilerden yararlanıyoruz. <span className={classes.boldItalicSpan}>ŞİRKET</span> olarak kullandığımız çerezler, <span className={classes.boldItalicSpan}>Hizmetler</span>’imizin çalışmasına olanak sağlamakta ve de sizlerin her biri için hangi bilgilerin ve reklâmların en yararlı olduğunu anlamamıza yardımcı olmaktadır.</p>
          <p className={classes.boldItalicUnderlineParagraph}>Bilgilerinizi kim topluyor?</p>
          <p className={classes.standartParagraph}>Çerezler ve başka izleme teknolojileri vasıtasıyla <span className={classes.boldItalicSpan}>ŞİRKET</span>’e sizler tarafından açıklanan/iletilen/gönderilen veya <span className={classes.boldItalicSpan}>ŞİRKET</span>’in topladığı/sakladığı/aldığı/kabul ettiği kişisel bilgiler, <span className={classes.boldItalicSpan}>ŞİRKET</span>’in kontrolü altında olacaktır. <span className={classes.boldItalicSpan}>Hizmetler</span>’i kullanmakla, <span className={classes.boldItalicSpan}>ŞİRKET</span>’in işbu “Çerezler ve Çerez Kullanımı İzinleri Hakkında Bildirim” metnindeki ve de başta “Kullanım Şartları Sözleşmesi” olmak üzere <span className={classes.boldItalicSpan}>Hizmetler</span> içerisinde yer alabilecek diğer tüm kural, koşul, yönlendirme, yardım, açıklama, bilgilendirme ve sair metinlerin tamamındaki (Kısaca “Genel Şartlar” olarak  anılacaktır) hükümlerin içeriğinin sizler tarafından kabul edildiğini beyan ve taahhüt edersiniz. Böylelikle de bizim “Kişisel Verilerin Korunması ve İşlenmesi Politikası” metnimize uygun olarak ilgili teknolojileri ve de çerezleri kullanmamıza ve bu yolla kişisel verilerinizi işlememize şimdiden açık şekilde rıza göstermiş sayılırsınız.</p> 
          <p className={classes.standartParagraph}>Bu Çerezler Hakkında Bildirim, Facebook, Apple, Google, YouTube veya benzeri uluslararası platformlarda bulunan ve <span className={classes.boldItalicSpan}>ŞİRKET</span> tarafından veya adına işletilen başta işbu kullanmakta olduğunuz <span className={classes.boldItalicSpan}>Hizmetler</span>’e ve de diğer tüm söz konusu olan/olabilecek uygulamalara, dijital alanlara, sitelere ve de sayfalara da uygulanır. Bu Bildirimde “<span className={classes.boldItalicSpan}>ŞİRKET</span>” şirketine yapılan atıflar, sizin etkileşimde ve iletişimde bulunduğunuz tüm <span className={classes.boldItalicSpan}>ŞİRKET</span> ortak şirketlerini, iştiraklerini, bağlı şirketlerini, ortak girişim şirketlerini ve de imtiyaz alan sair şirketleri de kapsamaktadır.</p> 
          <p className={classes.standartParagraph}><span className={classes.boldItalicSpan}>Hizmetler</span>’i hali hazırda kullanmaya başlamış olmakla, “Çerezler ve Çerez Kullanımı İzinleri Hakkında Bildirim” ve “Kişisel Verilerin Korunması ve İşlenmesi Politikası” metinlerimize tam olarak uygun şekilde olmak üzere ilgili sair çerezleri kullanmamıza şimdiden açık rıza göstermiş olmaktasınız. Çerezleri anılan yol, şekil ve amaçlarla kullanmamızı kabul etmiyorsanız, muhtemel ayarlarınızı ve izinlerinizi buna göre düzenlemelisiniz ve fakat çerezlerin herhangi bir şekilde devreden çıkartılması ya da ilgili teknolojilere ait izinlerin etkisizleştirmesi/geri çekilmesi haleri sizlerin <span className={classes.boldItalicSpan}>Hizmetler</span> üzerindeki kullanıcı deneyiminizi olumsuz etkileyebilir ya da <span className={classes.boldItalicSpan}>Hizmetler</span>’i sizin tarafınızdan tamamen kullanılmaz hale getirebilir.</p> 
          <p className={classes.standartParagraph}>Aşağıdaki bölüm, <span className={classes.boldItalicSpan}>Hizmetler</span>’imizde kullanmakta olduğumuz farklı çerez tiplerini, bunların her birinin kullanım amacıyla birlikte özetlemekte ve size bu çerezleri yönetme kabiliyetini kazandırmaktadır.</p>
          <p className={classes.standartParagraph}><span className={classes.boldItalicSpan}>ŞİRKET</span>, sizin şahsi verilerinizi ancak ve sadece bunu yapması adil ve yasalara uygun olduğu takdirde toplayacak, kullanacak veya ifşa edecektir.</p> 
          <p className={classes.boldItalicUnderlineParagraph}>Çerez kelimesi ne anlama gelmektedir?</p>
          <p className={classes.standartParagraph}>Çerezler, piksel etiketleri ve benzeri teknolojiler (hepsi birlikte ‘çerezler’ olarak anılmaktadırlar), bir <span className={classes.boldItalicSpan}>Hizmetler</span>’i kullandığınızda – bilgisayarınız, akıllı telefonunuz veya tabletiniz gibi – internet bağlantılı cihazınıza indirilen küçük miktarlarda bilgileri içeren dosyalardır. Çerezler, sizin tercihlerinizi hatırlamak, sizin internet deneyiminizi genel olarak iyileştirmek ve <span className={classes.boldItalicSpan}>Hizmetler</span>’i size en iyi şekilde sunmamızda bize yardımcı olmak gibi pek çok farklı ve faydalı işleri yaparlar.</p> 
          <p className={classes.standartParagraph}>Pek çok farklı çerez tipi bulunmaktadır. Bu çerezlerin tümü aynı biçimde çalışırlar, fakat aralarında bazı ufak farklılıklar da bulunmaktadır.</p> 
          <p className={classes.boldItalicUnderlineParagraph}>Çerezleri hangi amaçla kullanıyoruz?</p>
          <p className={classes.standartParagraph}>Çerezleri, <span className={classes.boldItalicSpan}>Hizmetler</span>’in kullanılmasını daha kolay bir hale dönüştürmek, <span className={classes.boldItalicSpan}>Hizmetler</span>’de size kişiselleştirilmiş bir deneyim yaşama olanağı sağlamak ve <span className={classes.boldItalicSpan}>Hizmetler</span>’i sizin gereksinim ve ilgi alanlarınıza daha iyi uyarlamak amaçlarıyla kullanıyoruz. Çerezler, sizin <span className={classes.boldItalicSpan}>Hizmetler</span> üzerinde gelecek faaliyetlerinizi ve deneyimlerinizi hızlandırmamıza yardımcı olmak için de kullanılmaktadırlar.</p> 
          <p className={classes.standartParagraph}>Sizin kullanılmasına rıza gösterdiğiniz çerezler, aynı zamanda, size ait şahsi verileri toplamak için de kullanılmaktadır. Daha sonra, bu veri ve bilgileri, sizin ilgi alanlarınıza uyarlanmış hedefe yönelik tanıtımlar sunabileceğimiz ve sizin bir promosyonel içeriği gördüğünüz tekrar sayısını sınırlandırabileceğimiz bir biçimde izleyicilere göre profillendirebiliyoruz.</p> 
          <p className={classes.standartParagraph}>Çerezleri, içeriğimizi ve <span className={classes.boldItalicSpan}>Hizmetler</span>’i geliştirmek amacıyla e-postalara ve haber bültenlerine de sokabiliyoruz.</p> 
          <p className={classes.standartParagraph}>Son olarak, çerezleri insanların <span className={classes.boldItalicSpan}>Hizmetler</span>’i nasıl kullandıklarını anlamamıza olanak sağlayan ve <span className={classes.boldItalicSpan}>Hizmetler</span>’in yapısı ve içeriğini zenginleştirmemize yardımcı olan ve tanıtıma dair kampanyaların <span className={classes.boldItalicSpan}>Hizmetler</span> üzerindeki etkinliğini ölçmemize de yardım eden anonim ve birleştirilmiş istatistiksel veriler derlemek amacıyla da kullanabiliyoruz.</p> 
          <p className={classes.boldItalicUnderlineParagraph}>Çerezleri nasıl kontrol edebilirim veya silebilirim?</p>
          <p className={classes.standartParagraph}>Çerezlerinizi/izinlerinizini yönetmek için kullanabileceğiniz pek çok yol vardır:</p> 
          <ul>
            <li>Rızanızı vermeyi reddedebilirsiniz;</li>
            <li>Ayarlarınızı kullanarak <span className={classes.boldItalicSpan}>ŞİRKET</span> veya üçüncü şahıs çerezlerini/izinlerini devreden çıkartabilir ve etkisizleştirebilirsiniz.</li>
          </ul>
          <p className={classes.boldItalicUnderlineParagraph}>Cihaz ayarlarınız vasıtasıyla kontrol</p>
          <p className={classes.standartParagraph}>Cihazların çoğu ilk başta çerezleri/izinleri otomatik olarak kabul edecek şekilde ayarlanmış ve kurgulanmışlardır. Çerezler için vermiş bulunduğunuz rıza beyanını da cihazınıza daha önce yüklenmiş ve depolanmış bulunan çerezleri silerek geri çekebilirsiniz. </p>
          <p className={classes.standartParagraph}>Ancak kullandığımız çerezleri devreden çıkartır veya ilgili izinleri etkisizleştirirseniz, <span className={classes.boldItalicSpan}>Hizmetler</span>’de bulunduğunuz süre içindeki deneyiminiz bundan etkilenebilir, örneğin, <span className={classes.boldItalicSpan}>Hizmetler</span>’in belirli alanlarını ziyaret edemeyebilirsiniz ya da <span className={classes.boldItalicSpan}>Hizmetler</span>’i kullandığınızda kişiselleştirilmiş bilgiler almayabilirsiniz.</p>
          <p className={classes.standartParagraph}><span className={classes.boldItalicSpan}>Hizmetler</span>’i görüntülemek ve ona erişmek için (örneğin, bilgisayarınız, akıllı telefonunuz veya tabletiniz gibi) farklı cihazlar kullanırsanız, bu cihazların her birinin sizin çerez ve sair izin tercihlerinize göre ayarlanmış olduğundan emin olmanız gerekir.</p> 
          <p className={classes.standartParagraph}>Ayarlarınızı ve çerezlerinizi/izinlerinizi değiştirmek için kullanabileceğiniz prosedürler, cihazdan cihaza da farklılık gösterebilmektedir.</p> 
          <p className={classes.boldItalicUnderlineParagraph}>Hangi çerezleri kullanıyoruz?</p>
          <p className={classes.standartParagraph}><span className={classes.boldItalicSpan}>Hizmetler</span>’de kullanılan çerezler genelde aşağıdaki gibi sınıflandırılabilirler:</p>
          <ul>
            <li>Gerekli Çerezler: Bu çerezler, <span className={classes.boldItalicSpan}>Hizmetler</span>’in doğru ve düzgün çalışmasını temin etmek için şarttırlar; bizim <span className={classes.boldItalicSpan}>Hizmetler</span>’de gezinmenize ve özelliklerimizi kullanmanıza olanak sağlarlar. Bunun örnekleri arasında, aynı oturumdaki bir sayfaya geri giderken daha önceki eylemlerin (örneğin, daha önce girilen metin) hatırlanmasından bahsedebiliriz.</li>
            <li>Bu çerezler şahsi veri ve bilgiler topluyorlar mı / beni tanıyor ve kimliğimi belirliyorlar mı? Bu çerezler, sizi ayrı bir kişi olarak tanımaz ve kimliğinizi belirlemezler. Bu çerezleri/izinleri kabul etmezseniz, bu durum ilgili <span className={classes.boldItalicSpan}>Hizmetler</span>’in veya kısımlarının performansını etkileyebilir.</li>
            <li>Performans Çerezleri: Bu çerezler, örneğin en sık hangi sayfalara gittiğiniz, <span className={classes.boldItalicSpan}>Hizmetler</span>’de ne kadar vakit geçirdiğiniz ve hata mesajları gibi karşılaştığınız sorunlar da dâhil <span className={classes.boldItalicSpan}>Hizmetler</span>’i nasıl kullandığınıza dair bilgiler toplarlar. Bu çerezler, aynı zamanda, başka bir kişi ya da kuruma bağlı bir kaynaktan gelip gelmediğinizin ve bizim bir hizmetimizi kullanıp kullanmadığınızın bağlı şirketlerce öğrenilmesini temin etmek amacıyla da kullanılmaktadır. Bu da bizim <span className={classes.boldItalicSpan}>Hizmetler</span>’in performansını artırmamıza ve geliştirmemize yardımcı olur.</li>
            <li>Bu çerezler şahsi veri ve bilgiler topluyorlar mı / beni tanıyor ve kimliğimi belirliyorlar mı? Bu çerezler, sizi ayrı bir kişi olarak tanımaz ve kimliğinizi belirlemezler. Bu çerezlerin topladığı tüm bilgiler birleştirilir ve bu sebeple anonimdir. Bu çerezler, sadece bir <span className={classes.boldItalicSpan}>Hizmetler</span>’in çalışmasının iyileştirilmesini temin etmek amacıyla kullanılırlar.</li>
            <li>İşlevsellik Çerezleri: Bu çerezler, <span className={classes.boldItalicSpan}>Hizmetler</span>’in size daha fazla kişiselleştirilmiş bir internet deneyimi sunabilmek gayesiyle, sizin yaptığınız seçim ve tercihleri (kullanıcı adınız, diliniz veya bulunduğunuz bölge gibi bilgiler) hatırlamalarına olanak sağlarlar. Benzer şekilde, bu çerezler, olası tekrarlardan kaçınmak için hangi özellikli servislerin, içeriklerin veya videoların görüntülendiğini izlemek ve sizin oyunlar oynamanıza ve bloglar, sohbet odaları ve forumlar gibi sosyal araçları kullanmanıza olanak sağlamak için de kullanılabilirler.  </li>
            <li>Bu çerezler şahsi veri ve bilgiler topluyorlar mı / beni tanıyor ve kimliğimi belirliyorlar mı? Bu çerezlerin topladığı bilgiler, sizin ifşa ettiğiniz ve açıkladığınız şahsi veri ve bilgileri de içerebilirler. Bu çerezleri kabul etmezseniz, bu tercihiniz <span className={classes.boldItalicSpan}>Hizmetler</span>’in performansını ve işlevselliğini etkileyebilir ve <span className={classes.boldItalicSpan}>Hizmetler</span>’deki içeriğe erişim haklarınızı kısıtlayabilir.</li>
            <li>Hedefleme ya da Reklâm Çerezleri: Bu çerezler, sizin için ve ilgi alanlarınız için daha önemli olan içerikleri size sunabilmek amacıyla kullanılırlar. Bunlar, aynı zamanda, hedefe yönelik reklâmlar sunabilmek ya da bir reklâmı gördüğünüz tekrar sayısını sınırlandırmak ve ayrıca, reklâm kampanyalarının <span className={classes.boldItalicSpan}>Hizmetler</span> üzerindeki etkinliğini ölçmemize yardımcı olmak için de kullanılırlar.  <span className={classes.boldItalicSpan}>Hizmetler</span>’i kullandığınızı hatırlarlar ve bu bilgi reklâmcılar ve bizim reklâm ajanslarımız da dâhil başka kişilerle de paylaşılır. Bu çerezler, üçüncü şahısların sağladıkları site işlevselliğine de bağlanabilirler.</li>
            <li>Bu çerezler şahsi veri ve bilgiler topluyorlar mı / beni tanıyor ve kimliğimi belirliyorlar mı? Bu çerezlerin çoğu tipleri, tüketicileri onların Cihaz ID veya IP adresi vasıtasıyla izlerler ve bu sebeple şahsi veri ve bilgiler de toplayabilirler.</li>
            <li>Üçüncü-Şahıs Çerezleri: Örneğin Facebook, Youtube ve Google gibi kendi alanlarında size uyarlanmış promosyonel tanıtım içeriğinin size sunabilmeleri için, <span className={classes.boldItalicSpan}>Hizmetler</span>’i kullandığınızda bizim adımıza sizin cihazınıza çerezler de yerleştirebilecek bir dizi iş ortağıyla çalışabilmekteyiz. Bu çerezleri kabul etmek isteyip istemediğinize karar verebilmeniz için bu çerezleri kullanılmadan önce belirlemek ve tanıtmak için çaba gösteriyoruz. Ayrıca, <span className={classes.boldItalicSpan}>Hizmetler</span>’de dijital deneyimler ve işlevsellikler sunmaları için de bir dizi iş ortağıyla çalışıyoruz. Örneğin, <span className={classes.boldItalicSpan}>Hizmetler</span>’i yüklediğinizde/kullandığınızda, bizim çerezlerimize rıza göstermemiş veya rızanızı geri çekmiş olsanız da <span className={classes.boldItalicSpan}>Hizmetler</span>’deki özelliklerin bir kısmını (örneğin bir YouTube videosu) sağlayan üçüncü şahıslar size çerezler sunabilir ve yerleştirebilirler. Bunun olmasının sebebi sizin doğrudan doğruya onların çerezlerinin kullanılmasına rıza göstermiş olmanızdır. Böyle durumlarda, doğrudan doğruya ilgili üçüncü şahsın <span className={classes.boldItalicSpan}>Hizmetler</span>’de rızanızı geri çekmeniz gerekir.   </li>
            <li>Bu çerezler şahsi veri ve bilgiler topluyorlar mı / beni tanıyor ve kimliğimi belirliyorlar mı? İlgili üçüncü şahsın kullandığı çerezlerin tipine bağlı olarak, bu çerezlerin topladıkları veri ve bilgiler şahsi veri ve bilgileri de içerebilir.</li>
          </ul>
          <p className={classes.boldItalicUnderlineParagraph}>Kullandığımız çerezin süresi</p>
          <p className={classes.standartParagraph}>Süre açısından, <span className={classes.boldItalicSpan}>Hizmetler</span>’de iki farklı tipte çerez kullanabiliriz:</p> 
          <ul>
            <li>Oturum Çerezleri: Bu çerezler, bizim <span className={classes.boldItalicSpan}>Hizmetler</span>’i terk ettiğiniz zamana kadar cihazınızda kalan geçici nitelikte çerezlerdir ya da</li>
            <li>Kalıcı Çerezler: Bu tip çerezler, cihazınızda çok daha uzun bir süre ya da siz onları manuel olarak silene kadar kalırlar. Çerezin sizin cihazınızda ne kadar süre boyunca kalacağı ise hem ilgili çerezin kendi ömür süresine hem de sizinşahsi cihaz ayarlarınıza bağlıdır.</li>
          </ul>
        </div>
        <Footer />
      </main>
  </>
  )
}