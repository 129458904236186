import React from 'react';
import {
  withStyles,
  InputBase,
  Select,
  MenuItem,
} from '@material-ui/core';

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const SelectComponent = (props) => {
  const {
    label,
    name,
    id,
    value,
    changeFuction,
    selectItemValues,
    selectItemLabels,
    width,
    ...rest
  } = props;
  return (
    <Select
      labelId={label}
      id={id}
      name={name}
      value={value}
      displayEmpty
      onChange={(e) => changeFuction(e.target.value)}
      label={label}
      input={<BootstrapSelect />}
      style={{ width: width, marginLeft: 20 }}
    >
      {selectItemValues.map((selectItemValue, idx) => (
        <MenuItem value={selectItemValue}>{selectItemLabels[idx]}</MenuItem>
      ))}
    </Select>
  );
}

export default SelectComponent;