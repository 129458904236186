import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import StepButton from '@material-ui/core/StepButton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import RingVolumeIcon from '@material-ui/icons/RingVolume';
import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 30,
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const StyledStepper = withStyles({
  root: {
    padding: 0,
    margin: '15px 0'
  }
})(Stepper);

const StyledStepLabel = withStyles({
    label: {
        fontFamily: 'var(--font-fam)',
        fontSize: '1rem'
    }
})(StepLabel);

const StyledStepLabelMobile = withStyles({
  label: {
      fontFamily: 'var(--font-fam)',
      fontSize: '0.85rem'
  }
})(StepLabel);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 70,
    height: 70,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: 'var(--secondary-color)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

const useColorlibStepIconStylesMobile = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: 'var(--secondary-color)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AddAPhotoIcon />,
    2: <RingVolumeIcon />,
    3: <PaymentIcon />,
    4: <AssignmentTurnedInIcon />,
    5: <WbSunnyIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function ColorlibStepIconMobile(props) {
  const classes = useColorlibStepIconStylesMobile();
  const { active, completed } = props;

  const icons = {
    1: <AddAPhotoIcon />,
    2: <RingVolumeIcon />,
    3: <PaymentIcon />,
    4: <AssignmentTurnedInIcon />,
    5: <WbSunnyIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

/* 
  Process Tracker Adımları
  1. Keşif Talebi
  2. Proje Detaylarını Netleştirme
  3. Paket Seçimi, Online Ödeme ve Sigorta
  4. Projenizin Yasal Onay Süreçleri
  5. Kurulum ve Devreye Alma

  Process Tracker Açıklamaları
  1. İletişim bilgilerinizle birlikte keşif talebinizi oluşturun.
  2. Proje detayları için ekibimiz size ulaşacak. 
  3. Size uygun olan güneş enerjisi paketinizi seçin, kredi kartı veya alışveriş kredisi ile online ödeme yapın ve yine sistem üzerinden online sigorta işlemlerini tamamlayın.
  4. Projenizin yasal onay süreçlerini sizin adınıza yürüteceğiz.
  5. Onayların ardından evinizin çatısına kurulumu gerçekleştirip devreye alacağız. Artık ürettiğiniz temiz enerji ile sürdürülebilir bir geleceğe yatırım yapmış olacaksınız.
*/

function getSteps(t, moduleType) {
  if (moduleType === 'home') {
    return [t('UserMainPage.firstStep'), t('UserMainPage.secondStep'), t('UserMainPage.thirdStep'), t('UserMainPage.fourthStep'), t('UserMainPage.fifthStep')];
  }
  return [t('UserMainPage.firstStep'), t('UserMainPage.secondStep'), t('UserMainPage.thirdStepIndustrial'), t('UserMainPage.fourthStep'), t('UserMainPage.fifthStep')];
}

function getStepContent(step, t, moduleType) {
  switch (step) {
    case 0:
      return t('UserMainPage.firstStepText');
    case 1:
      return t('UserMainPage.secondStepText');
    case 2:
      if (moduleType === 'home') {
        return t('UserMainPage.thirdStepText');
      }
      return t('UserMainPage.thirdStepTextIndustrial');
    case 3:
      return t('UserMainPage.fourthStepText');
    case 4:
      return t('UserMainPage.fifthStepText');
    default:
      return 'Unknown step';
  }
}

const convertUserStatustoStepNumber = (userStatus) => {
  switch (userStatus) {
    case 3:
      return 0
    case 4:
      return 1
    case 6:
      return 2
    case 7:
      return 3
    default:
      return 0;
  }
};

export default function CustomizedSteppers(props) {
  const { userStatus, moduleType } = props;
  const { t, } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(convertUserStatustoStepNumber(userStatus));
  const steps = getSteps(t, moduleType);

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      {isDesktopOrLaptop &&
      <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label, index) => (
          <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
              >
                <StyledStepLabel StepIconComponent={ColorlibStepIcon}>{label}</StyledStepLabel>
              </StepButton>
          </Step>
        ))}
      </Stepper>}
      {isTabletOrMobile &&
      <>
        <StyledStepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
                <StepButton
                  onClick={handleStep(index)}
                >
                  <StyledStepLabelMobile StepIconComponent={ColorlibStepIconMobile}></StyledStepLabelMobile>
                </StepButton>
            </Step>
          ))}
        </StyledStepper>
        <p style={{ textAlign: 'center', fontSize:'0.85rem', fontWeight: 'bold' }}>{steps[activeStep]}</p>
      </>
      }
      <p style={{ textAlign: 'center', fontSize: isTabletOrMobile ? '0.85rem' : '18px' }} >{getStepContent(activeStep, t, moduleType)}</p>
    </div>
  );
}