import React from 'react';

const Refund = () => {

  return (
    <div style={{
      maxWidth: '600px',
      minHeight: '500px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      margin: 'auto'
    }}
    >
      <p style={{fontSize: '20px'}}>Güneş Paketi için ödediğiniz ön ödeme tutarı iade edilmiştir. Sistem üzerinden tekrar satın alım / fizibilite yapmak istiyorsanız lütfen farklı bir mail ile kayıt olunuz.</p>
    </div>
  );
}

export default Refund;