import React, { useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../components/axios/axiosApi';
import classes from './SummaryPage.module.css';
import { IconContext } from 'react-icons/lib';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { changeStaticMapURL } from '../../services/products/product/reducer';
import { changeUserId, changeLoginToken, changeFirstSession, changeUserAPIStatus } from '../../services/users/userReducer';
import { changeSolarvisBannerState } from '../../services/products/product/moduleReducer';
import { changeStep } from '../../services/products/product/stepReducer';
import { FaSolarPanel, FaReceipt } from 'react-icons/fa';
import { RiBuilding2Fill, RiBattery2Fill } from 'react-icons/ri';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BsLightningFill } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import { BiArea } from 'react-icons/bi';
import { VscTriangleUp } from 'react-icons/vsc';
import RechartComposedLine from './RechartComposedLine';
import i18n from '../../i18n';
import {
  makeStyles, 
  Dialog, 
  DialogContent, 
  withStyles, 
  TableRow, 
  Table, 
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  InputBase,
  alpha,
 } from '@material-ui/core';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import InfoIcon from '@material-ui/icons/Info';
import MuiAlert from '@material-ui/lab/Alert';
import SpinnerDialog from '../../components/UI/SpinnerDialog/Spinner'; 
import download from 'downloadjs';
import materialsTestData from './testData';

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 600
  }
})(Dialog);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '70px'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  myAlert: {
    color: "#000000",
    backgroundColor: "#ffffff"
  },
  cell: {
    padding: 4
  },
  table: {
    minWidth: 300,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

function SummaryPage(props) {

    const MUIClasses = useStyles();
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});

    const [materials, setMaterials] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [collectorNumber, setCollectorNumber] = useState(0);

    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const [ loading, setLoading ] = useState(false);
    const { userId, loginToken, firstSession } = useSelector(state => state.users);
    const { roofArea, drawPoints, cityName, ilce, tempLatitude, tempLongitude, currentZoom, staticMapURL, polygonPoints } = useSelector(state => state.location);
    const { fatura, batarya, sarj, tesis, meskenTipi, roofTilt, sGucu, trafoGucu, isim, soyisim, telefon, mail, cephe, consumptionProfile } = useSelector(state => state.summary);
    const {
      module,
      availableModules,
    } = useSelector(state => state.module);

    const [feasibilityInfoBoxOpen, setFeasibilityInfoBoxOpen] = useState(false);

    const handleCloseFeasbilityInfoBox = () => {
      setFeasibilityInfoBoxOpen(false);
    };

    const [analysisData, setAnalysisData] = useState({
        panel: null,
        guc: null,
        acGuc: null,
        tlMaliyet: null,
        dolarMaliyet: null,
        uretim: null,
        amortisman: null,
        amortismanGonder: null,
        agac: null,
        co2: null,
        yillikTasarruf: null,
        dolar: null,
        aylikTuketim: null,
        yillikTuketim: null,
        yillikTasarrufUSD: null,
        amortismanYaklasik: null,
        amortismanYilAy: ['-', '-'],
        tlMaliyetYaklasik: null,
        tlMaliyetYaklasikKDVsiz: null,
        dolarMaliyetYaklasik: null,
        dolarMaliyetYaklasikKDVsiz: null,
        kredilendirilebilirMaliyetTL: null,
        kredilendirilebilirMaliyetUSD: null
      });

    const [graphData, setGraphData] = useState({
        firstGraphDataNormalFatura: [],
        firstGraphDataGesFatura: [],
        barGraphData: []
      });

    const [mixedGraphData, setMixedGraphData] = useState([]);
    const [arcelikOzelContent, setArcelikOzelContent] = useState({
      toplamNormalFatura: '', 
      sistemeSatis: '',
      toplamTasarruf: '',
      toplamKazanc: '',
    });

    let firstGraphDataNormalFaturaTemp = [];
    let firstGraphDataGesFaturaTemp = [];
    let graphDataTemp = [];
    let mixedGraphDataTemp = [];

    const handleChangeStep = (newValue) => {
      dispatch(changeStep({stepNumber: newValue}));
    };

    let lineGraphTicksTemp = [];
    const [lineGraphTicks, setLineGraphTicks] = React.useState(null);

    async function axiosGet() {

        let sozlesmeGucuTemp;
        if (tesis === 'Mesken') {
        sozlesmeGucuTemp = "10";
        } else {
        sozlesmeGucuTemp = sGucu;
        }

        await axiosInstance.get(`fizibilite?enlem=${String(tempLatitude.toFixed(4))}&boylam=${String(tempLongitude.toFixed(4))}&angle=${roofTilt}&yon=${cephe}&alan=${roofArea}&tesis=${tesis}&tutar=${fatura}&sgucu=${sozlesmeGucuTemp}&consumption_profile=${consumptionProfile}&format=json`)
          .then(response => {
            const data = response.data.data;
            setAnalysisData({
                panel: data['panel_sayisi'],
                guc: data['guc'],
                acGuc: data['ac_guc'],
                tlMaliyet: data['kdvli_tl_maliyet'],
                dolarMaliyet: data['kdvli_usd_maliyet'],
                uretim:  data['yillik_uretim'],
                amortisman:  data['roi'].slice(0,4),
                amortismanGonder: data['roi'],
                agac: data['agac'],
                co2: data['co2'],
                yillikTasarruf: data['yillik_ort_tl_tasrrf'],
                dolar: data['dolar'],
                aylikTuketim: data['aylik_tuketim'], // String((Number(response.data['tuketim'])*0.012).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
                yillikTuketim: data['yillik_tuketim'],
                yillikTasarrufUSD: data['yillik_ort_usd_tasrrf'],
                amortismanYaklasik: data['roi'] === '20+' ? '20+' : data['approx_roi'],
                amortismanYilAy: data['roi'] === '20+' ? ['20+', ''] : data['roi_as_years_months'],
                tlMaliyetYaklasik: data['approx_tl_maliyet'],
                tlMaliyetYaklasikKDVsiz: data['approx_tl_maliyet_kdvsiz'],
                dolarMaliyetYaklasik: data['approx_usd_maliyet'],
                dolarMaliyetYaklasikKDVsiz: data['approx_usd_maliyet_kdvsiz'],
                kredilendirilebilirMaliyetTL: module === 'akbank' ? data['akbank_ozel_content']['creditable_amount_tl'] : null,
                kredilendirilebilirMaliyetUSD: module === 'akbank' ? data['akbank_ozel_content']['creditable_amount_usd'] : null
            });

            // Yeni grafik için data
            let onlyMonths = data['aylik_zaman'].slice(0,12).map((month) => month.split(" ")[0].slice(0,3) + '.')
            if ((localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase() === 'EN') {
              // English month abbreviations list
              onlyMonths = onlyMonths.map(month => {
                switch (month) {
                  case 'Oca.':
                    return month = 'Jan.';
                  case 'Şub.':
                    return month = 'Feb.';
                  case 'Mar.':
                    return month = 'Mar.';
                  case 'Nis.':
                    return month = 'Apr.';
                  case 'May.':
                    return month = 'May.';
                  case 'Haz.':
                    return month = 'Jun.';
                  case 'Tem.':
                    return month = 'Jul.';
                  case 'Ağu.':
                    return month = 'Aug.';
                  case 'Eyl.':
                    return month = 'Sep.';
                  case 'Eki.':
                    return month = 'Oct.';
                  case 'Kas.':
                    return month = 'Nov.';
                  case 'Ara.':
                    return month = 'Dec.';
                  default:
                    return month;
                }
              });
              console.log(onlyMonths);
            }
            onlyMonths.forEach((date,index) => {
              const normalFatura = Number(data['arcelik_ozel_content']['normal_fatura_yillik_tl'][index]);
              const gesFatura = Number(data['arcelik_ozel_content']['ges_fatura_yillik_tl'][index]);
              const month = date;
    
              const obje = {
                "month": month,
                "normalFatura": normalFatura,
                "gesFatura": gesFatura,
                "faturaFarki": [
                  normalFatura,
                  gesFatura
                ],
                "satis": gesFatura < 0 ? gesFatura : 0
              }
              mixedGraphDataTemp.push(obje);
            });

            setMixedGraphData(mixedGraphDataTemp);

            setArcelikOzelContent({
              toplamNormalFatura: data['arcelik_ozel_content']['toplam_normal_fatura'], 
              sistemeSatis: data['arcelik_ozel_content']['sisteme_satis'],
              toplamTasarruf: data['arcelik_ozel_content']['toplam_tasarruf'],
              toplamKazanc: data['arcelik_ozel_content']['toplam_kazanc'],
            });

            data['aylik_zaman'].slice(0,12).forEach((date,index) => {
              const veri = data['aylik_normal_fatura'][index];
              const updatedData = veri.replace(".", "");
    
              const obje = {
                "x": date,
                "y": Number(updatedData)
              }
              firstGraphDataNormalFaturaTemp.push(obje);

              lineGraphTicksTemp.push(date);
            });

            data['aylik_zaman'].slice(0,12).forEach((date,index) => {
              const veri = data['aylik_ges_fatura'][index];
              const updatedData = veri.replace(".", "");
              const obje = {
                "x": date,
                "y": Number(updatedData)
              }
              firstGraphDataGesFaturaTemp.push(obje);
            });

            data['yillik_zaman'].forEach((date, index) => {
                const veri = data['yillik_roi'][index];
                const updatedData = Number(veri.replaceAll(".", ""));
                let obje;
                if (updatedData < 0) {
                    obje = {
                      "year": date,
                      "Yatırımınız cebinize dönüyor": Number(updatedData),
                      "kebabColor": "hsl(4, 61%, 59%)",
                    }
                  } else {
                    obje = {
                      "year": date,
                      "Muhteşem! Artık kara geçiyorsunuz": Number(updatedData),
                      "kebabColor": "hsl(4, 61%, 60%)",
                    }
                  }
                graphDataTemp.push(obje);
            });
            setGraphData({
              firstGraphDataNormalFatura: firstGraphDataNormalFaturaTemp,
              firstGraphDataGesFatura: firstGraphDataGesFaturaTemp,
              barGraphData: graphDataTemp
            });
            setLineGraphTicks(lineGraphTicksTemp);
        })
        .catch(error => console.log(error))
    }

    const submitHandler = async (event) => {
      event.preventDefault();
      handleChangeStep(5);
    }

    const willMount = useRef(true);
      if (willMount.current) {
        const dataGet = axiosGet();
        willMount.current = false;
      }

    useEffect(() => {
      if (tesis === "Mesken") {
        if (fatura <= 3) {
          setMaterials(materialsTestData.oneColllectorPackage);
          setCollectorNumber(1);
        } else if (fatura > 3 && fatura <= 5) {
          setMaterials(materialsTestData.twoColllectorPackage);
          setCollectorNumber(1);
        } else if (fatura > 5) {
          setMaterials(materialsTestData.threeColllectorPackage);
          setCollectorNumber(2);
        }
      } else {
        const multiplier1 = Math.round(fatura / 3);
        const multiplier2 = Math.round(multiplier1 / 2);
        setMaterials([
          {
            id: 1,
            productCode: '09150269',
            name: 'SERİ SELLEKTİF KOLL. 2.5 M2 APOLLO E XL',
            price: 100,
            quantity: multiplier1,
            total: 100,
          },
          {
            id: 2,
            productCode: '09140087',
            name: 'PROZEDA 1316 SOR-M-PL-200B-G',
            price: 100,
            quantity: 1,
            total: 100,
          },
          {
            id: 3,
            productCode: '14510239',
            name: 'EVOPLUS B 120/250.40 M FREKANS KONT. POMPA',
            price: 100,
            quantity: 1,
            total: 100,
          },
          {
            id: 4,
            productCode: '17100006',
            name: 'TM -   300 KAPALI GENLEŞME TANKI',
            price: 100,
            quantity: 1,
            total: 100,
          },
          {
            id: 5,
            productCode: '09140098',
            name: '18x18  NIPEL',
            price: 100,
            quantity: multiplier1,
            total: 100,
          },
          {
            id: 6,
            productCode: '09140099',
            name: '18x3-4"  DİRSEK',
            price: 100,
            quantity: multiplier2,
            total: 100,
          },
          {
            id: 7,
            productCode: '09140116',
            name: 'KISA BAGLANTI FLEKSI GK',
            price: 100,
            quantity: multiplier2,
            total: 100,
          },
          {
            id: 8,
            productCode: '09140100',
            name: '18" KOR TAPA',
            price: 100,
            quantity: multiplier2,
            total: 100,
          },
          {
            id: 8,
            productCode: '57675',
            name: 'FERNOX S1 SOLAR SIVI 10 LT',
            price: 100,
            quantity: multiplier2,
            total: 100,
          },
        ]);
        setCollectorNumber(multiplier1);
      }
      
    }, []);

    useEffect(() => {
      let total = 0;
      materials.forEach((material) => {
        total += material.price * material.quantity;
      }
      );
      setTotalPrice(total);
    }, [materials]);

    return (
        <>
          <div className={classes.leftWrapper}>
            <div className={classes.projectInfoWrapper}>
              <div className={classes.projectInfo}>
                <span className={classes.littleIcon}>
                  <div style={{ marginRight: 8 }}>
                    <IconContext.Provider value={{ color: '#ffffff', size: 13 }}>
                      <MdLocationOn />
                    </IconContext.Provider>
                  </div>
                  {cityName} - {ilce}
                </span>
                <span className={classes.littleIcon}>
                  <div style={{ marginRight: 8 }}>
                    <IconContext.Provider value={{ color: '#ffffff', size: 13 }}>
                      <BiArea />
                    </IconContext.Provider>
                  </div>
                  {roofArea} m<sup>2</sup>
                </span>
                <span className={classes.littleIcon}>
                  <div style={{ marginRight: 8 }}>
                    <IconContext.Provider value={{ color: '#ffffff', size: 13 }}>
                      <RiBuilding2Fill />
                    </IconContext.Provider>
                  </div>
                  {t('SummaryPage.tesis')}: {tesis}
                </span>
                <span className={classes.littleIcon}>
                  <div style={{ marginRight: 8 }}>
                    <IconContext.Provider value={{ color: '#ffffff', size: 13 }}>
                      <VscTriangleUp />
                    </IconContext.Provider>
                  </div>
                  {roofTilt === '0' && <span>{t('SummaryPage.duz')}</span>}
                  {roofTilt === '5' && <span>{t('SummaryPage.azegim')}</span>}
                  {roofTilt === '15' && <span>{t('SummaryPage.egimli')}</span>}
                  {roofTilt === '25' && <span>{t('SummaryPage.egimli')}</span>}
                  {!(['0', '5', '15', '25'].includes(roofTilt)) && <span>{t('ProgressBar.egim')}: {roofTilt}°</span>}
                </span>
              </div>
            </div>
            <div className={classes.summaryWrapper}>
              </div>
              <div className={classes.summary2}>
                  <div className={classes.summary2Comp1}>
                    <div className={classes.summaryComp1}>
                      <span className={classes.priceValue}>Güneş Kolektörü Sayısı</span>
                      <span className={classes.outputPrice}>{collectorNumber}</span>
                    </div>
                    <div className={classes.summaryComp1}>
                      <span className={classes.priceValue}>Günlük Sıcak Su Üretimi</span>
                      <span className={classes.outputPrice}>{collectorNumber * 150} litre</span>
                    </div>
                    <div className={classes.summaryComp1} style={{ paddingBottom: '0.75rem' }}>
                      <span className={classes.priceValue}>Günlük Sıcak Su İhtiyacı</span>
                      <span className={classes.outputPrice}>{fatura * 50} litre</span>
                    </div>
                    {/* <div className={classes.summaryComp1} style={{ paddingTop: '0.75rem', borderTop: '1px solid #fff' }}>
                      <span className={classes.priceValue}>{t('SummaryPage.agac')}</span>
                      <span className={classes.outputPrice}>{analysisData.agac} {t('SummaryPage.adet')}</span>
                    </div>
                    <div className={classes.summaryComp1}>
                      <span className={classes.priceValue}>{t('SummaryPage.co2')}<sub>2</sub></span>
                      <span className={classes.outputPrice}>{analysisData.co2} kg</span>
                    </div> */}
                    {/* <div
                      style={{ cursor: 'pointer', marginTop: 20 }}
                      onClick={() => setFeasibilityInfoBoxOpen(!feasibilityInfoBoxOpen)}
                    >
                      <Alert
                        severity="info"
                        icon={<InfoIcon fontSize="inherit" />}
                        className={MUIClasses.myAlert}
                      >
                        {t('SummaryPage.howFeasibilityCalculationsInfo')}
                      </Alert>
                    </div>
                    <StyledDialog
                      aria-labelledby="customized-dialog-title"
                      open={feasibilityInfoBoxOpen}
                      onClose={handleCloseFeasbilityInfoBox}
                    >
                      <DialogContent dividers>
                        <ul style={{ marginLeft: '10px' }}>
                          {module !== "isbank" && <li style={{ margin: '10px 0px' }}>{module === "arcelikkurumsalcozumler" ? t('InvoiceInfoPage.tahminiMetinArcelik') : t('InvoiceInfoPage.tahminiMetinDefault')}</li>}
                          {module === "isbank" && <li style={{ margin: '10px 0px' }}>{t('InvoiceInfoPage.tahminiMetinIsbank')}</li>}
                          {module !== "ibtenerji" && <li style={{ margin: '10px 0px' }}>{t('SummaryPage.bilgi')}</li>}
                          {module === "ibtenerji" && <li style={{ margin: '10px 0px' }}>{t('SummaryPage.bilgiKDVHaric')}</li>}
                          <li style={{ margin: '10px 0px' }}>{t('SummaryPage.powerCalculationInfo')}</li>
                          {module !== "ibtenerji" && <li style={{ margin: '10px 0px' }}>{t('SummaryPage.feasibilityInfoUSD')}</li>}
                          {module === "ibtenerji" && <li style={{ margin: '10px 0px' }}>{t('SummaryPage.feasibilityInfoElecPrices')}</li>}
                          <li style={{ margin: '10px 0px' }}>{t('InvoiceInfoPage.bataryaNot')}</li>
                        </ul>
                      </DialogContent>
                    </StyledDialog> */}
                  </div>
              </div>
              <div className={classes.projectInfoWrapper} style={{ backgroundColor: 'white' }}>
              <div className={classes.projectInfo} style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', color: 'black' }}>
                <img src="./baymak-kolektor.png" style={{ width: 100, objectFit: 'contain' }} />
                <div>
                  <ul style={{ marginLeft: 20 }}>
                    <li>5 yıl garanti</li>
                    <li>Selektif emici yüzey ve lazer kaynak teknolojisi ile yüksek verim</li>
                    <li>Güçlendirilmiş şase ve temperli camı ile uzun ömürlü kullanım</li>
                    <li>Galvaniz alt örtü ile darbelere karşı dayanıklı</li>
                    <li>Lazer kaynaklı boruları yüksek ısı transferi saglar</li>
                    <li>Özel 40 mm kalınlıgında cam yünü izolasyonu ısı kaybını engeller</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        <div className={classes.rightWrapper}>
          <div className={classes.rightWrapperlv1}>
            <div className={classes.rightWrapperlv2} style={{ padding: 0, paddingTop: '1rem' }}>
              <div className={classes.graphWrapper}>
                <p style={{ alignSelf: 'center', width: 'auto', fontWeight: 'bold' }}>Ürün Listesi</p>
                <TableContainer style={{ padding: 20 }}>
                    <Table className={MUIClasses.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                          <TableCell className={MUIClasses.cell}>Ürün Kodu</TableCell>
                          <TableCell className={MUIClasses.cell}>Ürün Adı</TableCell>
                          <TableCell className={MUIClasses.cell}>Adet</TableCell>
                          <TableCell className={MUIClasses.cell}>Birim Fiyat</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                          {materials.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell className={MUIClasses.cell}>
                                  {row.productCode}
                              </TableCell>
                              <TableCell className={MUIClasses.cell} component="th" scope="row">
                                  {row.name}
                              </TableCell>
                              <TableCell className={MUIClasses.cell}>
                                  {row.quantity}
                              </TableCell>
                              <TableCell className={MUIClasses.cell}>
                                  {row.price} ₺
                              </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <h4 style={{ marginRight: '20px', fontSize: '1.1rem', textAlign: 'end', width: '100%' }}>Toplam Fiyat: {totalPrice} ₺</h4>
              </div>
              <div className={classes.formwizard}>
                <div className={classes.formwizardlv1}>
                  <div className={classes.buttonContainer}>
                    <button onClick={() => handleChangeStep(3)} className={classes.backButton}>
                      <div className={classes.backButtonIcon}>
                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                          <AiOutlineArrowRight />
                        </IconContext.Provider>
                      </div>
                      <span className={classes.startText}>{t('Buton.geri')}</span>
                    </button>
                    <button onClick={submitHandler} className={classes.nextButton}>
                      <span className={classes.finishText}>{t('Buton.ileri')}</span>
                      <div className={classes.buttonIcon}>
                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                          <AiOutlineArrowRight />
                        </IconContext.Provider>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    );
}

export default SummaryPage;