import React from 'react';
import classes from './Spinner.module.css';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogContent,
    withStyles,
  } from '@material-ui/core';

const StyledDialog = withStyles({
    paper: {
      margin: 16,
      maxWidth: 600
    }
  })(Dialog);


const spinner = (props) => (
    <>
        <StyledDialog aria-labelledby="customized-dialog-title" open={true}>
            <DialogContent dividers>
                <div className={classes.Loader}>
                    Loading...
                </div>
                <div className={classes.SpinnerText}>
                    <p style={{ color: '#07bbf2' }}>
                        {props.text}
                    </p>
                </div>
            </DialogContent>
        </StyledDialog>
    </>
);

export default spinner;