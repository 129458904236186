import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../components/axios/axiosApi';
import classes from './InvoiceInfoPage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight, AiFillHome } from 'react-icons/ai';
import { RiBattery2ChargeLine } from 'react-icons/ri';
import { FaHandHoldingWater } from 'react-icons/fa';
import { MdEvStation, MdApartment } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep, changeQuote } from '../../services/products/product/stepReducer';
import RadioButton from '../../components/UI/RadioButton/RadioButton';
import GeneralDialog from '../../components/Dialog/GeneralDialog';
import {
    changeYillikTasarruf,
    changeFatura,
    changeBatarya,
    changeSarj,
    changeTesis,
    changeMeskenTipi,
    changeSozlesmeGucu,
    changeTrafoGucu,
    changeYaklasikAmortisman,
    changeAmortismanYilAy,
    changeYillikKazanc,
    changeYaklasikMaliyet,
    changeConsumptionProfile
} from '../../services/products/product/summaryReducer';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useMediaQuery } from 'react-responsive';
import StepProgress from '../../components/StepProgress';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} {...props} />;
  }

const StyledDialog = withStyles({
    paper: {
      margin: 16,
      maxWidth: 600
    }
  })(Dialog);

const useStyles = makeStyles({
    root: {
      width: 250,
    },
    input: {
      width: 95,
      fontFamily: 'var(--font-fam)'
    },
    item: {
        width: 200
    },
    myAlert: {
        color: "#000000",
        backgroundColor: "#ffffff"
    }
  });


const ColoredSlider = withStyles({
    root: {
        color: 'var(--primary-color)',
        height: 5
    },
    thumb: {
        height: 15,
        width: 15,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -5,
        marginLeft: -7
    },
    active: {},
    track: {
        height: 4,
        borderRadius: 2
    },
    rail: {
        height: 4,
        borderRadius: 2
    }
})(Slider);

const ColoredCheckbox = withStyles({
    root: {
        color: 'var(--primary-color)',
        '&$checked': {
            color: 'var(--primary-color)',
        },
    },
    checked: {},
})(Checkbox);

function InvoiceInfoPage(props) {
    const { t, } = useTranslation();

    const MUIClasses = useStyles();

    // ------------ DETERMINE MOBILE OR DESKTOP ---------- //

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isTabletLandscape = useMediaQuery({ minWidth: 600, maxWidth: 1100, orientation: 'landscape' });

  // --------------------------------------------------- //

    const dispatch = useDispatch();
    const { apiRequestSource, userId, userStatus } = useSelector(state => state.users);
    const { roofArea, tempLatitude, tempLongitude } = useSelector(state => state.location);
    const { roofTilt, batarya, sarj, tesis, meskenTipi, fatura, sGucu, trafoGucu, yillikTasarruf, tlMaliyet, mail, yaklasikAmortisman, cephe, consumptionProfile, yillikKazanc } = useSelector(state => state.summary);
    const {
        module,
        availableModules
    } = useSelector(state => state.module);
    const {
        maxFatura,
        avgFatura,
        faturaSgucuOrani
    } = useSelector(state => state.constants);

    const [feasibilityInfoBoxOpen, setFeasibilityInfoBoxOpen] = useState(false);

    const handleCloseFeasbilityInfoBox = () => {
      setFeasibilityInfoBoxOpen(false);
    };

    const [loading, setLoading] = useState(false);

    const [tempFatura, setTempFatura] = useState(fatura);
    //const [tempTesis, setTempTesis] = useState(tesis);
    const [tempSgucu, setTempSgucu] = useState(sGucu);
    const [tempTrafoGucu, setTempTrafoGucu] = useState(trafoGucu);

    const checkApartmentRef = useRef(null);

    const willMount = useRef(true);
      if (willMount.current) {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: "/fatura_bilgileri" });
        willMount.current = false;
      }

    const [apartmanChecked, setApartmanChecked] = useState(JSON.parse(localStorage.getItem('apartmanChecked')) || false);

    const handleChangeApartmanCheck = (event) => {
        setApartmanChecked(event.target.checked);
        localStorage.setItem('apartmanChecked', JSON.stringify(event.target.checked));
    };


    useEffect(() => {
        dispatch(changeYillikKazanc({yillikKazanc: fatura * 50}));

    }, [tesis, tempFatura, tempSgucu, consumptionProfile])

    

    const handleChangeStep = (newValue) => {
        if (newValue === 4) {
            if (tesis === 'Mesken' && meskenTipi === 'Apartman' && apartmanChecked === false) {
                checkApartmentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                return;
            }
        }
        
        dispatch(changeStep({stepNumber: newValue}));
        
        if (Boolean(userId) && Boolean(mail)) {
            dispatch(changeQuote({ quoteSend: true }));
        }
      };

    const selectionChange = (event) => {
        dispatch(changeTesis({tesis: event.target.value}));
        if (event.target.value === 'Mesken') {
          dispatch(changeFatura({fatura: 3}));
        } else if (event.target.value === "Ticarethane") {
          dispatch(changeFatura({fatura: 50}));
        }
      };
    
    const handleChange = (event, newValue) => {
        dispatch(changeFatura({fatura: newValue ||  parseInt(event.target.value)}));
        
        // manuel input değiştiğinde tekrar istek atması için ekledim bu kısmı
        if (parseInt(event.target.value)) {
            setTempFatura(newValue ||  parseInt(event.target.value));
        }
      };

    const handleChangeDragEnd = (event, newValue) => {
        setTempFatura(newValue ||  parseInt(event.target.value));
      };

    const handleMeskenTipiChange = (event) => {
        dispatch(changeMeskenTipi({meskenTipi: event.target.value}));
    }

    let optionsFatura;

    if (tesis === "Mesken") {
        optionsFatura = (
            <>
                <Grid item xs>
                    <ColoredSlider
                        value={fatura}
                        onChange={handleChange}
                        onChangeCommitted={handleChangeDragEnd}
                        aria-labelledby="input-slider"
                        step={1}
                        min={1}
                        max={8}
                    />
                </Grid>
                <Grid item>
                    <Input
                        className={MUIClasses.input}
                        value={fatura}
                        margin="dense"
                        onChange={handleChange}
                        inputProps={{
                        step: 1,
                        min: 1,
                        max: 8,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </>
        )
    } else if (tesis === "Ticarethane"){
        optionsFatura = (
            <>
                <Grid item xs>
                    <ColoredSlider
                        value={fatura}
                        onChange={handleChange}
                        onChangeCommitted={handleChangeDragEnd}
                        aria-labelledby="input-slider"
                        step={10}
                        min={10}
                        max={300}
                    />
                </Grid>
                <Grid item>
                    <Input
                        className={MUIClasses.input}
                        value={fatura}
                        margin="dense"
                        onChange={handleChange}
                        //onBlur={handleBlur}
                        inputProps={{
                        step: 10,
                        min: 10,
                        max: 300,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </>
        );

    }

    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    const open1 = Boolean(anchorEl1);
    const id1 = open1 ? 'popover1' : undefined;

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'popover2' : undefined;

    const open3 = Boolean(anchorEl3);
    const id3 = open3 ? 'popover3' : undefined;


    return (
    <>
        <div className={classes.leftWrapper}>
            {tesis === "Mesken" && <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src="./solar-collector-home.jpg"/>}
            {tesis === "Ticarethane" && <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src="./solar-collector-industrial.jpg"/>}
        </div>
        
        <div className={classes.rightWrapper}>
            <div className={classes.rightWrapperlv1}>
                <div className={classes.rightWrapperlv2}>
                    <div className={classes.title}>
                        <h2 className={classes.titleh2}>Kapasite ve Tesis Bilgileri</h2>
                        {Number(userStatus) < 4 &&
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <span className={classes.adresGir} >{t('InvoiceInfoPage.tesisSoru')}</span>
                        </div>
                        }
                    </div>
                    {!((availableModules.length === 2 && availableModules.includes("home") && availableModules.includes("subcon")) || (Number(userStatus) > 3)) && <div className={classes.radioWrapper}>
                        <div className={classes.facilityChoice}>
                            {!(module === "akbank" || module === "isbank") && <label className={tesis === "Mesken" ? classes.rooflabelActive : classes.roofLabel}>
                                <span className={classes.spanRoofLabel}>{t('InvoiceInfoPage.mesken')}</span>
                                <input 
                                    type="radio" 
                                    id="facility_type1" 
                                    name="facility_type" 
                                    value="Mesken" 
                                    className={classes.radioRoof} 
                                    checked={tesis === "Mesken"}
                                    onChange={selectionChange}
                                    disabled={Number(userStatus) > 3}
                                ></input>
                            </label>}
                            <label className={tesis === "Ticarethane" ? classes.rooflabelActive : classes.roofLabel}>
                                <span className={classes.spanRoofLabel}>Endüstriyel</span>
                                <input 
                                    type="radio" 
                                    id="facility_type2" 
                                    name="facility_type" 
                                    value="Ticarethane" 
                                    className={classes.radioRoof} 
                                    checked={tesis === "Ticarethane"}
                                    onChange={selectionChange}
                                    disabled={Number(userStatus) > 3}
                                ></input>
                            </label>
                        </div>
                    </div>}
                    {!((availableModules.length === 2 && availableModules.includes("home") && availableModules.includes("subcon"))|| (Number(userStatus) > 3)) && <Divider variant="middle" />}
                    {tesis === 'Mesken' && Number(userStatus) < 4 &&
                    <div className={classes.optionsWrapper} style={{ marginBottom: meskenTipi === 'Müstakil' ? '1rem' : '0rem', marginTop: '1rem' }}>
                        <div className={classes.checkWrapper}>
                            <label className={meskenTipi === 'Müstakil' ? classes.rooflabelActive : classes.roofLabel}>
                                <div className={classes.extraIcon}>
                                    <IconContext.Provider value={{ color: meskenTipi === 'Müstakil' ? '#ffffff' : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), size: 20 }}>
                                        <AiFillHome />
                                    </IconContext.Provider>
                                    <span style={{ marginLeft: 5 }} className={classes.battery} >{t('InvoiceInfoPage.mustakil')}</span>
                                </div>
                                <input
                                    type="radio"
                                    id="Müstakil"
                                    name="meskenTipi" 
                                    value="Müstakil"
                                    checked={meskenTipi === 'Müstakil'}
                                    onChange={handleMeskenTipiChange}
                                    className={classes.checkbox}
                                    />
                            </label>
                            <label className={meskenTipi === 'Apartman' ? classes.rooflabelActive : classes.roofLabel} style={{ marginLeft: '20px' }} ref={checkApartmentRef}>
                                <div className={classes.extraIcon}>
                                    <IconContext.Provider value={{ color: meskenTipi === 'Apartman' ? '#ffffff' : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), size: 20 }}>
                                        <MdApartment />
                                    </IconContext.Provider>
                                    <span style={{ marginLeft: 5 }} className={classes.battery}>{t('InvoiceInfoPage.apartman')}</span>
                                </div>
                                <input
                                    type="radio"
                                    id="Apartman"
                                    name="meskenTipi" 
                                    value="Apartman"
                                    checked={meskenTipi === 'Apartman'}
                                    onChange={handleMeskenTipiChange}
                                    className={classes.checkbox}
                                    />
                            </label>
                        </div>
                    </div>}
                    {tesis === 'Mesken' && Number(userStatus) < 4 && meskenTipi === 'Apartman'
                        && (
                        <div style={{
                            margin: '1rem 0rem',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <ColoredCheckbox
                                checked={apartmanChecked}
                                onChange={handleChangeApartmanCheck}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <span style={{ fontSize: isTabletOrMobile ? '0.8rem' : '1rem' }}>{t('InvoiceInfoPage.apartmanKarar')}</span>
                        </div>
                        )}
                    <div className={classes.title2}>
                        <div style={{marginTop: 12, width: '95%'}}>
                            {isTabletOrMobile && tesis === "Mesken" && <span className={classes.adresGir}>Evinizde kaç kişi yaşıyor?</span>}
                            {isTabletOrMobile && tesis !== "Mesken" && <span className={classes.adresGir}>Tesisinizin kapasitesi kaç kişi kişidir?</span>}
                            <Grid container spacing={3} alignItems="center">
                                {isDesktopOrLaptop && <Grid className={MUIClasses.item} item>
                                    {tesis === "Mesken" && <span>Evinizde kaç kişi yaşıyor?</span>}
                                    {tesis !== "Mesken" && <span>Tesisinizin kapasitesi kaç kişi kişidir?</span>}
                                </Grid>}
                                {optionsFatura}
                            </Grid>
                        </div>
                    </div>
                    {/* <>
                        <Divider variant="middle" />
                        <div className={classes.optionsWrapper} style={ isTabletOrMobile ? { marginBottom: '0rem' } : {}}>
                            <h3 className={classes.extraTexth3}>{t('InvoiceInfoPage.ekstra')}</h3>
                            <div className={classes.checkWrapper}>
                                {module !== "akbank" && <label className={batarya ? classes.extraLabelActive : classes.extraLabelDeactive}>
                                    <div className={classes.extraIcon}>
                                        <IconContext.Provider value={{ color: batarya ? '#ffffff' : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), size: 20 }}>
                                            <RiBattery2ChargeLine />
                                        </IconContext.Provider>
                                        <span style={{ marginLeft: 5 }} className={classes.battery} >{t('InvoiceInfoPage.batarya')}</span>
                                    </div>
                                    <input
                                        type="checkbox"
                                        id="battery"
                                        name="battery" 
                                        checked={batarya}
                                        onChange={handleBatteryChange}
                                        className={classes.checkbox}
                                        />
                                </label>}
                                {<label className={sarj ? classes.extraLabelActive : classes.extraLabelDeactive}>
                                    <div className={classes.extraIcon}>
                                        <IconContext.Provider value={{ color: sarj ? '#ffffff' : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'), size: 20 }}>
                                            <MdEvStation />
                                        </IconContext.Provider>
                                        <span style={{ marginLeft: 5 }} className={classes.battery}>{t('InvoiceInfoPage.sarj')}</span>
                                    </div>
                                    <input
                                        type="checkbox"
                                        id="charger"
                                        name="charger" 
                                        checked={sarj}
                                        onChange={handleChargerChange}
                                        className={classes.checkbox}
                                        />
                                </label>}
                            </div>
                        </div>
                    </> */}
                    {/* <div
                        style={{ cursor: 'pointer', marginTop: 20, marginBottom: isTabletOrMobile ? '6rem' : 0 }}
                        onClick={() => setFeasibilityInfoBoxOpen(!feasibilityInfoBoxOpen)}
                    >
                        <Alert
                            severity="info"
                            icon={<InfoIcon fontSize="inherit" />}
                            className={MUIClasses.myAlert}
                        >
                            {t('SummaryPage.howFeasibilityCalculationsInfo')}
                        </Alert>
                    </div>
                    <StyledDialog
                        aria-labelledby="customized-dialog-title"
                        open={feasibilityInfoBoxOpen}
                        onClose={handleCloseFeasbilityInfoBox}
                    >
                        <DialogContent dividers>
                        <ul style={{ marginLeft: '10px' }}>
                            <li style={{ margin: '10px 0px' }}>{t('InvoiceInfoPage.tahminiMetinDefault')}</li>
                            <li style={{ margin: '10px 0px' }}>{t('SummaryPage.bilgi')}</li>
                            <li style={{ margin: '10px 0px' }}>{t('SummaryPage.powerCalculationInfo')}</li>
                            <li style={{ margin: '10px 0px' }}>{t('SummaryPage.feasibilityInfoUSD')}</li>
                            <li style={{ margin: '10px 0px' }}>{t('InvoiceInfoPage.bataryaNot')}</li>
                        </ul>
                        </DialogContent>
                    </StyledDialog> */}
                    
                </div>
                {(isDesktopOrLaptop || isTabletLandscape) && <div className={classes.formwizard}>
                    <div className={classes.formwizardlv1}>
                        <div className={classes.buttonContainer}>
                            <button onClick={() => handleChangeStep(2)} className={classes.backButton}>
                                <div className={classes.backButtonIcon}>
                                    <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                    </IconContext.Provider>
                                </div>
                                <span className={classes.startText}>{t('Buton.geri')}</span>
                            </button>
                            <button
                                onClick={() => handleChangeStep(4)}
                                className={classes.nextButton}
                            >
                                <span className={classes.startText}>{t('Buton.ozet')}</span>
                                <div className={classes.buttonIcon}>
                                    <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                    </IconContext.Provider>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>}
                {(isTabletOrMobile && !isTabletLandscape) && <div className={classes.formwizard}>
                    <div style={{
                        padding: '0.5rem 50px',
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr auto',
                        alignItems: 'center',
                        gridGap: '1rem',
                    }}>
                        <img src="/images/sun.png" style={{width: '20px' }} />
                        <StepProgress />
                        <img src="/images/solar-panel-home.png" style={{width: '20px' }} />
                    </div>
                    <div className={classes.formwizardlv1}>
                        <div className={classes.buttonContainer}>
                            <div className={classes.costSavingWrapper}>
                                <IconContext.Provider value={{ color: '#757575', size: 30 }}>
                                  <FaHandHoldingWater />
                                </IconContext.Provider>
                                <div className={classes.costSaving}>
                                  <span className={classes.costSavingText}>{yillikKazanc}</span>
                                  <span className={classes.costSavingText2}>Sıcak Su İhtiyacı</span>
                                </div>
                            </div>
                            <button onClick={() => handleChangeStep(2)} className={classes.backButton}>
                                <div className={classes.backButtonIcon}>
                                    <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                    <AiOutlineArrowRight />
                                    </IconContext.Provider>
                                </div>
                                <span className={classes.startText}>{t('Buton.geri')}</span>
                            </button>
                            <button
                                onClick={() => handleChangeStep(4)}
                                className={classes.nextButton}
                            >
                                <span className={classes.startText}>{t('Buton.ozet')}</span>
                                <div className={classes.buttonIcon}>
                                    <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                    </IconContext.Provider>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    </>
  );
}

export default InvoiceInfoPage;