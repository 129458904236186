/* eslint-disable prefer-template */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './UserPageFooter.module.css';
import { useSelector } from 'react-redux';

const UserPageFooter = () => {
    const { t, } = useTranslation();
    const { module } = useSelector(state => state.module);

    return (
        <>
            {module !== "akbank" && <div className={classes.copyrightWrapper}>
                <span> </span>
                <span style={{ color: 'white' }}>Powered by <a href="https://solarvis.co" target='_blank' style={{ color: 'white'}}>solarVis</a></span>
            </div> }
            {module === "akbank" && <div className={classes.copyrightWrapperAkbank}>
                <span style={{ color: 'white', maxWidth: 800 }}>Güneş enerjisi ön fizibilite hizmeti Şirketimiz Electravis Mühendislik Danışmanlık Elektrik Elektronik İç ve Dış Ticaret Limited Şirketi tarafından verilmektedir. Bu internet sitesinden alacağınız hizmetle/vereceğimiz bilgilerle ilgili olarak Akbank T.A.Ş’nin herhangi bir sorumluluğu bulunmamaktadır.</span>
                <span style={{ color: 'white' }}>Powered by <a href="https://solarvis.co" target='_blank' style={{ color: 'white'}}>solarVis</a></span>
            </div> }
        </>
    );
};

export default UserPageFooter;
