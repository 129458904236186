import React, { useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone-uploader'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
//import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useMediaQuery } from 'react-responsive';
import axiosInstance from '../../../components/axios/axiosApi';

const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta
    return (
      <>
        <img style={{ width: '60%' }} src={previewUrl} alt={name}/>
        <span style={{ margin: '10px 3%' }}>
            {name}, {Math.round(percent)}%
        </span>
      </>
    )
  }

const FileUploadComponent = (props) => {

    const { t, } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const fileParams = ({ file, meta }) => {
        /* const body = new FormData()
        body.append('fileField', file)
        return { url: 'https://api.solarvis.co/upload_file/?format=json', body } */
        return { url: 'https://httpbin.org/post' }
    }

    const onFileChange = ({ meta, file }, status) => { 
        console.log(status, meta, file);
        if (status === 'done') {
            //console.log(file.name);

            let fileNameArray, extension;
            fileNameArray = file.name.split('.');
            extension = fileNameArray[fileNameArray.length - 1];

            let body = new FormData();
            // const key = {filename:`${props.photoCategory}-${fileCounter}.${extension}`,category_id: 3,subcon_project_id:1,is_admin: false};
            const jsonData = {file_document_category_id: props.documentCategory.id, user_comment: ""}
            body.append('json_data_str', JSON.stringify(jsonData));
            body.append('file', file);

            axiosInstance.post('subcon/document/project_APIs/end_user_upload_single_project_document', body, {
                Headers: {contentType: 'multipart/form-data'}
            })
              .then((response) => console.log(response))
              .catch((error) => console.log(error))
        }
    }

    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }

    const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
        const textMsg = files.length > 0 ? t('UploadComponent.uploadAgain') : t('UploadComponent.uploadDocument')

        return (
            <>
                <label 
                    className="btn mt-4"
                    style={{width: '50%', backgroundColor: '#cbddea', padding: '10px', cursor: 'pointer', borderRadius: '8px', textAlign: 'center'}}
                >
                    {textMsg}
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        accept={accept}
                        multiple
                        onChange={e => {
                            getFilesFromEvent(e).then(chosenFiles => {
                                onFiles(chosenFiles)
                            })
                        }}
                    />
                </label>
                <span style={{ color: '#a6a6a5', fontSize: '13px', marginTop: '10px' }} >{t('UploadComponent.fileExtensions')}</span>
            </>
        )
    }

    

    return (
        <Dropzone
            //onSubmit={onSubmit}
            PreviewComponent={Preview}
            onChangeStatus={onFileChange}
            InputComponent={selectFileInput}
            getUploadParams={fileParams}
            getFilesFromEvent={getFilesFromEvent}
            /* accept={props.acceptedTypes} */
            maxFiles={props.maxFiles}
            inputContent={t('UploadComponent.uploadDocument')}
            styles={{
                dropzone: { width: isDesktopOrLaptop ? 600 : '100%', height: 400, justifyContent: 'center', overflowX: 'hidden', overflowY: 'scroll' },
                dropzoneActive: { borderColor: 'green' },
                previewImage: { maxHeight: '100px', maxWidth: '300px' },
                preview: { minHeight: 'auto' }
            }}            
        />
    );
};

export default FileUploadComponent;