import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PageWrapper from './pages/PageWrapper/PageWrapper';
import MainPageWrapper from './pages/UserMainPage/Wrapper';
import UserDocumentUploadPage from './pages/UserDocumentUploadPage/UserDocumentUploadPage';
import UserAccountPage from './pages/UserAccountPage/UserAccountPage';

import AydinlatmaMetni from './pages/UserMainPage/Home/KVKKPages/AydinlatmaMetni';
import CerezPolitikasi from './pages/UserMainPage/Home/KVKKPages/CerezPolitikasi';
import KVKKPolitikasi from './pages/UserMainPage/Home/KVKKPages/KVKKPolitikasi';
import SatisSozlesmesi from './pages/UserMainPage/Home/KVKKPages/SatisSozlesmesi';

import PaymentResponse from './pages/UserMainPage/Home/PaymentPages/PaymentResponse';
import CreditResponse from './pages/UserMainPage/Home/PaymentPages/CreditResponse';
import PaymentPage from './pages/UserMainPage/Home/PaymentPages/PaymentPage';
import PaytrRedirect from './pages/RedirectPages/PaytrRedirect';

import SunSpinner from './components/UI/SunSpinner/SunSpinner';

function App() {


  const [loading, setLoading] = React.useState(false);

  if (loading) {
    return (
      <>
        <SunSpinner />
      </>
    )
  }

  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<PageWrapper />} />
        <Route path="login" element={<MainPageWrapper />} />
        <Route path="login/photos" element={<UserDocumentUploadPage />} />
        <Route path="login/account" element={<UserAccountPage />} />
        <Route path="login/package-payment" element={<PaymentPage />} />
        <Route path="login/successfull-payment" element={<PaymentResponse success={true} />} />
        <Route path="login/failed-payment" element={<PaymentResponse success={false} />} />
        <Route path="login/successful-credit-response" element={<CreditResponse success={true} />} />
        <Route path="login/failed-credit-response" element={<CreditResponse success={false} />} />
        <Route path="kvkk-aydinlatma-metni" element={<AydinlatmaMetni />} />
        <Route path="kvkk-politikasi" element={<KVKKPolitikasi />} />
        <Route path="cerez-kullanim-izinleri-bildirimi" element={<CerezPolitikasi />} />
        <Route path="mesafeli-satis-sozlesmesi" element={<SatisSozlesmesi />} />
        <Route path="paytr-redirect" element={<PaytrRedirect />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;