/* eslint-disable prefer-template */
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
// import qs from 'qs';
import classes from "./UserLoginPage.module.css";
import {
  Box,
  Button,
  TextField,
  Typography,
  withStyles,
  Snackbar,
} from "@material-ui/core";
// import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
// import { changeAccessToken, changeRefreshToken } from 'src/services/auth/authReducer';
import {
  changeLoggedIn,
  changePassword,
  changeUserId,
  changeUserAPIStatus,
} from "../../services/users/userReducer";
import MuiAlert from "@material-ui/lab/Alert";
import {
  changeIsim,
  changeMail,
  changeSoyisim,
  changeTesis,
  changeFatura,
  changeSozlesmeGucu,
  changeSarj,
  changeBatarya,
  changeCephe,
  changeEgim,
} from "../../services/products/product/summaryReducer";
import { changeDiscoveryRequest } from "../../services/products/product/moduleReducer";
import {
  changeLocation,
  changeTempLocation,
  changeCityName,
  changeIlce,
  changeArea,
  changePolygonPoints,
  changeMarker,
  changePanelAmount,
} from "../../services/products/product/reducer";
import { changeStep } from "../../services/products/product/stepReducer";
import axiosInstance from "../../components/axios/axiosApi";
import Navbar from "../../containers/LoginNavbar/Navbar";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import i18n from "../../i18n";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

const LoginView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { module } = useSelector((state) => state.module);
  const { firmaKodu } = useSelector(state => state.companyInfo);
  const [forgotPassword, setForgotPassword] = useState(false);

  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState("warning");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 991px)" });

  const fetchUserFeasibilityInfo = async () => {
    let roofArea;
    await axiosInstance
      .get(`get_user_feasibility_info?format=json`)
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data;
          roofArea = data.alan;
          dispatch(changeStep({ stepNumber: 1 }));
          dispatch(changeUserId({ userId: data.id }));
          dispatch(
            changeLocation({ latitude: data.enlem, longitude: data.boylam })
          );
          dispatch(
            changeTempLocation({ tempLatitude: data.enlem, tempLongitude: data.boylam })
          );
          dispatch(changeCityName({ cityName: data.il }));
          dispatch(changeIlce({ ilce: data.ilce }));
          dispatch(changePolygonPoints({ polygonPoints: data.drawing_points }));
          dispatch(changeArea({ roofArea: data.alan }));
          dispatch(changeTesis({ tesis: data.tesis_tipi }));
          dispatch(changeFatura({ fatura: data.fatura_tutari }));
          dispatch(changeSozlesmeGucu({ sGucu: data.sgucu }));
          dispatch(changeEgim({ roofTilt: String(data.aci) }));
          dispatch(changeCephe({ cephe: String(data.yon) }));
          dispatch(changeBatarya({ batarya: data.is_battery }));
          dispatch(changeSarj({ sarj: data.is_charger }));
          dispatch(changeUserAPIStatus({ userStatus: data.status }));
          if (data.status === 12) {
            dispatch(changeDiscoveryRequest(true));
          }
          dispatch(changeMarker({ markerOn: true }));
        }
      })
      .catch();

    await axiosInstance
      .get(`panel_sayisi?format=json&alan=${roofArea}`)
      .then((response) => {
        const data = response.data.data;
        dispatch(changePanelAmount({ panelAmount: data["panel_sayisi"] }));
      })
      .catch(() => {
        dispatch(
          changePanelAmount({ panelAmount: Math.round(roofArea * 0.35) })
        );
      });

    window.location.reload();
  };

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState(false);
  };

  const decideHelmet = () => {
    if (localStorage.getItem('dil') === 'tr' || String(i18n.language).split('-')[0] === 'tr') {
      if (module === "solarvis") {
          return <Helmet>
          <title>{`solarVis | Kullanıcı Girişi`}</title>
          <meta name="description" content="Fizibilite raporunu gör ve fotoğraflarını yükle." />
          </Helmet>
      } else {
        return <Helmet>
        <title>{`${firmaKodu} | Kullanıcı Girişi`}</title>
        <meta name="description" content="Fizibilite raporunu gör ve fotoğraflarını yükle." />
        </Helmet>
      }
    } else {
      return <Helmet>
        <title>{`${firmaKodu} | User Login`}</title>
        <meta name="description" content="View the feasibility report and upload your photos." />
        </Helmet>
    }
  };

  return (
    <>
      {decideHelmet()}
      <div className={classes.pageWrapper}>
        <div className={classes.container}>
          <div className={classes.loginWrapper}>
            <Navbar />
            {!forgotPassword && (
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Geçerli bir e-posta adresi giriniz")
                    .max(255)
                    .required("E-posta adresi gereklidir"),
                  password: Yup.string().min(6, "Required").max(255).required("Şifre gereklidir"),
                })}
                onSubmit={(values) => {
                  let domainSend;
                  if (String(window.location.host).includes('localhost')) {
                    domainSend = 'demotest.solarvis.co';
                  } else {
                    domainSend = window.location.host
                  }
                  axiosInstance
                    .post(`/token/?domain=${domainSend}`, {
                      username: values.email,
                      password: values.password,
                    })
                    .then((result) => {
                      const resp = result.data;
                    
                      if ('case_code' in resp && resp.case_code === 'saved_different_company') {
                        alert(resp.message);
                      } 
                      
                      if ('access' in resp) {
                        const parsedInfo = parseJwt(resp.access);

                        axiosInstance.defaults.headers.Authorization = `Bearer ${result.data.access}`;
                        localStorage.setItem('access', result.data.access);
                        localStorage.setItem('refresh', result.data.refresh);
                        dispatch(changeLoggedIn({ loggedIn: true }));
                        dispatch(changeMail({ mail: values.email }));
                        if (parsedInfo.isim) {
                          dispatch(changeIsim({ isim: parsedInfo.isim }));
                        }
                        if (parsedInfo.soy_isim) {
                          dispatch(changeSoyisim({ soyisim: parsedInfo.soy_isim }));
                        }
                        dispatch(changePassword({ password: values.password }));
                        //dispatch(changeUserId({ userId: parsedInfo.user_id }))
                        //navigate('/login', { replace: true });

                        fetchUserFeasibilityInfo();
                      }
                    })
                    .catch((error) => {
                      if (
                        error.response.status === 401 &&
                        error.response.data.detail ===
                          "No active account found with the given credentials"
                      ) {
                        alert(t("LoginPage.hataliGiris"));
                      } else {
                        alert(t("LoginPage.hataliGiris2"));
                      }
                    });
                  //dispatch(changeLoggedIn({ loggedIn: true }));
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className={classes.form}
                    style={{ marginTop: "12vh" }}
                  >
                    <Box mb={3}>
                      <Typography color="textPrimary" variant="h4">
                        {t("LoginPage.giris")}
                      </Typography>
                      <Typography
                        color="textPrimary"
                        variant="p"
                        style={{ margin: "50px 0px" }}
                      >
                        <br></br>
                        {t('LoginPage.signupText1')}
                        <br></br>
                        {t('LoginPage.signupText2')}
                        <span>
                          <Link to="/">{t('LoginPage.signupText3')}</Link>
                        </span>
                        {t('LoginPage.signupText4')}
                      </Typography>
                    </Box>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label={t("LoginPage.email")}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label={t("LoginPage.sifre")}
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <Box my={2}>
                      <StyledButton
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {t("LoginPage.giris")}
                      </StyledButton>
                    </Box>
                    <div>
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => setForgotPassword(true)}
                      >
                        {t("LoginPage.forgotText")}
                      </span>
                    </div>
                  </form>
                )}
              </Formik>
            )}
            {forgotPassword && (
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Geçerli bir e-posta adresi giriniz")
                    .max(255)
                    .required("E-posta adresi gereklidirr"),
                })}
                onSubmit={(values) => {
                  axiosInstance
                    .get(
                      `resend_mail?mail=${values.email}&lang=${
                        localStorage.getItem("dil") ||
                        String(i18n.language).split("-")[0]
                      }`
                    )
                    .then((response) => {
                      if (response.data.status) {
                        setSnackbarMessage(response.data.message);
                        setSnackbarType("success");
                        setSnackbarState(true);
                      } else {
                        setSnackbarMessage(
                          "Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz."
                        );
                        setSnackbarType("warning");
                        setSnackbarState(true);
                      }
                    })
                    .catch((error) => {
                      setSnackbarMessage(
                        "Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz."
                      );
                      setSnackbarType("warning");
                      setSnackbarState(true);
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className={classes.form}
                    style={{ marginTop: "12vh" }}
                  >
                    <Box mb={3}>
                      <Typography color="textPrimary" variant="p">
                        {t("LoginPage.forgotPassword")}
                      </Typography>
                    </Box>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label={t("LoginPage.email")}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <Box my={2}>
                      <StyledButton
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {t("LoginPage.send")}
                      </StyledButton>
                    </Box>
                    <div>
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => setForgotPassword(false)}
                      >
                        {t("LoginPage.backToLogin")}
                      </span>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
          {isDesktopOrLaptop && (
            <div className={classes.iconWrapper}>
              <img
                src="./images/solarpanel.png"
                alt="solar_panel"
                className={classes.solarImage}
              />
            </div>
          )}
        </div>
        <Snackbar
          open={snackbarState}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default LoginView;
