import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classes from './PaymentPage.module.css';
import NavbarMain from '../../../../containers/NavbarMainPage/NavbarMain';
import Footer from '../../../../containers/AlternativeFooter/Footer';

import { MdDone, MdReport } from 'react-icons/md';
import { IconContext } from 'react-icons/lib';

import {
  withStyles,
  Button
} from '@material-ui/core';

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'var(--secondary-color)',
    color: 'white',
    marginTop: 20
  }
})(Button);

const PaymentResponse = (props) => {
  const { success } = props;

  const { t, } = useTranslation();

  useEffect(() => {
    if (success) {
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/odeme_basarili" });
    } else {
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/odeme_basarisiz" });
    }
  }, []);

  return (
    <div className={classes.outerWrapper}>
      <NavbarMain />
      <div 
        style={{
          height: '80vh', 
          width:'100%', 
          backgroundColor: 'whitesmoke',
          display:'flex',
          flexDirection: 'column',
          textAlign:'center',
          placeContent: 'center',
          placeItems: 'center'
        }}>
        <div className={classes.circleDiv}>
          <IconContext.Provider value={{ color: '#228f26', size: 150 }}>
            {success && <MdDone />}
            {!success && <MdReport />}
          </IconContext.Provider>
        </div>
        <div style={{ maxWidth: '600px' }}>
          {success && <p style={{fontSize: '20px'}}>{t('PaymentResponse.successMessage')}</p>}
          {!success && <p style={{fontSize: '20px'}}>{t('PaymentResponse.failMessage')}</p>}
          <div>
            <Link
              to="/login"
              style={{ textDecoration: 'none' }}
            >
              <StyledButton
                size="large"
                type="submit"
                variant="contained"
              >
                {t('PaymentResponse.backToHome')}
              </StyledButton>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PaymentResponse;