/* global google */
import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import classes from './ReactGoogleMapExample.module.css'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import {
  changeLocation,
  changeTempLocation,
  changeMarker,
  changeArea,
  changeInfoWindow,
  changeDrawPoints,
  changePolygonPoints,
  changePanelAmount,
  changeCurrentZoom,
  changeTempMapZoom,
  changeIlce,
  changeCityName,
  changePlaka
} from '../../services/products/product/reducer';
import { IconContext } from 'react-icons/lib';
import { RiArrowGoBackLine } from 'react-icons/ri';
import axios from 'axios';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class Map extends Component {
  constructor(props) {
    super(props);
    this.shapes = [];
    this.state = {
      currentMode: "draw",
      showError: false,
      enlem: null,
      boylam: null,
      start: true,
      startMobile: true,
      mobileDrawButton: false,
      markerLat: null,
      marketLng: null,
      open: true,
      cizimVar: false,
    };
    this.handleOverlayComplete = this.handleOverlayComplete.bind(this);
    this.deleteShapes = this.deleteShapes.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  mapMounted = ((ref) => {
    this.map = ref;
  })

  /* componentDidMount() {
    this.setState({
      markerLat: this.props.latitude,
      marketLng: this.props.longitude
    });
  } */

  componentDidMount() {
    setTimeout(() => {
      this.props.dispatch(changeLocation({ latitude: this.map.getCenter().lat() + 0.00001, longitude: this.map.getCenter().lng() + 0.00001 }));
      }, 500);
  }


  handleOverlayComplete(e) {

    this.deleteShapes();

    const shape = e.overlay;
    shape.type = e.type;
    google.maps.event.addListener(shape, "click", () => {
      this.toggleSelection(shape);
    });
    let areaGoogle = google.maps.geometry.spherical.computeArea(shape.getPath());
    //console.log(shape.getPath().getArray());

    if (areaGoogle < 15) {
      this.setState({
        showError: true
      });
      this.shapes.push(shape);
      this.shapes.forEach(shape => shape.setMap(null));
      localStorage.setItem('alan', '0');
      this.props.dispatch(changeArea({roofArea: 0}));
    } else {
      this.setState({
        enlem: shape.getPath().getAt(0).lat(),
        boylam: shape.getPath().getAt(0).lng(),
        currentMode: null,
        cizimVar: true
      });
      
      //this.props.dispatch(changePanelAmount({panelAmount: Math.round(areaGoogle * 0.35)}));
      this.props.dispatch(changeArea({roofArea: Math.round(String(Math.round(areaGoogle)))}));
      this.calculatePanel(Math.round(String(Math.round(areaGoogle))));
      //this.props.dispatch(changeLocation({latitude: shape.getPath().getAt(0).lat(), longitude: shape.getPath().getAt(0).lng()}));
      //this.props.dispatch(changeTempLocation({tempLatitude: shape.getPath().getAt(0).lat(), tempLongitude: shape.getPath().getAt(0).lng()}));
      //localStorage.setItem('enlem', String(shape.getPath().getAt(0).lat()));
      //localStorage.setItem('boylam', String(shape.getPath().getAt(0).lng()))
      this.toggleSelection(shape);
      this.shapes.push(shape);
      //localStorage.setItem('cizim', JSON.stringify(shape));
      localStorage.setItem('alan', Math.round(String(Math.round(areaGoogle))));
      //let drawPoints = [];
      let drawPointsString = "";
      let tempPolygonPoints = [];
      let areaCenterLatList = [];
      let areaCenterLngList = [];
      shape.getPath().getArray().map((point, index) => {
          //console.log(point.lat());
          //drawPoints.push([point.lat(), point.lng()]);
          if (shape.getPath().getArray().length - 1 === index ) {
            drawPointsString += String(point.lat()) + "," + String(point.lng())
          } else {
            drawPointsString += String(point.lat()) + "," + String(point.lng()) + "|"
          }

          //Alanın tam ortasına imleç koymak için listeye kaydet sonrasında ortalamasını al
          areaCenterLatList.push(Number(point.lat()));
          areaCenterLngList.push(Number(point.lng()));

          // Define the LatLng coordinates for the polygon's path. Geri geldiğinde tekrar çizili olması için.
          tempPolygonPoints.push({ lat: Number(point.lat()), lng: Number(point.lng())});

        });
      //console.log(tempPolygonPoints);
      let average = (array) => array.reduce((a, b) => a + b) / array.length;
      this.props.dispatch(changeCurrentZoom({currentZoom: this.map.getZoom()}))
      this.props.dispatch(changeTempLocation({tempLatitude: average(areaCenterLatList), tempLongitude: average(areaCenterLngList)}));
      this.props.dispatch(changeLocation({latitude: average(areaCenterLatList), longitude: average(areaCenterLngList)}));
      this.props.dispatch(changeDrawPoints({drawPoints: drawPointsString}));
      this.props.dispatch(changePolygonPoints({polygonPoints: tempPolygonPoints}));
      this.props.dispatch(changeMarker({markerOn: true}));
      this.getCityDistrict(average(areaCenterLatList), average(areaCenterLngList));
    }
    //console.log(this.state.chosenCoord);
    //google.maps.drawing.DrawingManager.setDrawingMode(null);
  }

  toggleSelection(shape) {
    if (shape.getEditable() === true) shape.setEditable(false);
    else shape.setEditable(true);
  }

  deleteShapes() {
    this.shapes.forEach(shape => shape.setMap(null));
    this.setState({
      currentMode: "draw",
      cizimVar: false
    });
    this.props.dispatch(changeArea({roofArea: 0}));
    this.props.dispatch(changePolygonPoints({polygonPoints: []}));
    this.props.dispatch(changeMarker({markerOn: false}));
  }

  switchToDrawMode() {
    this.setState({
      currentMode: "draw",
      start: false
    });
    this.props.dispatch(changeInfoWindow({infoWindow: true}));
  }

  switchToDrawModeMobile() {
    this.setState({
      currentMode: "draw",
      mobileDrawButton: false
    });
  }

  switchToStartState() {
    this.setState({
      //currentMode: "draw",
      startMobile: false,
      mobileDrawButton: true
    });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      showError: false
    });
  };

  handleChange(event) {
    this.props.dispatch(changeArea({roofArea: event.target.value}));
    localStorage.setItem('alan', Math.round(String(Math.round(event.target.value))));
    //this.calculatePanel(Math.round(String(Math.round(event.target.value))));
  }

  handleClick(event) {
    /* if (this.props.mobile || this.props.alanTercihi === 'manuel') {
      this.setState({
        markerLat: event.latLng.lat(),
        markerLng: event.latLng.lng()
      });
      //this.map.setCenter()
      //this.props.dispatch(changeLocation({latitude: event.latLng.lat(), longitude: event.latLng.lng()}));
      this.props.dispatch(changeTempLocation({tempLatitude: event.latLng.lat(), tempLongitude: event.latLng.lng()}));
      this.props.dispatch(changeMarker({markerOn: true}));
      //console.log(this.map.getCenter().lat());
    } */
    this.setState({
      markerLat: event.latLng.lat(),
      markerLng: event.latLng.lng()
    });
    this.props.dispatch(changeTempLocation({tempLatitude: event.latLng.lat(), tempLongitude: event.latLng.lng()}));
    this.props.dispatch(changeMarker({markerOn: true}));
    this.props.dispatch(changeCurrentZoom({currentZoom: this.map.getZoom()}));
    this.getCityDistrict(event.latLng.lat(), event.latLng.lng());
  }

  handleOnMapDrag(event) {
    this.props.dispatch(changeLocation({latitude:this.map.getCenter().lat(), longitude: this.map.getCenter().lng()}));
    this.props.dispatch(changeTempLocation({tempLatitude: this.map.getCenter().lat(), tempLongitude: this.map.getCenter().lng()}));
    /* this.setState({
      markerLat: this.map.getCenter().lat(),
      markerLng: this.map.getCenter().lng()
    }); */
    // this.props.dispatch(changeMarker({markerOn: true}));
}

  handleZoomChange(event) {
    this.props.dispatch(changeTempMapZoom({tempMapZoom: this.map.getZoom()}))
  }

  calculatePanel(alan) {
    const panelSayisi = Math.round(alan / 5);
    this.props.dispatch(changePanelAmount({panelAmount: panelSayisi}));
  }

  getCityDistrict(lat, lng) {
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyC8haRE39XSEpuxc8tR7s90FVhD3NxKUzg`)
        .then((response) => {
            let countryCode = "TR";
            response.data.results[0].address_components.forEach(addressObject => {
                // Decide component names accorfing to the country
                // Türkiye olduğu durum
                if (addressObject.types[0] === "country" && addressObject.short_name !== "TR") {
                    countryCode = addressObject.short_name;
                }
                });
            response.data.results[0].address_components.forEach(addressObject => { 
                // Decide component names accorfing to the country
                // Türkiye olduğu durum
                if (countryCode === "TR") {
                  if (addressObject.types[0] === "postal_code") {
                  this.props.dispatch(changePlaka({ plaka: addressObject.short_name.slice(0,2)}));
                  } else if (addressObject.types[0] === "administrative_area_level_2" ) {
                  if (String(addressObject.short_name) === "null" || String(addressObject.short_name) === "undefined") {
                    this.props.dispatch(changeIlce({ ilce: 'Merkez'}));
                  } else {
                    this.props.dispatch(changeIlce({ ilce: String(addressObject.short_name)}));
                  }
                  } else if (addressObject.types[0] === "administrative_area_level_1" ) {
                    this.props.dispatch(changeCityName({ cityName: String(addressObject.short_name)}));
                  }
                } else {
                  // Türkiye harici ülke olduğu durum
                  // Şehir ismi
                  if (addressObject.types[0] === "locality") {
                    this.props.dispatch(changeCityName({ cityName: String(addressObject.short_name)}));
                  } else {
                  if (addressObject.types[0] === "administrative_area_level_2") {
                    this.props.dispatch(changeIlce({ ilce: String(addressObject.short_name)}));
                  } else {
                    this.props.dispatch(changeIlce({ ilce: '-'}));
                  }
                  }
                }
                });
        })
        .catch((error) => console.log(error));
};

  render() {

    return (
      <>
        <GoogleMap
          onClick={(e) => this.handleClick(e)}
          onDragEnd={(e) => this.handleOnMapDrag(e)}
          onZoomChanged={(e) => this.handleZoomChange(e)}
          /* defaultZoom={7} */
          zoom={this.props.tempMapZoom}
          //defaultCenter={{lat: Number(localStorage.getItem('enlem')), lng: Number(localStorage.getItem('boylam'))} || this.props.center}
          defaultMapTypeId="hybrid"
          defaultOptions={{
            /* scrollwheel: false,  mouse scroll'unu kapatıyor zoom için*/
            /* disableDefaultUI: true, */
            clickableIcons: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            /* styles: [
              {
                  featureType: 'all',
                  elementType: 'labels',
                  stylers: [
                      { visibility: 'off' }
                  ]
              },
              {
                  featureType: 'road',
                  elementType: 'labels',
                  stylers: [
                      { visibility: 'on' }
                  ]
              },
              {
                featureType: 'administrative',
                elementType: 'labels',
                stylers: [
                    { visibility: 'on' }
                ]
              },
              {
                  featureType: 'water',
                  elementType: 'labels',
                  stylers: [
                      { visibility: 'on' }
                  ]
              }
            ] */
          }}
          //center={{lat: Number(localStorage.getItem('enlem')), lng: Number(localStorage.getItem('boylam'))}}
          //center={{lat: this.props.markerOn ? this.props.tempLatitude : this.props.latitude, lng: this.props.markerOn ? this.props.tempLongitude : this.props.longitude}}
          center={{lat: this.props.latitude, lng: this.props.longitude}}
          ref={this.mapMounted}
        >
          
          {(this.props.mobile || this.props.alanTercihi === "manuel") && this.props.markerOn &&
          <Marker
            key={Math.round(this.state.markerLat)}
            //position={{ lat: this.state.markerLat, lng: this.state.markerLng }}
            position={{ lat: this.props.tempLatitude, lng: this.props.tempLongitude }}
          />}

          {!this.state.cizimVar && this.props.polygonPoints.length > 0 && <Polygon 
            key={1}
            paths={this.props.polygonPoints}
            options={{
              strokeWeight: 5,
              fillOpacity: 0.45,
              fillColor: '#EDD500',
              strokeColor: '#EDD500'
            }}
          />}

          {this.props.desktop && this.props.alanTercihi === "cizim" &&
          <DrawingManager
            //defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
            key={this.state.currentMode}
            defaultOptions={{
              polygonOptions: {
                strokeWeight: 5,
                fillOpacity: 0.45,
                fillColor: '#EDD500',
                strokeColor: '#EDD500'
              },
              drawingControl: true,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['polygon']
              }
            }}
            drawingMode={this.state.currentMode === "draw"
              ? google.maps.drawing.OverlayType.POLYGON
              : null}
            onOverlayComplete={this.handleOverlayComplete}
            
          />}

          {/* Desktop Info Box */}
          {(this.props.roofArea || this.props.polygonPoints.length > 0) && this.props.desktop && /* this.props.alanTercihi === "cizim" && */
          <>
            <div className={classes.desktopInfoBoxWrapper}>
              <div className={classes.desktopInfoBox}>
                <span className={classes.infoBoxText}>{this.props.roofArea} m<sup>2</sup></span>
                {(this.props.panelAmount !== 0) && <span className={classes.infoBoxText}>{this.props.panelAmount} güneş kolektörü</span>}
                <button onClick={this.deleteShapes} aria-label="Tekrar Çiz" type="button" className={classes.drawAgainButton}>
                  <IconContext.Provider value={{ color: '#ffffff', size: 25 }}>
                    <RiArrowGoBackLine />
                  </IconContext.Provider>
                  {this.props.alanTercihi === "cizim" && <span className={classes.resetText}>{this.props.drawAgainButtonText}</span>}
                  {this.props.alanTercihi === "manuel" && <span className={classes.resetText}>{this.props.sifirlaText}</span>}
                </button>
              </div>
            </div>
          </>}
          {(this.props.stepNumber === 2 && this.props.desktop) &&
          <>
            <div className={classes.directionTextRegion} style={{ top: '8rem' }}>
              <span className={classes.directionText}>Kuzey</span>
            </div>
            <div className={classes.directionTextRegion} style={{ bottom: '8rem' }}>
              <span className={classes.directionText}>Güney</span>
            </div>
            <div className={classes.directionTextRegion} style={{ top: '50%', height: 'fit-content', textAlign: 'start' }}>
              <span className={classes.directionText}>Batı</span>
            </div>
            <div className={classes.directionTextRegion} style={{ top: '50%', height: 'fit-content', textAlign: 'end' }}>
              <span className={classes.directionText}>Doğu</span>
            </div>
          </>
          }

          {/* Mobile Info Box */}
        </GoogleMap>
        
        <Snackbar open={this.state.showError} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="info">
            {this.props.smallAreaAlertText}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  latitude: state.location.latitude,
  longitude: state.location.longitude,
  zoomLevel: state.location.zoomLevel,
  roofArea: state.location.roofArea,
  markerOn: state.location.markerOn,
  tempLatitude: state.location.tempLatitude,
  tempLongitude: state.location.tempLongitude,
  polygonPoints: state.location.polygonPoints,
  panelAmount: state.location.panelAmount,
  alanTercihi: state.location.alanTercihi,
  currentZoom: state.location.currentZoom,
  tempMapZoom: state.location.tempMapZoom,
  stepNumber: state.steps.stepNumber
});

export default connect(mapStateToProps)(withGoogleMap(Map));
