import React from "react";
import axios from 'axios';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    changeLocation,
    changeDetailedAdress,
    changeZoom,
    changeTempLocation,
    changeTabButonlarAktif,
    changeIlce,
    changeCityName,
    changePlaka,
    changeTempMapZoom,
} from '../../services/products/product/reducer';
import { changeStep } from '../../services/products/product/stepReducer';
import Button from '@material-ui/core/Button';

const StyledAlert = withStyles({
    root: {
      backgroundColor: 'var(--primary-color)'
    }
  })(MuiAlert);

function Alert(props) {
  return <StyledAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    button: {
      marginLeft: '5px',
      borderRadius: '30px',
      color: 'white',
      padding: '0.75rem 1.25rem',
      textTransform: 'none',
      backgroundColor: 'var(--primary-color)',
      '&:hover': {
        backgroundColor: '#00376996',
      },
    },
  }));

export default function UseMyLocation() {

    const dispatch = useDispatch()
    //const { latitude, longitude } = useSelector(state => state.location)
    const { t, } = useTranslation();

    const classes = useStyles();

    const [showInfo, setShowInfo] = React.useState(false);

    const getGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function(position) {

                    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyC8haRE39XSEpuxc8tR7s90FVhD3NxKUzg`)
                        .then((response) => {
                            dispatch(changeDetailedAdress({detailedAdress: response.data.results[0].formatted_address}));
                            let countryCode = "TR";
                            response.data.results[0].address_components.forEach(addressObject => {
                                // Decide component names accorfing to the country
                                // Türkiye olduğu durum
                                if (addressObject.types[0] === "country" && addressObject.short_name !== "TR") {
                                    countryCode = addressObject.short_name;
                                }
                                });
                            response.data.results[0].address_components.forEach(addressObject => {
                                // Decide component names accorfing to the country
                                // Türkiye olduğu durum
                                if (countryCode === "TR") {
                                  if (addressObject.types[0] === "postal_code") {
                                  dispatch(changePlaka({ plaka: addressObject.short_name.slice(0,2)}));
                                  } else if (addressObject.types[0] === "administrative_area_level_2" ) {
                                  if (String(addressObject.short_name) === "null" || String(addressObject.short_name) === "undefined") {
                                      dispatch(changeIlce({ ilce: 'Merkez'}));
                                  } else {
                                      dispatch(changeIlce({ ilce: String(addressObject.short_name)}));
                                  }
                                  } else if (addressObject.types[0] === "administrative_area_level_1" ) {
                                  dispatch(changeCityName({ cityName: String(addressObject.short_name)}));
                                  }
                                } else {
                                  // Türkiye harici ülke olduğu durum
                                  // Şehir ismi
                                  if (addressObject.types[0] === "locality") {
                                  dispatch(changeCityName({ cityName: String(addressObject.short_name)}));
                                  } else {
                                  if (addressObject.types[0] === "administrative_area_level_2") {
                                      dispatch(changeIlce({ ilce: String(addressObject.short_name)}));
                                  } else {
                                      dispatch(changeIlce({ ilce: '-'}));
                                  }
                                  }
                              }
                              });
                        })
                        .catch((error) => console.log(error));
                    
                    dispatch(changeLocation({latitude: position.coords.latitude, longitude: position.coords.longitude}));
                    dispatch(changeTempLocation({tempLatitude: position.coords.latitude, tempLongitude: position.coords.longitude}));
                    dispatch(changeTabButonlarAktif({tabButonlarAktif: true}));
                    dispatch(changeZoom({zoomLevel: 18}));
                    dispatch(changeTempMapZoom({tempMapZoom: 18}));
                    dispatch(changeStep({stepNumber: 1}));
                    //console.log("Latitude is :", position.coords.latitude);
                    //console.log("Longitude is :", position.coords.longitude);

                },
                function(error) {
                    console.error("Error Code = " + error.code + " - " + error.message);
                    setShowInfo(true);
                }
            );
        } 
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setShowInfo(false);
      };


    return (
        <>
            <div >
                <Button
                    variant="contained"
                    onClick={getGeoLocation} 
                    color="primary"
                    className={classes.button}
                    endIcon={<MyLocationIcon />}
                    >
                    {t('AdressPage.currentLocation')}
                </Button>
                {/* <IconButton 
                    color="primary" 
                    aria-label="use my location"
                    className={classes.button}
                    onClick={getGeoLocation} 
                >
                    <MyLocationIcon />
                </IconButton> */}
            </div>
            <Snackbar open={showInfo} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="info">
                    Mevcut konumu kullanabilmek için konum bilgisine erişime izin vermelisiniz. 
                </Alert>
            </Snackbar>
        </>
    );
}
