import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../components/axios/axiosApi';
import classes from './SummaryPage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MaskedInput from 'react-text-mask';
import { resetStep } from '../../services/products/product/stepReducer';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['0', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function ContactInfoComp(props) {

    const dispatch = useDispatch();
    const { t, } = useTranslation();
    const {
      apiRequestSource,
      userId,
      firstSession
    } = useSelector(state => state.users);

    const {
      roofArea,
      drawPoints,
      cityName,
      ilce,
      tempLatitude,
      tempLongitude,
      currentZoom,
      polygonPoints
    } = useSelector(state => state.location);

    const {
      fatura,
      tesis,
      meskenTipi,
      roofTilt,
      sGucu,
      trafoGucu,
      batarya,
      sarj,
      cephe,
      consumptionProfile
    } = useSelector(state => state.summary);

    const {
      module,
      availableModules,
    } = useSelector(state => state.module);

    const {
      kvkkLink,
      firmaKodu,
      firstScreenAtEnd
    } = useSelector(state => state.companyInfo);

    function b64DecodeUnicode(str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }

    function parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
    }

    const [analysisData, setAnalysisData] = useState({
        panel: null,
        guc: null,
        tlMaliyet: null,
        dolarMaliyet: null,
        uretim: null,
        amortisman: null,
        amortismanGonder: null,
        agac: null,
        co2: null,
        yillikTasarruf: null,
        dolar: null,
        aylikTuketim: null,
        yillikTuketim: null,
        yillikTasarrufUSD: null,
      });

    const [contactInfo, setContactInfo] = useState(
        {
            name: '',
            surname: '',
            phoneNumber: '0(   )    -    ',
            email: '',
        }
    );

    const [validity, setValidity] = useState(
        {
            nameValid: true,
            surnameValid: true,
            phoneNumberValid: true,
            emailValid: true
        }
    );

    const handleChange = () => {
      if (firstScreenAtEnd === "contact") {
        dispatch(changeStep({stepNumber: 3}));
      } else {
        dispatch(changeStep({stepNumber: 4}));
      }
      
    };

    const checkValidity = (value, inputArea) => {
        let isValid = true;
        if (inputArea === "name") {
            isValid = value && isValid;
            setValidity({
                ...validity,
                nameValid: isValid
            })
        }

        if (inputArea === "surname") {
          isValid = value && isValid;
          setValidity({
              ...validity,
              surnameValid: isValid
          })
      }

        if (inputArea === "email") {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid;
            setValidity({
                ...validity,
                emailValid: isValid
            })
        }

        if(inputArea === "phoneNumber") {
            // const pattern = /^\d+$/;
            const pattern = /^0[.\(\/]5[0-9][0-9][.\)\/][.\ \/][1-9]([0-9]){2}[.\-\/]([0-9]){4}$/;
            isValid = pattern.test(value) && isValid;
            setValidity({
                ...validity,
                phoneNumberValid: isValid
            })
        }
    }

    const inputChangeHandler = (event) => {
        
        const value = event.target.value;
        checkValidity(value, event.target.name);
        setContactInfo({
            ...contactInfo,
            [event.target.name]: value
        })
    }

    const [KVKKchecked, setChecked] = React.useState(false);
    const [KVKKchecked2, setChecked2] = React.useState(false);
    const [KVKKchecked3, setChecked3] = React.useState(false);
    const [KVKKchecked4, setChecked4] = React.useState(false);

    const handleChangeCheckbox = (event) => {
        setChecked(event.target.checked);
      };

    const handleChangeCheckbox2 = (event) => {
      setChecked2(event.target.checked);
    };

    const handleChangeCheckbox3 = (event) => {
      setChecked3(event.target.checked);
    };

    const handleChangeCheckbox4 = (event) => {
      setChecked4(event.target.checked);
    };

    const submitHandler = async ( event ) => {
      event.preventDefault();
      alert('Teklif talebiniz alınmıştır!');
      localStorage.clear();
      dispatch(resetStep());
    }

    return (
            <>
                <div className={classes.rightWrapperBefore}>
                    <div className={classes.rightWrapperlv1Before}>
                        <div className={classes.rightWrapperlv2Before} style={{ maxWidth: 600 }}>
                            <div className={classes.title}>
                                <h2 className={classes.titleh2}>Teklif Al</h2>
                                <span className={classes.infoText}>Proje teklifi almak için lütfen aşağıdaki bilgileri doldurun.</span>
                            </div>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputs}>
                                  <div className={classes.input}>
                                      <TextField 
                                          fullWidth
                                          name="name"
                                          id="given-name"
                                          autoComplete="given-name"
                                          type="text"
                                          label={t('TeklifAlPage.isim')}
                                          onChange={(event) => inputChangeHandler(event)}
                                          value={contactInfo.name}
                                          helperText={!validity.nameValid ? t('TeklifAlPage.inputErrorİsim') : ""}
                                          error={!validity.nameValid} />
                                  </div>
                                  <div className={classes.input}>
                                      <TextField 
                                          fullWidth
                                          name="surname"
                                          id="family-name"
                                          autoComplete="family-name"
                                          type="text"
                                          label={t('TeklifAlPage.soyisim')}
                                          onChange={(event) => inputChangeHandler(event)}
                                          value={contactInfo.surname}
                                          helperText={!validity.surnameValid ? t('TeklifAlPage.inputErrorSoyisim') : ""}
                                          error={!validity.surnameValid} />
                                  </div>
                                  <div className={classes.input}>
                                      <TextField 
                                          fullWidth
                                          id="email"
                                          label={t('TeklifAlPage.email')}
                                          name="email"
                                          autoComplete="email"
                                          onChange={(event) => inputChangeHandler(event)}
                                          value={contactInfo.email}
                                          helperText={!validity.emailValid ? t('TeklifAlPage.inputErrorEmail') : ""}
                                          error={!validity.emailValid} />
                                  </div>
                                  <div className={classes.input}>
                                      <TextField 
                                          fullWidth 
                                          id="phoneNumber" 
                                          label={t('TeklifAlPage.telefon')}
                                          name="phoneNumber"
                                          autoComplete="tel-national"
                                          onChange={(event) => inputChangeHandler(event)}
                                          value={contactInfo.phoneNumber}
                                          helperText={!validity.phoneNumberValid ? t('TeklifAlPage.inputErrorTelefon') : ""}
                                          error={!validity.phoneNumberValid}
                                          InputProps={{
                                            inputComponent: TextMaskCustom,
                                          }}
                                          inputComponent={TextMaskCustom} />
                                  </div>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <div className={classes.checkboxWrapper} >
                                        <div className={classes.checkbox}>
                                            <div className={classes.checkboxLabel}>
                                                <label>
                                                    <p>{t('TeklifAlPage.kvkk1')}</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.checkboxWrapper} >
                                        <div className={classes.checkbox}>
                                            <Checkbox
                                                checked={KVKKchecked2}
                                                onChange={handleChangeCheckbox2}
                                                color="default"
                                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                            />
                                            <div className={classes.checkboxLabel}>
                                                <label>
                                                    <p>{t('TeklifAlPage.kvkk6')} {firmaKodu} {t('TeklifAlPage.kvkk7')} {firmaKodu} {t('TeklifAlPage.kvkk8')}</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.checkboxWrapper} >
                                        <div className={classes.checkbox}>
                                            <Checkbox
                                                checked={KVKKchecked3}
                                                onChange={handleChangeCheckbox3}
                                                color="default"
                                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                                            />
                                            <div className={classes.checkboxLabel}>
                                                <label>
                                                    <p>{t('TeklifAlPage.kvkk9')}</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.formwizardUpdate}>
                                <div className={classes.formwizardlv1}>
                                    <div className={classes.buttonContainer}>
                                        <button onClick={() => handleChange(3)} className={classes.backButton}>
                                            <div className={classes.backButtonIcon}>
                                                <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                                <AiOutlineArrowRight />
                                                </IconContext.Provider>
                                            </div>
                                            <span className={classes.startText}>{t('Buton.geri')}</span>
                                        </button>
                                        <button onClick={submitHandler} disabled={!(validity.emailValid && KVKKchecked2 && KVKKchecked3 && contactInfo.email )} className={classes.nextButton}>
                                            <span className={classes.finishText}>{t('Buton.ileri')}</span>
                                            <div className={classes.buttonIcon}>
                                                <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                                <AiOutlineArrowRight />
                                                </IconContext.Provider>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}

export default ContactInfoComp;