import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './DrawingPage.module.css';
import { IconContext } from 'react-icons/lib';
import { AiOutlineArrowRight } from 'react-icons/ai';
import GoogleMap from '../GoogleMapViewer/Map';
import { useSelector, useDispatch } from 'react-redux';
import { changeStep } from '../../services/products/product/stepReducer';
import { changeBaseType } from '../../services/products/product/summaryReducer';
import { useMediaQuery } from 'react-responsive';
import { 
    changeArea,
    changePanelAmount,
    changeAlanTercihi,
    changeMarker
} from '../../services/products/product/reducer';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import StepProgress from '../../components/StepProgress';

const MarginDivider = withStyles({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
})(Divider);

function DrawingPage(props) {

    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const { baseType } = useSelector(state => state.summary);
    const { roofArea, polygonPoints, markerOn, alanTercihi } = useSelector(state => state.location);
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const handleChangeChoice = (event) => {
        dispatch(changeAlanTercihi({ alanTercihi: event.target.value }));
        if (event.target.value === "manuel") {
            dispatch(changeMarker({markerOn: true}));
        } else {
            dispatch(changeMarker({markerOn: false}));
        }
      };

    const handleChangeBaseType = (event) => {
        dispatch(changeBaseType({ baseType: event.target.value }));
      };

    // this.props.dispatch(changePanelAmount({panelAmount: Math.round(areaGoogle * 0.35)}));

    const handleChange = (newValue) => {
        dispatch(changeStep({stepNumber: newValue}));
      };

    const calculatePanel = (alan) => {
        const panelSayisi = Math.round(alan / 2.25);
        dispatch(changePanelAmount({panelAmount: panelSayisi}));
      }

    const handleAreaInput = (event) => {
        dispatch(changeArea({roofArea: event.target.value}));
        //dispatch(changePanelAmount({panelAmount:  Math.round(event.target.value * 0.35)}));
        calculatePanel(Math.round(event.target.value));
      };

    return (
        <>
            <div className={classes.leftWrapper}>
                <GoogleMap />
            </div>
            <div className={classes.rightWrapper}>
                <div className={classes.rightWrapperlv1}>
                    <div className={classes.rightWrapperlv2}>
                        <div className={classes.title}>
                            <h2 className={classes.titleh2}>{baseType === "roof" ? t('DrawingPage.catiOlc') : t('DrawingPage.araziOlc')}</h2>
                            {isDesktopOrLaptop && <>
                            <div style={{ display: 'flex' }} >
                                <Radio
                                    checked={alanTercihi === 'cizim'}
                                    onChange={handleChangeChoice}
                                    value="cizim"
                                    color="default"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'Cizim' }}
                                />
                                <span className={classes.spanText}>{baseType === "roof" ? t('DrawingPage.catiDrawingMetin') : t('DrawingPage.araziDrawingMetin')}</span>
                            </div>
                            <MarginDivider variant="middle" />
                            <div style={{ display: 'flex', alignItems: 'center' }} >
                                <Radio
                                    checked={alanTercihi === 'manuel'}
                                    onChange={handleChangeChoice}
                                    value="manuel"
                                    color="default"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'Elle alan gir' }}
                                />
                                <span className={classes.spanText}>{baseType === "roof" ? t('DrawingPage.catiManuelMetin') : t('DrawingPage.araziManuelMetin')}</span>
                            </div>
                            </>}
                            {isTabletOrMobile && <span className={classes.spanText}>{baseType === "roof" ? t('DrawingPage.catiMobile') : t('DrawingPage.araziMobile')}</span>}
                        </div>
                        {(alanTercihi === "manuel" || isTabletOrMobile) && 
                        <div className={polygonPoints.length > 0 ? classes.inputBoxDisabled : classes.inputBox}>
                            <input 
                                disabled={polygonPoints.length > 0}
                                type="number"
                                placeholder={baseType === "roof" ? t('DrawingPage.alanLabel') : t('DrawingPage.alanAraziLabel')}
                                name="çatı alanı"
                                onChange={handleAreaInput}
                                className={classes.roofAreaInput} 
                                value={roofArea}
                            />
                            <span className={classes.prefix}>m<sup>2</sup></span>
                        </div>}
                    </div>
                    <div className={classes.formwizard}>
                        {isTabletOrMobile && 
                        <div style={{
                            padding: '0.5rem 50px',
                            display: 'grid',
                            gridTemplateColumns: 'auto 1fr auto',
                            alignItems: 'center',
                            gridGap: '1rem',
                        }}>
                            <img src="/images/sun.png" style={{width: '20px' }} />
                            <StepProgress />
                            <img src="/images/solar-panel-home.png" style={{width: '20px' }} />
                        </div>}
                        <div className={classes.formwizardlv1}>
                            <div className={classes.buttonContainer}>
                                <button onClick={() => handleChange(0)} className={classes.backButton}>
                                    <div className={classes.backButtonIcon}>
                                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                        </IconContext.Provider>
                                    </div>
                                    <span className={classes.startText}>{t('Buton.geri')}</span>
                                </button>
                                <button disabled={!((roofArea > 15) && markerOn)} onClick={() => handleChange(2)} className={classes.nextButton}>
                                    <span className={classes.startText}>{t('Buton.ileri')}</span>
                                    <div className={classes.buttonIcon}>
                                        <IconContext.Provider value={{ color: '#ffffff', size: 16 }}>
                                        <AiOutlineArrowRight />
                                        </IconContext.Provider>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DrawingPage;