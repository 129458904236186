/* eslint-disable prefer-template */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeUploadStepNumber, changeCompanyProcessNumber } from '../../services/products/product/stepReducer';
import classes from './ZolarTracker.module.css';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';

const ProgressBar = (props) => {
    const { t, } = useTranslation();
    const dispatch = useDispatch();

    const { stepCount } = props;

    // const { loggedIn } = useSelector((state) => state.users);
    const { uploadStepNumber } = useSelector(state => state.steps);

    const handleChange = (newValue) => {
        dispatch(changeUploadStepNumber({uploadStepNumber: newValue}));
    };

    return (
    <div className={classes.outerWrapper}>
        <div className={classes.wrapper}>
            <div style={{width: '100%'}}>
                <div className={classes.innerWrapper}>
                    <ol className={classes.stepper}>
                        {Array.from({length: stepCount}, (_, i) => i + 1).map((step, index) => (
                            <li className={classes.step}>
                            
                            {(uploadStepNumber === stepCount) && 
                            <>
                                <span className={classes.submitLine}></span>
                                <a className={classes.submitPoint}></a>    
                            </>
                            }
                            {(uploadStepNumber !== stepCount) && 
                            <>
                                <span className={uploadStepNumber >= step ? classes.activeLine : classes.passiveLine}></span>
                                <a className={uploadStepNumber >= step ? classes.activePoint : classes.passivePoint}></a>    
                            </>
                            }
                        </li>   
                        ))}
                    </ol>
                    <div className={classes.buttonContainer}>
                        <div  onClick={() => dispatch(changeUploadStepNumber({uploadStepNumber: uploadStepNumber - 1}))} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                            <div style={{marginRight: 8}}>
                                <IconContext.Provider value={{ color: '#112f48', size: 20 }}>
                                    <RiArrowLeftSLine/>
                                </IconContext.Provider>
                            </div>
                            <span style={{paddingBottom: '8px'}}>{t('ElectricityBillUpload.back')}</span>
                        </div>
                        <span>{uploadStepNumber} of {stepCount}</span>
                    </div>
                </div>
            </div>
            <div>
                {(uploadStepNumber < stepCount) && <button 
                    style={{ backgroundColor: 'var(--secondary-color)' }}
                    className={classes.nextButton} //#7dbb18 - başarılı yeşil
                    onClick={() => dispatch(changeUploadStepNumber({uploadStepNumber: uploadStepNumber + 1}))}
                >
                    {t('ElectricityBillUpload.next')}
                </button>}
                {(uploadStepNumber === stepCount) && <Link style={{textDecoration: 'none'}} to="/login">
                <button 
                    style={{ backgroundColor: '#7dbb18'}}
                    className={classes.nextButton} //#7dbb18 - başarılı yeşil
                    onClick={() => {
                        dispatch(changeCompanyProcessNumber({companyProcessNumber: 1}));
                        dispatch(changeUploadStepNumber({uploadStepNumber: 0}));
                    }}
                >
                    {t('ElectricityBillUpload.tamamla')}
                </button>
                </Link>}
            </div>
        </div>
    </div>
  );
};

export default ProgressBar;