import React from 'react';

import classes from './Spinner.module.css';
import { useTranslation } from 'react-i18next';

/* let spinnerText;

if (props.type === "makeAnalysis") {
    spinnerText = (
        <p style={{ color: '#07bbf2' }}>
            Analiziniz yapılıyor..
        </p>
    )
} else if (props.type === "") {

} */


const spinner = (props) => (
    <>
        <div className={classes.Loader}>
            Loading...
        </div>
        <div className={classes.SpinnerText}>
            <p style={{ color: '#07bbf2' }}>
                {props.text}
            </p>
        </div>

    </>
);

export default spinner;