import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  withStyles,
  Box,
  Dialog,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  changeTelefon,
} from '../../services/products/product/summaryReducer';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import axiosInstance from '../axios/axiosApi';
import MaskedInput from 'react-text-mask';
import i18n from '../../i18n';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['0', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 900
  }
})(Dialog);

const SMSDialog = (props) => {
  const {
    unreceivedSMS,
    changeFunc,
    changeSnackbarMessage,
    changeSnackbarType,
    changeSnackbarState,
  } = props;

  const { t, } = useTranslation();
  const dispatch = useDispatch();

  const { telefon } = useSelector(state => state.summary);

  const handleClose = () => {
    changeFunc(false);
  };

  return (
    <div>
      <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={unreceivedSMS}>
        <DialogContent dividers>
          <div style={{ padding: '40px', display: 'flex', flexDirection: 'column' }}>
            <div>
              <span>
                {t('QuoteSendPage.sendAgainText1SMS')}
                {telefon}
                {t('QuoteSendPage.sendAgainText2SMS')}
              </span>
              <div style={{ marginTop: '20px' }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    axiosInstance.post('resend_sms', {
                        'phone_number': "",
                    })
                      .then((response) => {
                        if (response.data.status) {
                            changeSnackbarMessage(response.data.message);
                            changeSnackbarType('success');
                            changeSnackbarState(true);
                        } else {
                            changeSnackbarMessage('Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz.');
                            changeSnackbarType('warning');
                            changeSnackbarState(true);
                        }
                      })
                      .catch((error) => {
                        changeSnackbarMessage('Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz.');
                        changeSnackbarType('warning');
                        changeSnackbarState(true);
                        console.log(error);
                      });

                    handleClose();
                  }}
                  style={{ textTransform: 'none' }}
                >
                  {t('QuoteSendPage.sendAgainButton')}
                </Button>
              </div>
            </div>
            <span
              style={{
                textAlign: 'center',
                padding: '20px',
                fontWeight: 'bold',
                fontSize: '1.2rem'
              }}
            >
              {t('QuoteSendPage.or')}
            </span>
            <div>
              <span>{t('QuoteSendPage.differentSMS')}</span>
              <div>
                <Formik
                  initialValues={{
                    phoneNumber: '0(   )    -    ',
                  }}
                  validationSchema={Yup.object().shape({
                    phoneNumber: Yup
                    .string()
                    .matches(/^0[.\(\/]5[0-9][0-9][.\)\/][.\ \/][1-9]([0-9]){2}[.\-\/]([0-9]){4}$/, 'Geçerli bir telefon numarası giriniz')
                    .required('Telefon numarası gereklidir'),
                  })}
                  onSubmit={(values) => {

                    dispatch(changeTelefon(values.phoneNumber));
                    // send sms to new entered phone number
                    axiosInstance.post('resend_sms', {
                        'phone_number': values.phoneNumber,
                    })
                      .then((response) => {
                        if (response.data.status) {
                            changeSnackbarMessage(response.data.message);
                            changeSnackbarType('success');
                            changeSnackbarState(true);
                        } else {
                            changeSnackbarMessage('Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz.');
                            changeSnackbarType('warning');
                            changeSnackbarState(true);
                        }
                        })
                    .catch((error) => {
                        changeSnackbarMessage('Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz.');
                        changeSnackbarType('warning');
                        changeSnackbarState(true);
                        console.log(error);
                    });


                    handleClose();
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        fullWidth
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        label={t('TeklifAlPage.telefon')}
                        margin="normal"
                        name="phoneNumber"
                        autoComplete="tel-national"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="tel"
                        value={values.phoneNumber}
                        variant="outlined"
                        InputProps={{
                            inputComponent: TextMaskCustom,
                        }}
                        inputComponent={TextMaskCustom}
                      />
                      <Box my={2}>
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          style={{ textTransform: 'none' }}
                        >
                          {t('LoginPage.send')}
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ textTransform: 'none' }}
          >
            {t('LoginPage.kapat')}
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}

export default SMSDialog;