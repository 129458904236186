/* eslint-disable prefer-template */
import React from 'react';
import {
    Grid,
    Container,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classes from '../UserMainPage.module.css';
import UserInfoAccordion from '../../../../components/SolarPackage/UserInfoAccordion';

const RejectedProject = (props) => {

    const { t, } = useTranslation();

    const {
        userInfo,
        imagePreview,
        expandUserInfo,
        setExpandUserInfo,
    } = props;

    return (
        <Container 
            maxWidth={true}
            style={{ height: '100%', backgroundColor:'#F4F6F8' }}
        >
            <Grid 
                container
                spacing={3}
            >
                <Grid
                    item
                    lg={8}
                    md={12}
                    xl={8}
                    xs={12}
                >
                    <UserInfoAccordion
                        userInfo={userInfo}
                        imagePreview={imagePreview}
                        expandUserInfo={expandUserInfo}
                        setExpandUserInfo={setExpandUserInfo}
                        isAccordion={false}
                    />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={12}
                    xl={4}
                    xs={12}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2 className={classes.allh2} style={{ fontSize: '1.5rem' }}>{t('UserMainPage.kesifHizmetiAlindi')}</h2>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default RejectedProject;