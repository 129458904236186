import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Collapse,
  Checkbox,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'var(--secondary-color)',
    color: 'white'
  }
})(Button);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 4,
    fontFamily: 'Poppins',
    borderBottom: 'none',
    fontSize: '1.2rem',
    width: '30%'
  },
}))(TableCell);

const StyledTableCellTitle = withStyles(() => ({
  root: {
    padding: 4,
    fontFamily: 'Poppins',
    borderBottom: 'none',
    fontSize: '1rem',
    width: '70%'
  },
}))(TableCell);

const ProjectOverview = (props) => {
  const {
    pkgOverview,
  } = props;

  const [checked, setChecked] = useState(false || JSON.parse(localStorage.getItem('occupancyPermit')));

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
    localStorage.setItem('occupancyPermit', JSON.stringify(event.target.checked));
  };

  const { t, } = useTranslation();

  return (
    <div style={{ backgroundColor: 'white', width: '90%', position: 'sticky', top: '100px', borderRadius: '10px', padding: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 style={{ fontSize: '1.8rem', fontWeight: 'normal' }}>{t('ProjectOverview.yourProject')}</h2>
        <Table style={{margin: '10px 0px' }}>
          <TableBody>
            <TableRow
              key="yearlySavings"
            >
              <StyledTableCellTitle>
                {t('ProjectOverview.yearlySavings')}
              </StyledTableCellTitle>
              <StyledTableCell>
                {pkgOverview.yearlySavings} TL
              </StyledTableCell>
            </TableRow>
            <TableRow
              key="roi"
            >
              <StyledTableCellTitle>
                {t('ProjectOverview.roi')}
              </StyledTableCellTitle>
              <StyledTableCell>
                {pkgOverview.roi} {t('ProjectOverview.years')}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider />
        <Table style={{ margin: '10px 0px' }}>
          <TableBody>
            <TableRow
              key="yearlyProduction"
            >
              <StyledTableCellTitle>
                {t('ProjectOverview.yearlyProduction')}
              </StyledTableCellTitle>
              <StyledTableCell>
                {pkgOverview.yearlyProduction} MWh
              </StyledTableCell>
            </TableRow>
            <TableRow
              key="yearlyConsumption"
            >
              <StyledTableCellTitle>
                {t('ProjectOverview.yearlyConsumption')}
              </StyledTableCellTitle>
              <StyledTableCell>
                {pkgOverview.yearlyConsumption} MWh
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider />
        <Table style={{ margin: '10px 0px' }}>
          <TableBody>
            <TableRow
              key="savedTrees"
            >
              <StyledTableCellTitle>
                {t('ProjectOverview.savedTrees')}
              </StyledTableCellTitle>
              <StyledTableCell>
                {pkgOverview.savedTrees} {t('ProjectOverview.number')}
              </StyledTableCell>
            </TableRow>
            <TableRow
              key="co2"
            >
              <StyledTableCellTitle>
                {t('ProjectOverview.savedCO2')}
              </StyledTableCellTitle>
              <StyledTableCell>
                {pkgOverview.savedCO2} kg
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider />
        <Table style={{ margin: '10px 0px' }}>
          <TableBody>
            <TableRow
              key="packagePrice"
            >
              <StyledTableCellTitle>
                {t('ProjectOverview.packagePriceVAT')}
              </StyledTableCellTitle>
              <StyledTableCell>
                {pkgOverview.price_TL_kdv} ₺
              </StyledTableCell>
            </TableRow>
            {pkgOverview.is_promotion_available && (
            <>
            <TableRow
              key="discount"
            >
              <StyledTableCellTitle>
                {t('ProjectOverview.discount')}
              </StyledTableCellTitle>
              <StyledTableCell>
                <span style={{ color: '#cc0000' }}>-{pkgOverview.promotion_amount} ₺</span>
              </StyledTableCell>
            </TableRow>
            <TableRow
              key="packagePriceWithDiscount"
            >
              <StyledTableCellTitle>
                {t('ProjectOverview.discountedPrice')}
              </StyledTableCellTitle>
              <StyledTableCell>
                {pkgOverview.price_TL_kdv_with_promotion} ₺
              </StyledTableCell>
            </TableRow>
            </>
            )}
          </TableBody>
        </Table>
        <Collapse in={!checked} style={{ margin: '10px 0px' }}>
          <Alert severity="info">
            <div>
              <p style={{ fontSize: '0.9rem' }}>
                {t('ProjectOverview.yapiKullanimDesc1')}<strong>{t('ProjectOverview.yapiKullanimDesc2')}</strong>{t('ProjectOverview.yapiKullanimDesc3')}
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Checkbox
                  checked={checked}
                  onChange={handleChangeCheck}
                  color="default"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <p style={{ fontSize: '0.9rem' }}>{t('ProjectOverview.yapiKullanimCheckbox')}</p>
              </div>
            </div>
          </Alert>
        </Collapse>
        {checked && <Link
            to="/login/package-payment"
            state={{ pkgOverview: pkgOverview }}
            style={{ textDecoration: 'none' }}
          >
            <StyledButton
                size="large"
                type="submit"
                variant="contained"
                fullWidth
            >
              {t('ProjectOverview.nextToCheckout')}
            </StyledButton>
          </Link>}
          {!checked &&
          <div>
            <StyledButton
                size="large"
                type="submit"
                variant="contained"
                fullWidth
                disabled
            >
              {t('ProjectOverview.nextToCheckout')}
            </StyledButton>
            <span style={{ fontSize: '0.8rem', marginTop: 5, marginLeft: 5 }}>{t('ProjectOverview.yapiKullanimIzinError')}</span>
          </div>
          }
      </div>
    </div>
  )
}

export default ProjectOverview;