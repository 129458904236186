import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Footer.module.css';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Footer() {

    const { t, } = useTranslation();
    const { module } = useSelector(state => state.module);
    const { companyMail, companyPhone } = useSelector(state => state.companyInfo);

    // Responsive Design Values
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

    return (
        <>
            {!isTabletOrMobile &&
            <div className={classes.copyrightWrapper}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 10px' }}>
                        <span style={{ fontWeight: 'bold' }}>{t('UserMainPage.customerService')}</span>
                        <Link to="/mesafeli-satis-sozlesmesi">{t('UserMainPage.distanceSalesAgreement')}</Link>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 10px' }}>
                        <span style={{ fontWeight: 'bold' }}>{t('UserMainPage.kvkk')}</span>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Link to="/kvkk-aydinlatma-metni">{t('UserMainPage.disclosureText')}</Link>
                                <Link to="/kvkk-politikasi">{t('UserMainPage.kvkkPolicy')}</Link>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                                <Link to="/cerez-kullanim-izinleri-bildirimi">{t('UserMainPage.cookiesPolicy')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }} >
                    <span>{t('UserMainPage.reachUs')}</span>
                    <a href={`mailto:${companyMail}`}>{companyMail}</a>
                    <a href={`tel:${companyPhone}`}>{companyPhone}</a>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                    <img src="/images/visa-mastercard.png" alt="visa-mastercard-logos" style={{ width: '150px', margin: '0px 20px' }}/>
                    {module !== "solarvis" && <span>Powered by <a href="https://solarvis.co" style={{textDecoration: 'none'}}>solarVis</a></span>}
                </div>
            </div>}
            {isTabletOrMobile &&
            <>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10, fontSize: 12, justifyContent: 'space-around', marginTop: 10 }} >
                <span>{t('UserMainPage.reachUs')}</span>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <a href={`mailto:${companyMail}`}>{companyMail}</a>
                    <a href={`tel:${companyPhone}`} style={{ marginTop: 10 }}>{companyPhone}</a>
                </div>
            </div>
            <hr />
            <div className={classes.copyrightWrapper}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold', marginLeft: '5px 10px' }}>{t('UserMainPage.customerService')} & {t('UserMainPage.kvkk')}</span>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 10px' }}>
                            <Link to="/mesafeli-satis-sozlesmesi">{t('UserMainPage.distanceSalesAgreement')}</Link>
                            <Link to="/cerez-kullanim-izinleri-bildirimi">{t('UserMainPage.cookiesPolicy')}</Link>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 10px' }}>
                            <Link to="/kvkk-aydinlatma-metni">{t('UserMainPage.disclosureText')}</Link>
                            <Link to="/kvkk-politikasi">{t('UserMainPage.kvkkPolicy')}</Link>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <img src="/images/visa-mastercard.png" alt="visa-mastercard-logos" style={{ width: '100px', margin: '0px 10px', marginBottom: '10px' }}/>
                    {module !== "solarvis" && <span>Powered by <a href="https://solarvis.co" style={{textDecoration: 'none'}}>solarVis</a></span>}
                </div>
            </div>
            </>}
        </>
    );
}

export default Footer;
