const oneColllectorPackage = [
    {
        id: 1,
        productCode: '09010475',
        name: '120 LT/G AQUASOLAR ELEGANT SERP. GUNES KOLL. DEPOSU ',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 2,
        productCode: '09150268',
        name: 'SERİ SELLEKTİF KOLL. 2.0 M2 APOLLO E X',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 3,
        productCode: '09140126',
        name: '1K X 100-160LT GK PAKET SİSTEM ŞASESİ',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 4,
        productCode: '09140133',
        name: '1K X Elegant Paket Sistem Bağlantı Kiti',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 5,
        productCode: '57675',
        name: 'Fernox S1 Solar Sıvı',
        price: 100,
        quantity: 1,
        total: 100,
    },
];

const twoColllectorPackage = [
    {
        id: 1,
        productCode: '09010477',
        name: '200 LT/G AQUASOLAR ELEGANT SERP. GUNES KOLL. DEPOSU  ',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 2,
        productCode: '09150268',
        name: 'SERİ SELLEKTİF KOLL. 2.0 M2 APOLLO E X',
        price: 100,
        quantity: 2,
        total: 100,
    },
    {
        id: 3,
        productCode: '09140127',
        name: '2K X 200LT GK PAKET SİSTEM ŞASESİ',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 4,
        productCode: '09140135',
        name: '2K X Elegant Paket Sistem Bağlantı Kiti',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 5,
        productCode: '57675',
        name: 'Fernox S1 Solar Sıvı',
        price: 100,
        quantity: 2,
        total: 100,
    },
];

const threeColllectorPackage = [
    {
        id: 1,
        productCode: '09010478',
        name: '300 LT/G AQUASOLAR ELEGANT SERP. GUNES KOLL. DEPOSU  ',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 2,
        productCode: '09150268',
        name: 'SERİ SELLEKTİF KOLL. 2.0 M2 APOLLO E X',
        price: 100,
        quantity: 2,
        total: 100,
    },
    {
        id: 3,
        productCode: '09140128',
        name: '2K X 300LT GK PAKET SİSTEM ŞASESİ',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 4,
        productCode: '09140135',
        name: '2K X Elegant Paket Sistem Bağlantı Kiti',
        price: 100,
        quantity: 1,
        total: 100,
    },
    {
        id: 5,
        productCode: '57675',
        name: 'Fernox S1 Solar Sıvı',
        price: 100,
        quantity: 2,
        total: 100,
    },
];

const materialsTestData = {
    oneColllectorPackage,
    twoColllectorPackage,
    threeColllectorPackage,
};

export default materialsTestData;