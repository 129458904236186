import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: localStorage.getItem('userId') || '',
  loginToken: localStorage.getItem('loginToken') || '',
  password: localStorage.getItem('password') || '',
  loggedIn: localStorage.getItem('loggedIn') || false,
  cookieShow: localStorage.getItem('cookieShow') || false,
  apiRequestSource: 'demo.solarvis.co', // 'api.localhost',
  firstSession: 1,
  authDialogOpen: false,
  userStatus: Number(localStorage.getItem('userAPIStatus')) || 1,
  userSessionCounter: null
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeUserId: (state, action) => {
      state.userId = action.payload.userId;
      localStorage.setItem('userId', action.payload.userId);
    },
    changeCookieShow: (state, action) => {
      state.cookieShow = action.payload.cookieShow;
      localStorage.setItem('cookieShow', action.payload.cookieShow);
    },
    changePassword: (state, action) => {
      state.password = action.payload.password;
      localStorage.setItem('password', action.payload.password);
    },
    changeLoginToken: (state, action) => {
      state.loginToken = action.payload.loginToken;
      localStorage.setItem('loginToken', action.payload.loginToken);
    },
    changeRequestSource: (state, action) => {
      state.apiRequestSource = action.payload.apiRequestSource;
    },
    changeLoggedIn: (state, action) => {
      state.loggedIn = action.payload.loggedIn;
      localStorage.setItem('loggedIn', action.payload.loggedIn);
    },
    changeFirstSession: (state, action) => {
      state.firstSession = action.payload.firstSession;
    },
    changeAuthDialogState: (state, action) => {
      state.authDialogOpen = action.payload.authDialogOpen;
    },
    changeUserAPIStatus: (state, action) => {
      if (action.payload.userStatus > state.userStatus) {
        state.userStatus = action.payload.userStatus;
        localStorage.setItem('userAPIStatus', action.payload.userStatus);
      } else {
        state.userStatus = state.userStatus;
      }
    },
    changeUserSessionCounter: (state, action) => {
      state.userSessionCounter = action.payload.userSessionCounter;
    },
    resetUsers: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const {
  changeUserId,
  changeCookieShow,
  changeLoginToken,
  changeRequestSource,
  changePassword,
  changeLoggedIn,
  changeFirstSession,
  changeAuthDialogState,
  changeUserAPIStatus,
  changeUserSessionCounter,
  resetUsers
} = userSlice.actions

export default userSlice.reducer