/* eslint-disable prefer-template */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../../components/axios/axiosApi';
import { changeUserAPIStatus, changeUserId } from '../../../services/users/userReducer';
import {
  changeLocation,
  changeTempLocation,
  changeCityName,
  changeIlce,
  changeArea,
  changePolygonPoints,
  changeMarker,
  changePanelAmount,
  changeStaticMapURL,
  changeDetailedAdress
} from '../../../services/products/product/reducer';
import { Container, Grid, Box, Button, withStyles } from '@material-ui/core';
import classes from './UserMainPage.module.css';
import ProcessTracker from '../../../components/ProcessTracker';
import UserLoginPage from '../../UserLoginPage/UserLoginPage';
import {
  changeIsim,
  changeMail,
  changeSoyisim,
  changeTelefon,
  changeTesis,
  changeFatura,
  changeSozlesmeGucu,
  changeSarj,
  changeBatarya,
  changeCephe,
  changeEgim,
  changeVKN,
  changeTCKN,
} from '../../../services/products/product/summaryReducer';
import { useMediaQuery } from 'react-responsive';
import SpinnerDialog from '../../../components/UI/SpinnerDialog/Spinner'; 
import download from 'downloadjs';

import BeforePayment from './StatusComponents/BeforePayment';
import AfterPayment from './StatusComponents/AfterPayment';
import Refund from './StatusComponents/Refund';
import ExplorationRequest from './StatusComponents/ExplorationRequest';
import RejectedProject from './StatusComponents/RejectedProject';
import AwaitingApproval from './StatusComponents/AwaitingApproval';

const StyledButton = withStyles({
    root: {
      textTransform: 'none',
      backgroundColor: 'var(--secondary-color)',
      color: 'white'
    }
  })(Button);

const MainPage = () => {
  const { t, } = useTranslation();
  const dispatch = useDispatch();

  const { firmaKodu } = useSelector(state => state.companyInfo);
  const {
    module,
  } = useSelector(state => state.module);

  const { userId, userStatus, loggedIn } = useSelector((state) => state.users);
  const { mail } = useSelector((state) => state.summary);
  const { detailedAdress } = useSelector(state => state.location);

  const [loading, setLoading] = useState(false);

  const mapContactInfo = (value) => {
    switch (value) {
      case 'name':
        return 'isim';
      case 'surname':
        return 'soy_isim';
      case 'phoneNumber':
        return 'telefon';
      case 'companyName':
        return 'customer_firm';
      case 'referenceCode':
        return 'subcon_reference_code';
      default:
        return value;
    }
  };

  // Color states
  // const [ primaryColor, setPrimaryColor ] = useState(getComputedStyle(document.documentElement).getPropertyValue('--primary-color'));
  // const [ secondaryColor, setSecondaryColor ] = useState(getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'));

  // Responsive Design Values
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

  const [userInfo, setUserInfo] = useState({});
  const [expandUserInfo, setExpandUserInfo] = useState(false);
  const [imagePreview, setImagePreview] = useState();

  const [purhasedPackageInfo, setPurchasedPackageInfo] = useState({
    name: '',
    dcPower: '',
    acPower: '',
    priceTLKDV: '',
  });

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  async function getReport(id) {
    // Define report type
    let reportType = 'home_tr';
    let reportName = 'GES Ön Fizibilite Raporu.pdf';
    if (localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en' || localStorage.getItem('dil') === 'de' || String(i18n.language).split('-')[0] === 'de') {
      reportType = 'home_en';
      reportName = 'SPP Pre-Feasibility Report.pdf';
    }
    setLoading(true);
    await axiosInstance.get(`report/generate?musteri_id=${id}&format=json&is_admin_page=0&report_type=${reportType}`/* , {
      responseType: 'blob'
    } */)
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' }
        );
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        download(file, reportName, );
      })
      .catch((error) => console.log(error));
    setLoading(false);
  }

  const fetchUserFeasibilityInfo = async (url) => {
    let roofArea;
    let tempUserId;
    let staticMapURLTemp;
    let conctInfoTemp = {};


    await axiosInstance.get('/customer/fetch_profile_info/?format=json')
      .then(response => {
        const data = response.data.data;
        let contactInfoValuesTemp = {};

        conctInfoTemp.map(tempContact => {
          contactInfoValuesTemp[tempContact.title] = data[mapContactInfo(tempContact.title)];
        });

        // setContactInfoValues(contactInfoValuesTemp);

        if (data.hasOwnProperty('isim') && data.isim) dispatch(changeIsim({ isim: data.isim }));
        if (data.hasOwnProperty('soy_isim') && data.soy_isim) dispatch(changeSoyisim({ soyisim: data.soy_isim }));
        if (data.hasOwnProperty('telefon') && data.telefon) dispatch(changeTelefon({ telefon: data.telefon }));
        if (data.hasOwnProperty('tckn') && data.tckn) dispatch(changeTCKN({ tckn: data.tckn }));
        if (data.hasOwnProperty('vkn') && data.vkn) dispatch(changeVKN({ vkn: data.vkn }));
        if (data.hasOwnProperty('address') && data.address) dispatch(changeDetailedAdress({ detailedAdress: data.address }));
        if (data.hasOwnProperty('username') && data.username) dispatch(changeMail({ mail: data.username }));
      })
      .catch(error => {
        console.log(error);
      })

    await axiosInstance.get('/get_user_feasibility_info/?format=json')
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data;
          if ("order" in data) {
            const orderObj = data.order.packageDetails;
            setPurchasedPackageInfo({
              name: orderObj.name,
              priceTLKDV: orderObj.price
            });
          }
          setUserInfo(data);
          setImagePreview(data.gmaps_image_link);
          roofArea = data.alan;
          tempUserId = data.id;
          staticMapURLTemp = data.gmaps_link;
          dispatch(changeUserId({ userId: data.id}));
          dispatch(changeLocation({ latitude: data.enlem, longitude: data.boylam }));
          dispatch(changeTempLocation({ tempLatitude: data.enlem, tempLongitude: data.boylam }));
          dispatch(changeCityName({ cityName: data.il}));
          dispatch(changeIlce({ ilce: data.ilce}));
          dispatch(changePolygonPoints({polygonPoints: data.drawing_points}));
          dispatch(changeArea({ roofArea: data.alan }));
          dispatch(changeTesis({ tesis: data.tesis_tipi }));
          dispatch(changeFatura({ fatura: data.fatura_tutari }));
          dispatch(changeSozlesmeGucu({ sGucu: data.sgucu }));
          dispatch(changeEgim({ roofTilt: String(data.aci) }));
          dispatch(changeCephe({cephe: String(data.yon)}));
          dispatch(changeBatarya({batarya: data.is_battery}));
          dispatch(changeSarj({sarj: data.is_charger}));
          dispatch(changeUserAPIStatus({userStatus: data.status}));
          dispatch(changeMarker({markerOn: true}));
          dispatch(changeStaticMapURL({staticMapURL: data.gmaps_link}));
          if (!detailedAdress) {
            dispatch(changeDetailedAdress({detailedAdress: `${data.il}, ${data.ilce}`}))
          }
        }
      })
      .catch(error => {
        console.log(error);
    });

    await axiosInstance.get(`panel_sayisi?format=json&alan=${roofArea}`)
      .then(response => {
          const data = response.data.data;
          dispatch(changePanelAmount({panelAmount: data['panel_sayisi']}));
      })
      .catch(() => {
          dispatch(changePanelAmount({panelAmount:  Math.round(roofArea * 0.35)}));
      })

    if (userStatus < 4) {
      await axiosInstance.post('musteri/save?format=json', {
        musteri_id: tempUserId,
        status: 4,
        png: staticMapURLTemp,
        mail: mail,
        language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase(),
      })
        .then(response => {
          const data = response.data.data;
          dispatch(changeUserAPIStatus({userStatus: 4}));
          return data['musteri_id'];
        })
        .catch(error => console.log(error));
    }
  }

  useEffect(() => {
    fetchUserFeasibilityInfo("")
  }, [userStatus])

  if (!JSON.parse(localStorage.getItem('loggedIn'))) {
    let URLString = window.location.href;
    let url = new URL(URLString);
    if (!url.searchParams.get("token")){
        return <UserLoginPage />
    }
  }

  return (
    <>
        {/* Process tracker component. It is always visible. */}
        <Container 
            maxWidth={true}
            style={{ height: '100%', backgroundColor:'#F4F6F8' }}
        >
            <Grid 
                container
                spacing={3}
            >
              <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
              >
                <div className={classes.cardColumn}>
                  <div>
                    <h2 className={classes.allh2} style={{ textAlign: 'center' }}>{t('UserMainPage.progress')}</h2>
                  </div>
                  <div>
                    <ProcessTracker userStatus={userStatus} moduleType="home" />
                  </div>
                </div>
              </Grid>
            </Grid>
        </Container>
        {
          (userStatus < 4) // User can be make exploration request.
          &&
          <ExplorationRequest
            userInfo={userInfo}
            imagePreview={imagePreview}
            expandUserInfo={expandUserInfo}
            setExpandUserInfo={setExpandUserInfo}
          />
        }
        {
          (userStatus === 4) // Awaiting approval
          &&
          <AwaitingApproval
            userInfo={userInfo}
            imagePreview={imagePreview}
            expandUserInfo={expandUserInfo}
            setExpandUserInfo={setExpandUserInfo}
          />
        }
        {
          (userStatus === 6) // Project is accepted by company. User can be pay.
          &&
          <BeforePayment
            userInfo={userInfo}
            imagePreview={imagePreview}
            expandUserInfo={expandUserInfo}
            setExpandUserInfo={setExpandUserInfo}
          />
        }
        {
          (userStatus === -1) // Project is rejected by company.
          &&
          <RejectedProject
            userInfo={userInfo}
            imagePreview={imagePreview}
            expandUserInfo={expandUserInfo}
            setExpandUserInfo={setExpandUserInfo}
          />
        }
        {
          (userStatus === 7) // Payment is done. User can be see the package info.
          &&
          <AfterPayment
            userInfo={userInfo}
            imagePreview={imagePreview}
            expandUserInfo={expandUserInfo}
            setExpandUserInfo={setExpandUserInfo}
            purhasedPackageInfo={purhasedPackageInfo}
          />
        }
        {
          (userStatus === 8) // Payment is refunded.
          &&
          <Refund
            userInfo={userInfo}
            imagePreview={imagePreview}
            expandUserInfo={expandUserInfo}
            setExpandUserInfo={setExpandUserInfo}
          />
        }
        {([3,4,5,6,7].includes(userStatus)) // Report can be seen at these status.
        &&
        <Container 
            maxWidth={true}
            style={{ height: '100%', backgroundColor:'#F4F6F8', paddingBottom: '20px' }}
        >
            <Grid 
                container
                spacing={3}
            >
              {/* Report component */}
              {([6,7].includes(userStatus)) &&
              <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
              >
                  {!loading && <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px' }} >
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly'
                      }}>
                          <h2 className={classes.allh2}>{t('UserMainPage.reportReady')}</h2>
                          <p>{t('UserMainPage.reportText')}</p>
                          <Box my={2}>
                              <div style={{ textDecoration: 'none' }} onClick={() => getReport(userId)}>
                                  <StyledButton
                                      size="large"
                                      type="submit"
                                      variant="contained"
                                  >
                                      {t('UserMainPage.reportButton')}
                                  </StyledButton>
                              </div>
                          </Box>
                      </div>
                      {isDesktopOrLaptop &&
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}>
                          <img src="./images/report.png" alt="GES Fizibilite Raporu" className={classes.icon}/>
                      </div>}
                  </div>}
                  {loading && <SpinnerDialog text={(localStorage.getItem('dil') === 'tr' || String(i18n.language).split('-')[0] === 'tr') ? "Raporunuz oluşturuluyor.." : "Your report is being created.."}/>}
              </Grid>}
              {/* Document Upload component */}
              {([4,5].includes(userStatus)) &&
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={12}
              >
                  <div className={classes.card} style={{ marginBottom: isDesktopOrLaptop ? '40px': '0px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                          <h2 className={classes.allh2}>{t('UserMainPage.uploadNowDiscount')}</h2>
                          <p>{t('UserMainPage.uploadNowTextDiscount')}</p>
                          <Box my={2}>
                              <Link style={{ textDecoration: 'none' }} to="photos">
                                  <StyledButton
                                      size="large"
                                      type="submit"
                                      variant="contained"
                                  >
                                      {t('UserMainPage.uploadButton')}
                                  </StyledButton>
                              </Link>
                          </Box>
                      </div>
                      {isDesktopOrLaptop &&
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src="./images/upload.png" alt="GES Fizibilite Dosyaları" className={classes.icon}/>
                      </div>}
                  </div>
              </Grid>}
            </Grid>
        </Container>}
    </>
  );
};

export default MainPage;
