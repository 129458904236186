import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import classes from './Navbar.module.css';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import {
    withStyles,
    Select,
    MenuItem,
    InputBase,
  } from '@material-ui/core';
import { Link } from 'react-router-dom';

const StyledSelect = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
})(Select);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
        marginTop: theme.spacing(3),
        },
        marginRight: 10,
        backgroundColor: 'whitesmoke',
        borderRadius: 4,
        height: 40,
        alignSelf: 'center'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'whitesmoke',
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        'var(--font-fam)',
        'sans-serif',
        ].join(','),
        '&:focus': {
        borderRadius: 4,
        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const BootstrapInputMobile = withStyles((theme) => ({
    root: {
        'label + &': {
        marginTop: theme.spacing(3),
        },
        marginRight: 0,
        backgroundColor: 'whitesmoke',
        borderRadius: 4,
        height: 40,
        alignSelf: 'center'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'whitesmoke',
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        'var(--font-fam)',
        'sans-serif',
        ].join(','),
        '&:focus': {
        borderRadius: 4,
        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


function Navbar(props) {

    const { t, } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

    const [language, setLanguage] = React.useState(localStorage.getItem('dil') || String(i18n.language).split('-')[0]);

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
        localStorage.setItem('dil', event.target.value);
        i18n.changeLanguage(event.target.value);
      };

    const dispatch = useDispatch();
    const { lastInfo } = useSelector(state => state.steps);
    const { firmaKodu } = useSelector(state => state.companyInfo);
    const { module } = useSelector(state => state.module);

    return (
        <>
            {isTabletOrMobile && !lastInfo && <header>
                <div className={classes.headerWrapper}>
                    <div className={classes.headroom}>
                        <section className={classes.section}>
                            <div className={classes.innerWrapper}>
                                <div className={classes.navigationSection}>
                                    <div className={classes.seperateItems}>
                                        <div className={classes.hrefTag}>
                                            <Link to="/" style={{ alignItems: 'center', display: 'flex' }}>
                                                <img 
                                                    src="./Baymak_logo.png"
                                                    alt={module} 
                                                    className={classes.logo}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </header>}
            {isDesktopOrLaptop && <header className={classes.header}>
                <div className={classes.headerWrapper}>
                    <div className={classes.headroom}>
                        <section className={classes.section}>
                            <div className={classes.innerWrapper}>
                                <div className={classes.navigationSection}>
                                    <header className= {classes.NavigationHeader}>
                                        <div className={classes.hrefTag}>
                                            <Link to="/" style={{ alignItems: 'center', display: 'flex' }}>
                                                <img 
                                                    src="./Baymak_logo.png"
                                                    alt={module} 
                                                    className={classes.logo}
                                                />
                                            </Link>
                                        </div>
                                    </header>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </header>}
        </>
    );
}

export default Navbar;
