/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import axiosInstance from '../../../components/axios/axiosApi';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import classes from '../UserDocumentUploadPage.module.css';
import FileUploadComponent from './FileUploadComponent';
import { useMediaQuery } from 'react-responsive';

const FileUploadPage = (props) => {
  const { t, } = useTranslation();
  const [ files, setFiles ] = useState([]);
  const { stepNumber, documentCategory } = props;

  const [ fileNames, setFileNames ] = useState([]);
  const [ loaded, setLoaded ] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});

    const fetchDocumentList = () => {
        axiosInstance.get(`subcon/document/project_APIs/end_user_get_all_related_documents`)
            .then((response) => {
                const { status } = response.data;
                if (status) {
                const { data, message } = response.data;
                setFileNames(data);
                }
            })
            .catch((error) => console.log(error));
    };

    const deleteDocument = async (fileId) => {
        await axiosInstance.get(`subcon/document/project_APIs/delete_document_end_user?subcon_document_id=${fileId}`)
            .then((response) => {
                const { status } = response.data;
                if (status) {
                const { data, message } = response.data;
                setFileNames(data);
                }
            })
            .catch((error) => console.log(error));
    };

    React.useEffect(() => {
        fetchDocumentList();
    }, []);

    return (
      <>
            <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
            >
                <div className={classes.card}>
                    <div>
                        <h2 style={{fontWeight:'normal', fontSize: '1.5rem'}}>{documentCategory.document_category_name} {t('ElectricityBillUpload.upload')}</h2>
                        <p style={{ margin: '10px 0' }}>{documentCategory.document_category_description}</p>
                        <div style={{ display: 'flex', flexDirection: isDesktopOrLaptop ? 'row' : 'column' }}>
                            <FileUploadComponent
                                acceptedTypes=""
                                documentCategory={documentCategory}
                                maxFiles={1}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '400px', paddingLeft: '20px' }}>
                                <span style={{ textAlign: 'center', marginTop: '20px' }}>{t('ElectricityBillUpload.uploadedBefore')}</span>
                                {fileNames.map((file, index) => (
                                    <div key={index} style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        margin: '10px',
                                        justifyContent: 'space-between',
                                        border: '1px solid #cecece',
                                        borderRadius: '10px',
                                        padding: '10px',
                                    }}>
                                        <span>{file.document_category_name}</span>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <a href={file.document_download_url}>
                                                <IconButton aria-label="download">
                                                    <GetAppIcon />
                                                </IconButton>
                                            </a>
                                            {/* <IconButton
                                                aria-label="download"
                                                onClick={() => deleteDocument(file.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
    </>
  );
};

export default FileUploadPage;
