/* eslint-disable prefer-template */
import React, { useState } from 'react';
import classes from '../UserMainPage.module.css';
import {
    Grid,
    Container,
    TextField,
    Box,
    Button,
    withStyles
} from '@material-ui/core';
import ReactGA from 'react-ga';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../../../components/axios/axiosApi';
import _ from 'lodash';
import i18n from '../../../../i18n';
import {
    changeIsim,
    changeSoyisim,
    changeTelefon,
  } from '../../../../services/products/product/summaryReducer';
  import { changeUserAPIStatus } from '../../../../services/users/userReducer';
  import UserInfoAccordion from '../../../../components/SolarPackage/UserInfoAccordion';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['0', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
  }

const StyledButton = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: 'var(--secondary-color)',
        color: 'white'
    }
})(Button);

const ExplorationRequst = (props) => {

    const { t, } = useTranslation();
    const dispatch = useDispatch();

    const {
        userInfo,
        imagePreview,
        expandUserInfo,
        setExpandUserInfo,
    } = props;

    const { userId } = useSelector((state) => state.users);
    const { mail } = useSelector((state) => state.summary);
    const { staticMapURL } = useSelector(state => state.location);
    const { module } = useSelector(state => state.module);

    const contactInfo = [
        {
            title: 'name',
            type: 'text',
            autocomplete: 'given-name',
            name_tr: 'İsim',
            name_en: 'Name'
        },
        {
            title: 'surname',
            type: 'text',
            autocomplete: 'family-name',
            name_tr: 'Soy İsim',
            name_en: 'Surname'
        },
        {
            title: 'phoneNumber',
            type: 'text',
            autocomplete: 'tel-national',
            name_tr: 'Telefon',
            name_en: 'Phone'
        },
        {
            title: 'referenceCode',
            type: 'number',
            autocomplete: '',
            name_tr: 'Referans Kodu',
            name_en: 'Reference Code'
        }
    ];

    const [contactInfoValues, setContactInfoValues] = useState({
        name: '',
        surname: '',
        phoneNumber: '',
        referenceCode: ''
    });

    const inputChangeHandler = (event) => {
        setContactInfoValues({
            ...contactInfoValues,
            [event.target.name]: event.target.value
        });
    }

    const mapContactInfo = (value) => {
        switch (value) {
            case 'name':
                return 'isim';
            case 'surname':
                return 'soy_isim';
            case 'phoneNumber':
                return 'telefon';
            case 'companyName':
                return 'customer_firm';
            case 'referenceCode':
                return 'subcon_reference_code';
            default:
                return value;
        }
    };

    const explorationRequest = async (event) => {
        event.preventDefault();
    
        const contactInfoValuesKeys = Object.keys(contactInfoValues);
        const sendData = {};

        if (module === "arcelikkurumsalcozumler") {
            sendData['subcon_reference_code'] = contactInfoValues['referenceCode'];
        } else {
            contactInfoValuesKeys.map(key => {
                sendData[mapContactInfo(key)] = contactInfoValues[key];
              });
          
              await axiosInstance.post('/customer/save_profile_info/?format=json', sendData)
                .then((response) => {
                  if (response.data.status) {
                    if (contactInfoValues.hasOwnProperty("name") && contactInfoValues.name) dispatch(changeIsim({ isim: contactInfoValues.name }));
                    if (contactInfoValues.hasOwnProperty("surname") && contactInfoValues.surname) dispatch(changeSoyisim({ soyisim: contactInfoValues.surname }));
                    if (contactInfoValues.hasOwnProperty("phoneNumber") && contactInfoValues.phoneNumber) dispatch(changeTelefon({ telefon: contactInfoValues.phoneNumber }));
                  }
                })
                .catch(error => {
                  console.log(error);
                })
        }
    
        await axiosInstance.post('musteri/save?format=json', {
          musteri_id: userId,
          status: 4,
          png: staticMapURL,
          language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase(),
          mail: mail,
          ...sendData,
        })
          .then((response) => {
            if (response.data.status) {
                dispatch(changeUserAPIStatus({userStatus: 4}));
                // Send pageview with a custom path
                ReactGA.send({ hitType: "pageview", page: "/kesif_talebi" });
            }
          })
          .catch((error) => console.log(error));
      }

    return (
        <Container 
            maxWidth={true}
            style={{ height: '100%', backgroundColor:'#F4F6F8', paddingBottom: 20 }}
        >
            <Grid 
                container
                spacing={3}
            >
                <Grid
                    item
                    lg={6}
                    md={12}
                    xl={6}
                    xs={12}
                >
                    <UserInfoAccordion
                        userInfo={userInfo}
                        imagePreview={imagePreview}
                        expandUserInfo={expandUserInfo}
                        setExpandUserInfo={setExpandUserInfo}
                        isAccordion={false}
                    />
                </Grid>
                {module !== "arcelikkurumsalcozumler" &&
                <Grid
                    item
                    lg={6}
                    md={12}
                    xl={6}
                    xs={12}
                >
                    <div style={{
                        backgroundColor: '#ffffff',
                        padding: '40px',
                        borderRadius: '10px'
                        }}
                    >
                        <h2 className={classes.allh2}>{t('UserMainPage.kesifHizmeti')}</h2>
                        <p>{t('UserMainPage.kesifMetin')}</p>
                        <form autoComplete='on' style={{ padding: '20px 0px' }}>
                            <Container 
                                maxWidth={true}
                                style={{ height: '100%', backgroundColor:'#ffffff', padding: 0 }}
                            >
                                <Grid 
                                    container
                                    spacing={3}
                                >
                                    {!(_.isEmpty(contactInfo)) && contactInfo.map((contactItem, index) => (
                                    <Grid
                                        item
                                        lg={6}
                                        md={12}
                                        xl={6}
                                        xs={12}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>{(localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en') ? contactItem.name_en : contactItem.name_tr}</span>
                                        <TextField 
                                            fullWidth
                                            name={contactItem.title}
                                            type={contactItem.type}
                                            id={contactItem.autocomplete}
                                            autoComplete={contactItem.autocomplete}
                                            onChange={(event) => inputChangeHandler(event)}
                                            value={contactInfoValues[contactItem.title]}
                                            /* helperText={!validity[contactItem.title] ? contactItem.placeholder_tr : ""}
                                            error={!validity[contactItem.title]} */
                                            variant="outlined"
                                            size="small"
                                            InputProps={contactItem.title === "phoneNumber" ? {inputComponent: TextMaskCustom} : {}}
                                            inputComponent={contactItem.title === "phoneNumber" ? TextMaskCustom : {}}
                                        />
                                        {contactItem.title === "referenceCode" &&
                                        <p style={{ fontSize: '0.8rem' }}>{t('UserMainPage.optionalInput')}</p>
                                        }
                                        </div>
                                    </Grid>
                                    ))}
                                </Grid>
                            </Container>
                        </form>
                        <Box my={2}>
                            <div style={{ textDecoration: 'none' }}>
                                <StyledButton
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={(e) => explorationRequest(e)}
                                    disabled={!Object.keys(contactInfoValues).filter(key => key !== 'referenceCode').map(key => contactInfoValues[key]).every(value => value)}
                                >
                                    {t('UserMainPage.kesifButon')}
                                </StyledButton>
                            </div>
                        </Box>
                    </div>
                </Grid>}
                {module === "arcelikkurumsalcozumler" &&
                <Grid
                    item
                    lg={6}
                    md={12}
                    xl={6}
                    xs={12}
                >
                    <div style={{
                        backgroundColor: '#ffffff',
                        padding: '40px',
                        borderRadius: '10px'
                        }}
                    >
                        <h2 className={classes.allh2}>{t('UserMainPage.kesifHizmeti')}</h2>
                        <p style={{ margin: '15px 0px' }}>{t('UserMainPage.kesifMetin')}</p>
                        <p style={{ fontSize: '0.9rem' }}>Bu işlemi bir Arçelik bayiisinde yapıyorsanız aşağıdaki kutucuğa bayii kodunu girebilirsiniz.</p>
                        <form autoComplete='on' style={{ padding: '20px 0px' }}>
                            <Container 
                                maxWidth={true}
                                style={{ height: '100%', backgroundColor:'#ffffff' }}
                            >
                                <Grid 
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        lg={6}
                                        md={12}
                                        xl={6}
                                        xs={12}
                                    >
                                        <TextField 
                                            fullWidth
                                            name="referenceCode"
                                            type="number"
                                            id="referenceCode"
                                            label={(localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en') ? "Reference Code" : "Referans Kodu"}
                                            onChange={(event) => inputChangeHandler(event)}
                                            value={contactInfoValues.referenceCode}
                                            /* helperText={!validity[contactItem.title] ? contactItem.placeholder_tr : ""}
                                            error={!validity[contactItem.title]} */
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                        </form>
                        <Box my={2}>
                            <div style={{ textDecoration: 'none' }}>
                                <StyledButton
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={(e) => explorationRequest(e)}
                                >
                                    {t('UserMainPage.kesifButon')}
                                </StyledButton>
                            </div>
                        </Box>
                    </div>
                </Grid>}
            </Grid>
        </Container>
    );
};

export default ExplorationRequst;