import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Navbar from '../../../../containers/AlternativeNavbar/Navbar';
import classes from './KVKKPagesStyle.module.css';
import { Helmet } from "react-helmet";
import Footer from '../../../../containers/AlternativeFooter/Footer';
import testCompanyInfo from './testData';


export default function PageWrapper(props) {
  const { t, } = useTranslation();

  const {
    companyName,
    companyPhone,
    companyMail,
    companyAddress,
    vergiDairesi,
    vergiNo,
    mersisNo,
    companyContactPerson
  } = useSelector(state => state.companyInfo);

  const {
    isim,
    soyisim,
    telefon,
    mail
  } = useSelector(state => state.summary);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
      <title>Mesafeli Satış Sözleşmesi</title>
      </Helmet>
      <main className={classes.mainWrapper}>
        <Navbar/>
        <div className={classes.outerWrapper}>
          <h2>Mesafeli Satış Sözleşmesi</h2> 
          <p className={classes.justBoldParagraph}>1.SATICI Bilgileri:</p> 
          <p className={classes.standartParagraph}>Ünvanı : (Bundan sonra SATICI olarak adlandırılacaktır) {companyName}</p> 
          <p className={classes.standartParagraph}>Adresi: {companyAddress}</p> 
          <p className={classes.standartParagraph}>Telefon: {companyPhone}</p> 
          <p className={classes.standartParagraph}>Mail Adresi: {companyMail}</p> 
          <p className={classes.standartParagraph}>Web Sitesi: www.solarvis.co</p> 
          <p className={classes.justBoldParagraph}>2.ALICI Bilgileri:</p> 
          <p className={classes.standartParagraph}>Adı / Soyadı / Ünvanı: (Bundan sonra ALICI olarak adlandırılacaktır) {isim} {soyisim}</p> 
          <p className={classes.standartParagraph}>Adresi: (ALICI’NIN ADRESİ)'</p> 
          <p className={classes.standartParagraph}>Telefon: {telefon ? telefon : '(ALICI’NIN TELEFONU)'} </p> 
          <p className={classes.standartParagraph}>Mail Adresi: {mail ? mail : '(ALICI’NIN e-posta ADRESİ)'} </p> 
          <p className={classes.justBoldParagraph}>3.Sözleşmenin Konusu:</p> 
          <p className={classes.standartParagraph}>Bu sözleşme SATICI ile Müşteri (ALICI) arasında, internet üzerinden yapılan alışverişleri kapsar ve her iki taraf bu sözleşme şartlarını kabul etmiş sayılır. ALICI, satışa konu mal veya hizmetin temel nitelikleri, satış fiyatı, ödeme şekli, teslimat koşulları vs satışa konu mal veya hizmetin ile ilgili tüm ön bilgiler ve cayma hakkı konusunda bilgi sahibi olduğunu, bu ön bilgileri elektronik ortamda teyit ettiğini ve sonrasında malı sipariş verdiğini, işbu sözleşme hükümlerince kabul ve beyan eder.</p> 
          <p className={classes.justBoldParagraph}>4.Siparişin alınması:</p> 
          <p className={classes.standartParagraph}>Müşteri tüm siparişlerini internet üzerinde yer alan ve yukarıda adresi belirtilen web sitesi üzerinden verecektir. Herhangi bir sebeple SATICI ile telefon ya da başka bir iletişim aracı ile bağlantıya geçilse bile siparişin internet üzerinden tamamlanması esastır. Sipariş esnasında girilen bilgiler SATICI tarafından kayıt altında tutulacak ve yapılan satışın faaliyetlerini olacaktır.</p> 
          <p className={classes.justBoldParagraph}>5.Sözleşme Tarihi:</p> 
          <p className={classes.standartParagraph}>ALICI’nın Ödeme Formunda ödeme bilgilerini girmesinin ardından satışı onayladığı tarihtir. Satışa ilişkin hizmet teslimi ve iptal/iade işlemlerinde başlangıç tarihi olarak esas alınır. ALICI ödemenin başarıyla tamamlanmasının ardından sözleşme tarihini görüntüleyebilmektedir. ALICI bu tarihten itibaren sözleşmeye dair hükümlere tabii sayılır.</p> 
          <p className={classes.justBoldParagraph}>6.Malın Teslimi, Sözleşmenin ifa Yeri ve Teslim Şekli:</p> 
          <p className={classes.standartParagraph}>ALICInın ön ödemesini tamamlamasının ardından SATICI tarafından 14 gün içerisinde güneş enerjisi danışmanlık hizmeti sunulur. Güneş enerji santrali anahtar teslim kurulum hizmeti, ALICI tarafından tam ödemeden geriye kalan ödemenin SATICIya yapılmasını takiben ALICIya sunulmaya başlanacaktır. ALICInın bu süre zarfında iletişim adreslerini güncellemesi durumunda SATICI ile iletişime geçmesi gerekmektedir. ALICInın iletişim kanallarını kontrol etmesi kendi sorumluluğundadır. ALICIya gerekli değerlendirmelerin ALICInın belirttiği iletişim kanallarından sunulması halinde ön ödemeye tabi olan hizmetin gerçekleştirilmiş olduğu kabul edilir.</p> 
          <p className={classes.justBoldParagraph}>7.Teslimat Masrafları ve İfası:</p> 
          <p className={classes.standartParagraph}>SATICI, ön ödemenin yapılmasının ardından 14 gün süre içerisinde güneş enerjisi danışmanlık hizmeti sunulması ile yükümlüdür. SATICI, ALICI tarafından tam ödemenin yapılmasının ardından 6 ay süre içerisinde ALICIya güneş enerji santrali anahtar teslim tedarik, resmi izinler ve kurulum hizmetini ulaştırmak ile yükümlüdür. ALICInın iletişim adreslerine ilgili hizmetlerin ulaştırılması için ayrıca bir bedel talep edilmemektedir. Söz konusu hizmetlerin ALICIya ulaştırılması için ALICInın bu süre içerisinde herhangi bir ödeme iptal talebi gerçekleştirmemiş olması gerekmektedir.</p> 
          <p className={classes.justBoldParagraph}>8.Ödeme İşlemleri:</p> 
          <p className={classes.standartParagraph}>Müşteri internet üzerinden siparişini tamamladığı zaman ödeme formu sayfasına yönlendirilecektir. Burada kendisine sunulan mal veya hizmetin tüm vergiler dahil ön ödeme ve taksit seçeneklerinden uygun olanını seçecek ve ön ödeme işlemini dilerse tamamlayacaktır. Ön ödemeyi yaptıktan sonra 2 hafta içerisinde tam ödemesini göndermemiş olan Siparişler iptal edilir ve SATICI hizmetin teslim yükümlülüğünden kurtulmuş kabul edilir. SATICI ilerleyen zaman içerisinde ödeme seçeneklerini değiştirmeye, arttırmaya, azaltmaya ya da kaldırmaya yetkilidir. SATICI, ALICInın ödeme amacı ile verdiği bilgileri korumakla yükümlüdür.</p> 
          <p className={classes.justBoldParagraph}>9.SATICI’nın Beyan ve Taahhütleri:</p> 
          <p className={classes.standartParagraph}>SATICI, sözleşme konusu hizmetin ve belgelerin sağlam, eksiksiz ve siparişte belirtilen niteliklere biçimde teslim edilmesinden sorumludur. Sözleşme konusu hizmet, ALICI’DAN başka bir kişi veya kuruluşa teslim edilecek ise, teslim edilecek kişi veya kuruluşun teslimatı kabul etmemesinden SATICI sorumlu tutulamaz. Haklı gerekçelerle SATICI, sözleşmedeki ifa süresi dolmadan ALICIya eşit kalite ve fiyatta malı tedarik edebilir. SATICI, sipariş konusu mal veya hizmetin yerine getirilmesinin imkânsızlaştığını ileri sürerek, sözleşme konusu yükümlülüklerini yerine getiremiyorsa, bu durumu, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan tüketiciye bildirir. Ödemiş olduğu bedel ve borç altına sokan tüm belgeleri 7 gün içinde tüketiciye iade eder. SATICI bir neden göstermeksizin, ALICI’NIN ödemiş olduğu sipariş tutarını aynı ödeme yolu ile iade ederek, siparişi iptal etme hakkında sahiptir.</p>
          <p className={classes.justBoldParagraph}>10.Sözleşmeye Konu Mal / Hizmetin Özellikleri:</p> 
          <p className={classes.standartParagraph}>Güneş Enerjisi Paketi Ön Ödemesi</p> 
          <p className={classes.standartParagraph}>Ön Ödeme Tutarı: Ödeme ekranında ALICIya sunulan paketlerde gösterilen satış tutarıdır</p>
          <p className={classes.standartParagraph}>Ödeme Şekli: Online POS</p> 
          <p className={classes.standartParagraph}>Toplam Paket Ücreti: Ödeme ekranında ALICIya sunulan paketlerde gösterilen satış tutarıdır.</p> 
          <p className={classes.justBoldParagraph}>11.Ödeme Planı:</p>
          <p className={classes.standartParagraph}>ALICI’nın ödeme formu aracılığıyla yapmış olduğu ödeme ve taksitlendirme seçenekleri geçerlidir. ALICI’NIN kredi kartı ile ve taksitle alışveriş yapması durumunda siteden seçmiş olduğu taksit biçimi geçerlidir. Taksitlendirme işlemlerinde, ALICI ile kart sahibi banka arasında imzalamış bulunan sözleşmenin ilgili hükümleri geçerlidir. Kredi kartı ödeme tarihi banka ile ALICI arasındaki sözleşme hükümlerince belirlenir. ALICI, ayrıca bankanın gönderdiği hesap özetinden taksit sayısını ve ödemelerini takip edebilir.Siparişini verdikten sonra 24 saat içerisinde ödemesini göndermemiş olan Siparişler iptal edilir ve SATICI malın teslim yükümlülüğünden kurtulmuş kabul edilir. Banka Havalesi veya EFT yolu ile ödeme gönderen müşteriler mutlaka "Havale Bildirim Formu" nu doldurmak ve SATICI'YA iletmek zorundadırlar. SATICI ilerleyen zaman içerisinde ödeme seçeneklerini değiştirmeye, arttırmaya, azaltmaya ya da kaldırmaya yetkilidir. SATICI, ALICInın ödeme amacı ile verdiği bilgileri korumakla yükümlüdür. </p> 
          <p className={classes.justBoldParagraph}>12.Cayma Hakkı:</p> 
          <p className={classes.standartParagraph}>ALICI, sözleşme konusu hizmetin sunulmasını takip eden 14 (on dört) gün içerisinde hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkına sahiptir. Ancak bu durumda iade edilecek ürünün hiçbir şekilde satışını engelleyecek durumların söz konusu olmaması gerekmektedir (ambalajı açılmamış, tahrip edilmemiş, kullanılmamış kutu, aksesuar vb belge etiketi kaybedilmiş ve deforme edilmemiş vs.) cayma hakkı nedeni ile iade edilen malın teslimat bedeli SATICI tarafından karşılanır. SATICI, cayma beyanının kendisine ulaşmasından sonra 10 (on) gün içinde varsa kıymetli evrakı iade eder, mal bedelini 20 (yirmi) gün içinde iade alır.</p>
          <p className={classes.justBoldParagraph}>13.Cayma Hakkı Kullanılamayacak Mal / Hizmetler:</p>
          <p className={classes.standartParagraph}>ALICI’nın özel istek ve talepleri uyarınca üretilen veya üzerinde değişiklik ya da ilaveler yapılarak kişiye özel hale getirilen ve niteliği itibariyle iade edilemeyecek malları kapsamaktadır. Ayrıca Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallar,ile ses veya görüntü kayıtlarının, kitap, dijital içerik, yazılım programlarının, veri kaydedebilme ve veri depolama cihazlarının, bilgisayar sarf malzemelerinin, ambalajının ALICI tarafından açılmış olması halinde iadesi Yönetmelik gereği mümkün değildir. Ayrıca, niteliği itibarıyla iade edilemeyecek ürünler, tek kullanımlık ürünler, kopyalanabilir yazılım ve programlar, hızlı bozulan veya son kullanım tarihi geçen ürünler için cayma hakkı kullanılamaz. Her türlü yazılım ve programlar, Blu-ray, DVD, VCD, CD ve kasetler, bilgisayar ve kırtasiye sarf malzemeleri (toner, kartuş, şerit vb) ve her türlü kozmetik ürünlerinde cayma hakkının kullanılması, ürünün ambalajının açılmamış, bozulmamış ve ürünün kullanılmamış olması şartına bağlıdır. Bu takdirde nakliye giderleri ALICIYA aittir. Ayrıca Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin cayma hakkının kullanılması da Yönetmelik gereği mümkün değildir.</p> 
          <p className={classes.justBoldParagraph}>14.Temerrüt Hali ve Hukuki Sonuçları:</p> 
          <p className={classes.standartParagraph}>ALICI, kredi kartı ile yapmış olduğu işlemlerinde temerrüde düşmesi halinde kart sahibi bankanın kendisi ile yapmış olduğu kredi kartı sözleşmesi çerçevesinde faiz ödeyecek ve bankaya karşı sorumlu olacaktır.</p>
          <p className={classes.justBoldParagraph}>15.Yetkili Mahkeme:</p> 
          <p className={classes.standartParagraph}>İşbu sözleşmeden kaynaklanabilecek ihtilaflarda, Sanayi ve Ticaret Bakanlığınca ilan edilen değere kadar Tüketici Hakem Heyetleri ile ALICI ve SATICI’NIN Yerleşim yerlerindeki Tüketici Mahkemeleri, Tüketici Mahkemesi bulunamayan yerlerde Asliye Hukuk Mahkemeleri yetkilidir.</p> 
          <p className={classes.justBoldParagraph}>16.Sözleşmenin Uygulanması:</p>
          <p className={classes.standartParagraph}>Sözleşme, bir tarafın sözleşme hükümlerine uymaması, diğer tarafın sorumluluğunu ortadan kaldırmamaktadır her iki tarafı da bağlayan hükümler içermekte olup hükümleri.</p> 
          <p className={classes.justBoldParagraph}>17.Sözleşmenin Başlaması:</p> 
          <p className={classes.standartParagraph}>Bu sözleşme, Müşterinin SATICI internet sitesi üzerinden siparişini tamamlaması ile başlar. Müşteri tarafından ödeme işleminin henüz tamamlanmaması, sözleşme hükümlerini ortadan kaldırmaz.</p>
          <p className={classes.justBoldParagraph}>18. İletişim Onayı</p> 
          <p className={classes.standartParagraph}>Bu sözleşme ile ALICI sipariş sözleşmesini onayladıktan sonra SATICI’NIN bu tarihten sonra yapacağı tüm kampanyalar, indirim fırsatları, ürün ve müşteri memnuniyetinin tespiti amacıyla gönderilecek anketler ve bilgilendirmelerden elektronik bülten, elektronik posta, telefon ve sms aracılığıyla haberdar edilmeyi kabul etmiş sayılır.</p> 
          <p className={classes.justBoldParagraph}>19.Sözleşmenin Hükmü:  </p>
          <p className={classes.standartParagraph}>ALICI, sipariş sözleşmesi ile birlikte, sözleşme koşullarını okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini kabul, beyan ve taahhüt eder.</p>
        </div>
        <Footer />
      </main>
  </>
  )
}