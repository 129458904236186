import React from 'react';

import classes from './SunSpinner.module.css';

const spinner = (props) => (
    <>
        {/* <div className={classes.bar}></div> */}
        <div className={classes.circle}>
            <div className={classes.circle}></div>
            <div className={classes.innerCircle}></div>
        </div>
        <div className={classes.frame}>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>    
            <div className={classes.bar}></div>    
            <div className={classes.bar}></div>    
        </div>
    </>
);

export default spinner;
