/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 40000,
  headers: {
    /* Authorization: localStorage.getItem('access') ? `Bearer ${localStorage.getItem('access')}` : null, */
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access');

    axiosInstance.defaults.headers.common['Authorization'] = accessToken ? `Bearer ${accessToken}` : '';
    config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : '';

    if (config.url.includes('get_cust_file') || config.url.includes('generate')) {
      config.responseType = 'blob';
    }

    return config;
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (originalRequest.url !== '/token/refresh/') {
      localStorage.setItem('tempRequest', originalRequest.url);
    }

    // Prevent infinite loops
    if (error.response.status === 401 && originalRequest.url === `/token/refresh/`) {
      let usernameX, passwordX;
      if (Boolean(localStorage.getItem('password')) && Boolean(localStorage.getItem('mail'))/*  && window.location.pathname !== '/' */) {
        usernameX = localStorage.getItem('mail');
        passwordX = localStorage.getItem('password');
      } else {
        if (localStorage.getItem('modül') === 'solarvis') {
          if (window.location.host === "calculator.solarvis.co") {
            usernameX = process.env.REACT_APP_SOLARVISGLOBAL_UI;
            passwordX = process.env.REACT_APP_SOALRVISGLOBAL_PW;
          } else {
            usernameX = process.env.REACT_APP_SOLARVISDEFAULT_UI;
            passwordX = process.env.REACT_APP_SOLARVISDEFAULT_PW;
          }
        } else if (localStorage.getItem('modül') === "istanbulges") {
          usernameX = process.env.REACT_APP_ISTANBULGES_UI;
          passwordX = process.env.REACT_APP_ISTANBULGES_PW;
        } else if (localStorage.getItem('modül') === "elfsolar") {
          usernameX = process.env.REACT_APP_ELFSOLAR_UI;
          passwordX = process.env.REACT_APP_ELFSOLAR_PW;
        } else if (localStorage.getItem('modül') === "enisolar") {
          usernameX = process.env.REACT_APP_ENISOLAR_UI;
          passwordX = process.env.REACT_APP_ENISOLAR_PW;
        } else if (localStorage.getItem('modül') === "cn") {
          usernameX = process.env.REACT_APP_CN_UI;
          passwordX = process.env.REACT_APP_CN_PW;
        } else if (localStorage.getItem('modül') === "aksaelektrik") {
          usernameX = process.env.REACT_APP_AKSA_UI;
          passwordX = process.env.REACT_APP_AKSA_PW;
        } else if (localStorage.getItem('modül') === "e-prosumer") {
          usernameX = process.env.REACT_APP_EPROSUMER_UI;
          passwordX = process.env.REACT_APP_EPROSUMER_PW;
        } else if (localStorage.getItem('modül') === "istrich") {
          usernameX = process.env.REACT_APP_ISTRICH_UI;
          passwordX = process.env.REACT_APP_ISTRICH_PW;
        } else if (localStorage.getItem('modül') === "arcelikkurumsalcozumler") {
          usernameX = process.env.REACT_APP_ARCELIK_UI;
          passwordX = process.env.REACT_APP_ARCELIK_PW;
        } else if (localStorage.getItem('modül') === "solarvil") {
          usernameX = process.env.REACT_APP_SOLARVIL_UI;
          passwordX = process.env.REACT_APP_SOLARVIL_PW;
        } else if (localStorage.getItem('modül') === "solarmesken") {
          usernameX = process.env.REACT_APP_SOLARMESKEN_UI;
          passwordX = process.env.REACT_APP_SOLARMESKEN_PW;
        } else if (localStorage.getItem('modül') === "akbank") {
          usernameX = process.env.REACT_APP_AKBANK_UI;
          passwordX = process.env.REACT_APP_AKBANK_PW;
        } else if (localStorage.getItem('modül') === "isbank") {
          usernameX = process.env.REACT_APP_ISBANK_UI;
          passwordX = process.env.REACT_APP_ISBANK_PW;
        } else if (localStorage.getItem('modül') === "volthaus") {
          usernameX = process.env.REACT_APP_VOLTHAUS_UI;
          passwordX = process.env.REACT_APP_VOLTHAUS_PW;
        } else if (localStorage.getItem('modül') === "greeneffect") {
          usernameX = process.env.REACT_APP_GREENEFFECT_UI;
          passwordX = process.env.REACT_APP_GREENEFFECT_PW;
        } else if (localStorage.getItem('modül') === "kiracsolar") {
          usernameX = process.env.REACT_APP_KIRACSOLAR_UI;
          passwordX = process.env.REACT_APP_KIRACSOLAR_PW;
        } else if (localStorage.getItem('modül') === "ibtenerji") {
          usernameX = process.env.REACT_APP_IBTSOLAR_UI;
          passwordX = process.env.REACT_APP_IBTSOLAR_PW;
        }
      }

      let domainSend;
      if (localStorage.getItem('modül') === "solarvis" && window.location.host !== "calculator.solarvis.co") {
        domainSend = 'demotest.solarvis.co';
      } else {
        domainSend = window.location.host
      }

      return axiosInstance
        .post(`/token/?domain=${domainSend}`, { username: usernameX, password: passwordX })
        .then((response) => {
          localStorage.setItem('access', response.data.access);
          localStorage.setItem('refresh', response.data.refresh);
          axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
          originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
          return axiosInstance.get(localStorage.getItem('tempRequest'));
        })
        .catch((errorr) => {
          return Promise.reject(error);
        });
    }

    if (error.response.data.code === 'token_not_valid'
      && error.response.status === 401
      && (error.response.data.messages[0].message === 'Token is invalid or expired')) {
      const refreshToken = localStorage.getItem('refresh');
      if (refreshToken) {
        const tokenParts = parseJwt(refreshToken);

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access', response.data.access);
              axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
              originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //console.log('Refresh token is expired', tokenParts.exp, now);
          //console.log('logging again...');
          let usernameX, passwordX;
          if (Boolean(localStorage.getItem('password')) && Boolean(localStorage.getItem('mail'))/*  && window.location.pathname !== '/' */) {
            usernameX = localStorage.getItem('mail');
            passwordX = localStorage.getItem('password');
          } else {
            if (localStorage.getItem('modül') === 'solarvis') {
              if (window.location.host === "calculator.solarvis.co") {
                usernameX = process.env.REACT_APP_SOLARVISGLOBAL_UI;
                passwordX = process.env.REACT_APP_SOALRVISGLOBAL_PW;
              } else {
                usernameX = process.env.REACT_APP_SOLARVISDEFAULT_UI;
                passwordX = process.env.REACT_APP_SOLARVISDEFAULT_PW;
              }
            } else if (localStorage.getItem('modül') === "istanbulges") {
              usernameX = process.env.REACT_APP_ISTANBULGES_UI;
              passwordX = process.env.REACT_APP_ISTANBULGES_PW;
            } else if (localStorage.getItem('modül') === "elfsolar") {
              usernameX = process.env.REACT_APP_ELFSOLAR_UI;
              passwordX = process.env.REACT_APP_ELFSOLAR_PW;
            } else if (localStorage.getItem('modül') === "enisolar") {
              usernameX = process.env.REACT_APP_ENISOLAR_UI;
              passwordX = process.env.REACT_APP_ENISOLAR_PW;
            } else if (localStorage.getItem('modül') === "cn") {
              usernameX = process.env.REACT_APP_CN_UI;
              passwordX = process.env.REACT_APP_CN_PW;
            } else if (localStorage.getItem('modül') === "aksaelektrik") {
              usernameX = process.env.REACT_APP_AKSA_UI;
              passwordX = process.env.REACT_APP_AKSA_PW;
            } else if (localStorage.getItem('modül') === "e-prosumer") {
              usernameX = process.env.REACT_APP_EPROSUMER_UI;
              passwordX = process.env.REACT_APP_EPROSUMER_PW;
            } else if (localStorage.getItem('modül') === "istrich") {
              usernameX = process.env.REACT_APP_ISTRICH_UI;
              passwordX = process.env.REACT_APP_ISTRICH_PW;
            } else if (localStorage.getItem('modül') === "arcelikkurumsalcozumler") {
              usernameX = process.env.REACT_APP_ARCELIK_UI;
              passwordX = process.env.REACT_APP_ARCELIK_PW;
            } else if (localStorage.getItem('modül') === "solarvil") {
              usernameX = process.env.REACT_APP_SOLARVIL_UI;
              passwordX = process.env.REACT_APP_SOLARVIL_PW;
            } else if (localStorage.getItem('modül') === "solarmesken") {
              usernameX = process.env.REACT_APP_SOLARMESKEN_UI;
              passwordX = process.env.REACT_APP_SOLARMESKEN_PW;
            } else if (localStorage.getItem('modül') === "akbank") {
              usernameX = process.env.REACT_APP_AKBANK_UI;
              passwordX = process.env.REACT_APP_AKBANK_PW;
            } else if (localStorage.getItem('modül') === "isbank") {
              usernameX = process.env.REACT_APP_ISBANK_UI;
              passwordX = process.env.REACT_APP_ISBANK_PW;
            } else if (localStorage.getItem('modül') === "volthaus") {
              usernameX = process.env.REACT_APP_VOLTHAUS_UI;
              passwordX = process.env.REACT_APP_VOLTHAUS_PW;
            } else if (localStorage.getItem('modül') === "greeneffect") {
              usernameX = process.env.REACT_APP_GREENEFFECT_UI;
              passwordX = process.env.REACT_APP_GREENEFFECT_PW;
            } else if (localStorage.getItem('modül') === "kiracsolar") {
              usernameX = process.env.REACT_APP_KIRACSOLAR_UI;
              passwordX = process.env.REACT_APP_KIRACSOLAR_PW;
            } else if (localStorage.getItem('modül') === "ibtenerji") {
              usernameX = process.env.REACT_APP_IBTSOLAR_UI;
              passwordX = process.env.REACT_APP_IBTSOLAR_PW;
            }
          }
          let domainSend;
          if (localStorage.getItem('modül') === "solarvis" && window.location.host !== "calculator.solarvis.co") {
            domainSend = 'demotest.solarvis.co';
          } else {
            domainSend = window.location.host
          }
          return axiosInstance
            .post(`/token/?domain=${domainSend}`, { username: usernameX, password: passwordX })
            .then((response) => {
              localStorage.setItem('access', response.data.access);
              localStorage.setItem('refresh', response.data.refresh);
              axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
              originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
              //console.log('logged in!!');
              return axiosInstance(originalRequest);
            })
            .catch((errorr) => {
              console.log(errorr);
            });
        }
      } else {
        console.log('Refresh token not available.');
      }
    }

    // Handle error with document related API's
    if (error.config.responseType === 'blob'
      && error.response.status === 401) {
      const refreshToken = localStorage.getItem('refresh');
      //console.log('refresh:', refreshToken);
      //console.log('xxxx');
      if (refreshToken) {
        //console.log(refreshToken);
        const tokenParts = parseJwt(refreshToken);

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        // console.log(tokenParts.exp);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access', response.data.access);
              axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
              originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //console.log('Refresh token is expired', tokenParts.exp, now);
          //console.log('logging again...');
          let usernameX, passwordX;
          if (Boolean(localStorage.getItem('password')) && Boolean(localStorage.getItem('mail'))/*  && window.location.pathname !== '/' */) {
            usernameX = localStorage.getItem('mail');
            passwordX = localStorage.getItem('password');
          }
          return axiosInstance
            .post('/token/', { username: usernameX, password: passwordX })
            .then((response) => {
              localStorage.setItem('access', response.data.access);
              localStorage.setItem('refresh', response.data.refresh);
              axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access}`;
              originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
              //console.log('logged in!!');
              return axiosInstance(originalRequest);
            })
            .catch((errorr) => {
              // When we delete user from database, we need to delete user info from local storage. Otherwise, we will get 401 error. 
              // We can't use localStorage.clear() because it will delete all data in local storage.
              localStorage.clear();
              console.log(errorr);
            });
        }
      } else {
        console.log('Refresh token not available.');
      }
    }


    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
