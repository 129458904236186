import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  TableCell,
  Table,
  TableBody,
  TableRow,
  withStyles,
  Checkbox,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import {
  changeIsim,
  changeSoyisim,
  changeTelefon,
} from '../../../../services/products/product/summaryReducer';

import UserLoginPage from '../../../../pages/UserLoginPage/UserLoginPage';
import NavbarMain from '../../../../containers/NavbarMainPage/NavbarMain';
import Footer from '../../../../containers/AlternativeFooter/Footer';
import classes from './PaymentPage.module.css';
import axiosInstance from '../../../../components/axios/axiosApi'; 
import SunSpinner from '../../../../components/UI/SunSpinner/SunSpinner'; 
import i18n from '../../../../i18n';
import MaskedInput from 'react-text-mask';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['0', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const StyledTableCell = withStyles(() => ({
  root: {
    fontSize: '1rem',
    fontFamily: 'Poppins',
  }
}))(TableCell);

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: 'var(--secondary-color)',
    color: 'white'
  }
})(Button);

const PaymentPage = (props) => {
  const location = useLocation();
  const { pkgOverview } = location.state;
  const [pkgOverviewLocalState, setPkgOverviewLocalState] = useState(pkgOverview);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const {
    isim,
    soyisim,
    mail,
    telefon
  } = useSelector(state => state.summary);

  const {
    cityName,
    ilce,
    detailedAdress
  } = useSelector(state => state.location);
  
  const [ userInfo, setUserInfo ] = useState({
    name: isim,
    surname: soyisim,
    phone: telefon,
    email: mail,
    address: detailedAdress ? detailedAdress : `${cityName}, ${ilce}`,
  });

  const [ promotionCode, setPromotionCode ] = useState(); 

  const { t, } = useTranslation();

  const [KVKKChecked, setKVKKChecked] = useState(false);

  const [redirectPayment, setRedirectPayment] = useState(false);
  const [paymentToken, setPaymentToken] = useState();

  const [creditBanks, setCreditBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState();

  const [paymentMethod, setPaymentMethod] = useState('credit');
  const [paymentAmountType, setPaymentAmountType] = useState('all');

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const infoTypeInputSize = {
    name: 6,
    surname: 6,
    address: 12,
    phone: 6,
    email: 6,
  };

  const labels = {
    name: t('PaymentForm.name'),
    surname: t('PaymentForm.surname'),
    address: t('PaymentForm.address'),
    phone: t('PaymentForm.phone'),
    email: t('PaymentForm.email'),
  };

  const autocomplete = {
    name: "given-name",
    surname: "family-name",
    address: "address",
    phone: "tel-national",
    email: "email",
  }

  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value)
  };

  const handleChangeInfo = (event) => {
    setUserInfo({
      ...userInfo,
      [event.target.name]: event.target.value
    })
  };

  const handleChangeCheck = (event) => {
    setKVKKChecked(event.target.checked);
  };

  const checkPrivatePromoCode = () => {
    axiosInstance.get(`home/promotion/check?code=${promotionCode}`)
      .then((response) => {
        if (response.data.status) {
          const promotionDetails = response.data.data.promotion_details;
          setPkgOverviewLocalState(prevState => ({
            ...prevState,
            price_TL_kdv_with_promotion: promotionDetails.final_price_tl_kdv_after_private_discount,
            private_discount_amount: promotionDetails.private_discount_amount,
            is_promotion_available: true
          }));
        }
      })
      .catch(error => {
        console.log(error);
      })
  };

  const initializeCreditPeca = async () => {
    setLoading(true);
    let tokenResponse = "";
      await axiosInstance.post('/home/credit/initialize', {
        email: userInfo.email,
        name: userInfo.name,
        surname: userInfo.surname,
        address: userInfo.address,
        phone: userInfo.phone,
        promotion_code: promotionCode ? promotionCode : null,
        language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase()
      })
        .then((response) => {
          if (response.data.status) {
            tokenResponse = response.data.data.token;
            setPaymentToken(tokenResponse);
          } else {
            console.log(response.data.message);
            setPaymentMethod('card');
          }
        })
        .catch((error) => {
          console.log(error);
          setPaymentMethod('card');
        });

      // Get Payment Info with Yapikredi
      if (tokenResponse) {
        await axios.get(`https://${process.env.REACT_APP_PECA_ENDPOINT}/transaction/fetchBanks/${tokenResponse}`)
        .then((response) => {
          if (response.data.length > 0) {
            setCreditBanks(response.data);
            setSelectedBank(response.data[0].bankId);
          } else {
            setPaymentMethod('card');
          }
        })
        .catch((error) => {
          console.log(error);
          setPaymentMethod('card');
        });
      }      
      setLoading(false);
  };
    

  const initializePayment = async () => {
    setLoading(true);

    // Update User Profile With New Info
    await axiosInstance.post('/customer/save_profile_info/?format=json', {
      isim: userInfo.name,
      soy_isim: userInfo.surname,
      telefon: userInfo.phone,
    })
      .then((response) => {
        if (response.data.status) {
          dispatch(changeIsim({ isim: userInfo.name }));
          dispatch(changeSoyisim({ soyisim: userInfo.surname }));
          dispatch(changeTelefon({ telefon: userInfo.phone }));
        }
      })
      .catch(error => {
        console.log(error);
      })

    // Determine API request URL based on payment method
    if (paymentMethod === 'credit') {
      // Next with Selected Bank
      await axiosInstance.post(`https://${process.env.REACT_APP_PECA_ENDPOINT}/transaction/selectBankAndInitializeCredit`,{
        token: paymentToken,
        selectedBankId: selectedBank,
      })
        .then((response) => {
          if (response.data.status) {
            window.location.href = response.data.data.redirectURL;
          }
        })
        .catch((error) => {
          console.log(error);
        });

    } else if (paymentMethod === 'card') {
      // Initialize Payment with PAYTR
      await axiosInstance.post('/home/initialize_payment?format=json', {
        email: userInfo.email,
        name: userInfo.name,
        surname: userInfo.surname,
        address: userInfo.address,
        phone: userInfo.phone,
        promotion_code: promotionCode ? promotionCode : null,
        language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase()
      })
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            setRedirectPayment(true);
            setPaymentToken(response.data.data.paymentPageURL);
          } else {
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setLoading(false);
  };

  const tempInitialize = () => {
    setRedirectPayment(true);
  };

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/on_odeme_ekrani" });
    initializeCreditPeca();
  }, []);

  if (!JSON.parse(localStorage.getItem('loggedIn'))) {
    return <UserLoginPage />
  }

  if (loading) {
    return (
      <>
        <SunSpinner text="" />
      </>
    );
  }

  return (
    <>
      <div className={classes.outerWrapper}>
        <NavbarMain />
        {!redirectPayment && <div style={{ paddingTop: 30, paddingBottom: 30, minHeight: '80vh' }}>
          <Container maxWidth={false}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                lg={8}
                md={12}
                xl={8}
                xs={12}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                  style={{ marginBottom: 20 }}
                >
                  <Card>
                    <div style={{ padding: 20, paddingBottom: 0 }}>
                      <h2 style={{ fontWeight: 'normal', fontSize: '1.5rem' }}>{t('PaymentForm.paymentOptions')}</h2>
                    </div>
                    <CardContent>
                      {paymentAmountType === 'all' && creditBanks.length > 0 &&
                      <div
                        className={paymentMethod === 'credit' ? classes.paymentMethodActive : classes.paymentMethodPassive} style={{ marginBottom: '20px' }}
                        onClick={() => setPaymentMethod('credit')}  
                      >
                        <h4 style={{ marginBottom: '5px', fontSize: '1.1rem' }}>{t('PaymentForm.shoppingLoan')}</h4>
                        <p style={{ fontSize: '1rem' }}>{t('PaymentForm.shoppingLoanText1')}</p>
                        <p style={{ fontSize: '1rem' }}>{t('PaymentForm.shoppingLoanText2')}</p>
                        {creditBanks.map((bank) => (
                          <div className={classes.bankSelected}>
                            <img style={{ width: 150 }} src={bank.logoURL}/>
                            {bank.isExclusive && <span>{t('PaymentForm.privateLoan')}</span>}
                          </div>
                        ))}
                      </div>}
                      <div
                        className={paymentMethod === 'card' ? classes.paymentMethodActive : classes.paymentMethodPassive}
                        onClick={() => setPaymentMethod('card')}
                      >
                        <h4 style={{ marginBottom: '5px', fontSize: '1.1rem' }}>{t('PaymentForm.debitCard')}</h4>
                        <p style={{ fontSize: '1rem' }}>{t('PaymentForm.paytrDescription')}</p>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <Card>
                    <div style={{ padding: 20, paddingBottom: 0 }}>
                      <h2 style={{ fontWeight: 'normal', fontSize: '1.5rem' }}>{t('PaymentForm.invoiceDetails')}</h2>
                    </div>
                    <CardContent>
                      <form>
                        <Container maxWidth={false}>
                          <Grid
                            container
                            spacing={2}
                          >
                            {Object.keys(userInfo).map((infoType) => (
                              <Grid
                                item
                                lg={infoTypeInputSize[infoType]}
                                md={12}
                                xl={infoTypeInputSize[infoType]}
                                xs={12}
                              >
                                <div>
                                  <span>{labels[infoType]} *</span>
                                  <TextField
                                    fullWidth
                                    name={infoType}
                                    id={autocomplete[infoType]}
                                    autoComplete={autocomplete[infoType]}
                                    /* error={userInfo[infoType] === ''}
                                    helperText={userInfo[infoType] === '' ? 'Bu alanı doldurmak zorunludur.' : ''} */
                                    onChange={handleChangeInfo}
                                    required
                                    /* label={`${labels[infoType]}`} */
                                    value={userInfo[infoType]}
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    InputProps={infoType === "phone" ? {inputComponent: TextMaskCustom} : {}}
                                    inputComponent={infoType === "phone" ? TextMaskCustom : {}}
                                  />
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </Container>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                xl={4}
                xs={12}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                  style={{ marginBottom: 20 }}
                >
                  <Card>
                    <div style={{ padding: 20, paddingBottom: 0 }}>
                      <h2 style={{ fontWeight: 'normal', fontSize: '1rem' }}>{t('PaymentForm.promotionCode')}</h2>
                    </div>
                    <CardContent style={{ paddingBottom: 16 }}>
                      <div style={{ padding: '15px 10px', paddingTop: '0px', fontSize: '0.85rem', display: 'flex', flexDirection: 'row' }}>
                        <TextField
                          fullWidth
                          name="promotionCode"
                          id="promotionCode"
                          onChange={(e) => setPromotionCode(e.target.value)}
                          required
                          /* label={`${labels[infoType]}`} */
                          value={promotionCode}
                          variant="outlined"
                          type="text"
                          size="small"
                        />
                        <StyledButton
                          size="small"
                          type="submit"
                          variant="contained"
                          fullWidth
                          style={{ maxWidth: 150, marginLeft: 20 }}
                          onClick={() => checkPrivatePromoCode()}
                        >
                          {t('PaymentForm.apply')}
                        </StyledButton>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <Card>
                    <div style={{ padding: 20, paddingBottom: 0 }}>
                      <h2 style={{ fontWeight: 'normal', fontSize: '1.5rem' }}>{t('PaymentForm.yourOrder')}</h2>
                    </div>
                    <CardContent>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell align="left" style={{ fontWeight: 'bold' }}>
                              {t('PaymentForm.product')}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ fontWeight: 'bold', width: '40%' }}>
                            {t('PaymentForm.subTotal')}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell align="left" style={{ fontSize: '1.2rem', borderBottom: 'none' }}>
                            {t('PaymentForm.paymentNameAll')}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ borderBottom: 'none' }}>
                              {pkgOverviewLocalState.price_TL_kdv} ₺
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell align="left">
                            {t('PaymentForm.subTotal')}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {pkgOverviewLocalState.price_TL_kdv} ₺
                            </StyledTableCell>
                          </TableRow>
                          {pkgOverviewLocalState.is_promotion_available && Boolean(Number(pkgOverviewLocalState.promotion_amount)) &&
                          <TableRow>
                            <StyledTableCell align="left" style={{ borderBottom: 'none'  }}>
                              {t('PaymentForm.discountAmount')}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ color: '#cc0000', borderBottom: 'none' }}>
                              -{pkgOverviewLocalState.promotion_amount} ₺
                            </StyledTableCell>
                          </TableRow>}
                          {pkgOverviewLocalState.private_discount_amount &&
                          <TableRow>
                            <StyledTableCell align="left" style={{ borderBottom: 'none'  }}>
                              {t('PaymentForm.promotionAmount')}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ color: '#cc0000', borderBottom: 'none' }}>
                              -{pkgOverviewLocalState.private_discount_amount} ₺
                            </StyledTableCell>
                          </TableRow>}
                          <TableRow>
                            <StyledTableCell align="left" style={{ borderBottom: 'none'  }}>
                            {t('PaymentForm.total')}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ fontWeight: 'bold', fontSize: '1.4rem', borderBottom: 'none'  }}>
                              {pkgOverviewLocalState.is_promotion_available ? pkgOverviewLocalState.price_TL_kdv_with_promotion : pkgOverviewLocalState.price_TL_kdv} ₺
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <div style={{ padding: '15px 10px', fontSize: '0.85rem' }}>
                        <p>
                        {t('PaymentForm.kvkk1')} <span><Link to="/kvkk-politikasi" target="_blank">{t('PaymentForm.kvkk2')}</Link> </span>
                        {t('PaymentForm.kvkk3')}
                        </p>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '0.85rem' }}>
                        <Checkbox
                          checked={KVKKChecked}
                          color="default"
                          onChange={handleChangeCheck}
                          inputProps={{ 'aria-label': 'KVKK checkbox' }}
                        />
                        <p><span><Link to="/mesafeli-satis-sozlesmesi" target="_blank">{t('PaymentForm.satis1')}</Link> </span>{t('PaymentForm.satis2')}</p>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <StyledButton
                          size="large"
                          type="submit"
                          variant="contained"
                          fullWidth
                          onClick={initializePayment}
                          disabled={!(KVKKChecked && userInfo.name && userInfo.surname && userInfo.email && userInfo.address && userInfo.phone)}
                        >
                          {t('PaymentForm.approveTransaction')}
                        </StyledButton>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>}
        {redirectPayment &&
        <div>
          <iframe
            src={paymentToken}
            id="paytriframe"
            frameBorder="0"
            scrolling="yes"
            height={isTabletOrMobile ? "1000" : "700"}
            style={{width: '100%', height: isTabletOrMobile ? '1000px !important' : '700px !important', minHeight: '700px !important' }}
          />
        </div>}
        <Footer />
      </div>
    </>
  );
}

export default PaymentPage;