import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useTranslation } from 'react-i18next';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import classes from './UsePlacesAutoComplete.module.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeLocation,
  changeZoom,
  changeAdressValidity,
  changeDetailedAdress,
  changeTempLocation,
  changeTabButonlarAktif,
  changePlaka,
  changeIlce,
  changeCityName,
  changeArea,
  changeMarker,
  changePolygonPoints,
  changeTempMapZoom
} from '../../services/products/product/reducer';
import { changeStep } from '../../services/products/product/stepReducer';
import UseMyLocation from './UseMyLocation';

export default function PlacesAutocomplete() {

  const dispatch = useDispatch();
  const { t, } = useTranslation();

  const { markerOn } = useSelector(state => state.location);
  const { firmaKodu } = useSelector(state => state.companyInfo);

  const decideCountryRestriction = () => {
    if (firmaKodu === 'electraVis Global') {
      return {};
    } else if (firmaKodu === 'GreenEffect') {
      return {
        country: 'gr'
      };
    } else {
      return {
        country: 'tr'
      };
    }
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: decideCountryRestriction(),
    },
    debounce: 300,
  });

  const willMount = React.useRef(true);
  if (willMount.current) {
    if (localStorage.getItem('detailedAddress')) {
      setValue(localStorage.getItem('detailedAddress'));
      if (!markerOn) {
        getGeocode({ address: localStorage.getItem('detailedAddress') })
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            //console.log("📍 Coordinates: ", { lat, lng });
            dispatch(changeLocation({latitude: lat, longitude: lng}));
            dispatch(changeTempLocation({tempLatitude: lat, tempLongitude: lng}));
          })
          .catch((error) => {
            console.log("😱 Error: ", error);
          });
      }
    }
    willMount.current = false;
  }

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  /* if (!ready) {
    alert('Google Haritalar yüklenirken bir hata oluştu. Lütfen sayfayı yenileyip tekrar deneyiniz.')
    window.location.reload();
  } */

  /* const handleSelect = (val) => {
    setValue(val, false);
  }; */
  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Store the detailed adress for the next time
    dispatch(changeDetailedAdress({detailedAdress: description}));

    // Tekrar adres seçimi yapıldığında alan, polygon noktaları, marker sıfırla!
    dispatch(changeArea({roofArea: 0}));
    dispatch(changePolygonPoints({polygonPoints: []}));
    dispatch(changeMarker({markerOn: false}));

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => {
        //console.log(results);
        let countryCode = "TR";
        results[0].address_components.forEach(addressObject => {
            // Decide component names accorfing to the country
            // Türkiye olduğu durum
            if (addressObject.types[0] === "country" && addressObject.short_name !== "TR") {
                countryCode = addressObject.short_name;
            }
            });
        results[0].address_components.forEach(addressObject => {
          // Decide component names accorfing to the country
          // Türkiye olduğu durum
          if (countryCode === "TR") {
            if (addressObject.types[0] === "postal_code") {
              dispatch(changePlaka({ plaka: addressObject.short_name.slice(0,2)}));
            } else if (addressObject.types[0] === "administrative_area_level_2" ) {
              if (String(addressObject.short_name) === "null" || String(addressObject.short_name) === "undefined") {
                dispatch(changeIlce({ ilce: 'Merkez'}));
              } else {
                dispatch(changeIlce({ ilce: String(addressObject.short_name)}));
              }
            } else if (addressObject.types[0] === "administrative_area_level_1" ) {
              dispatch(changeCityName({ cityName: String(addressObject.short_name)}));
            }
          } else {
            // Türkiye harici ülke olduğu durum
            // Şehir ismi
            if (addressObject.types[0] === "locality") {
              dispatch(changeCityName({ cityName: String(addressObject.short_name)}));
            } else {
              if (addressObject.types[0] === "administrative_area_level_2") {
                dispatch(changeIlce({ ilce: String(addressObject.short_name)}));
              } else {
                dispatch(changeIlce({ ilce: '-'}));
              }
            }
          }

          
        });
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        dispatch(changeLocation({latitude: lat, longitude: lng}));
        dispatch(changeTempLocation({tempLatitude: lat, tempLongitude: lng}));
        dispatch(changeZoom({zoomLevel: 18}));
        dispatch(changeTempMapZoom({tempMapZoom: 18}));
        dispatch(changeAdressValidity({validAdress: true}));
        dispatch(changeTabButonlarAktif({tabButonlarAktif: true}));
        dispatch(changeStep({stepNumber: 1}));
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  /* const renderSuggestions = () => {
    const suggestions = data.map((id, description) => (
      <ComboboxOption key={id} value={description} />
    ));

    return (
      <>
        {suggestions}
        <li className={classes.logo}>
          <img
            src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
            alt="Powered by Google"
          />
        </li>
      </>
    );
  }; */

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
        description
      } = suggestion;

      return (
       /*  <li key={id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li> */
        <ComboboxOption key={id} onClick={handleSelect(suggestion)} value={description} />
      );
    });

  return (
    <div className={classes.SearchContainer}>
      {/* <div className= {classes.iconContextCss}>
        <FaSearchLocation color='#003769'/>
      </div> */}
      <Combobox onSelect={handleSelect} aria-labelledby="demo">
        <ComboboxInput
          placeholder={t('AdressPage.placeholder')}
          style={{           
            boxSizing: 'border-box',
            border: 'none',
            height: '40px',
            borderRadius: '3px',
            outline: 'none',
            transition: 'all .5s',
            backgroundColor: 'transparent'
          }}
          className={classes.inputContainer}
          value={value}
          onChange={handleInput}
          disabled={!ready}
        />
        <ComboboxPopover>
          <ComboboxList style={{paddingTop: '15px'}}>{status === "OK" && renderSuggestions()}</ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
