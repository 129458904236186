import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Container,
  Grid,
  Box,
  Button,
  withStyles,
  makeStyles,
  Divider,
  Typography,
  CardContent,
  CardMedia,
  CardActions,
  Dialog,
  TableCell,
  Chip
} from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IconContext } from 'react-icons/lib';
import { ImPriceTag } from 'react-icons/im';
import MaterialCard from './MaterialCard';
import classes from './SolarPackage.module.css';

const StyledCardContent = withStyles({
  root: {
    position: 'relative'
  }
})(CardContent);

const StyledButton = withStyles({
  root: {
    textTransform: 'none'
  }
})(Button);

const StyledDivider = withStyles({
  root: {
    margin: '0px 6px'
  }
})(Divider);

const StyledTypography = withStyles({
  root: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    '@media (max-width:900px)': {
      fontSize: 14
    },
    textAlign: 'start',
    marginLeft: '10px'
  }
})(Typography);

const StyledDialogContent = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StyledDialog = withStyles({
  paper: {
    margin: 16,
    maxWidth: 900
  }
})(Dialog);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none',
    fontSize: '1rem',
    width: '50%'
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tabRoot: {
    width: 'fit-content'
  },
  list: {
    display: 'flex',
    flexDirection: 'row'
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#f7e2e2',
    color: '#ad3737'
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row'
  },
  card: {
    width: '100%',
    position: 'relative',
  },
  selectedCard: {
    width: '100%',
    position: 'relative',
    /* border: '4px solid #ee9800', */
    borderRadius: '10px',
    boxShadow: '0px 0px 15px 5px #fba43a',
  },
}));

const TextWithIcon = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <CheckCircleIcon style={{ color: '#4caf50' }}/>
      <span style={{ marginLeft: '10px', fontSize: '0.875rem' }}>{props.text}</span>
    </div>
  );
};

const SolarPackageComponent = (props) => {
  const [open, setOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const {
    bestRoiPackageId,
    cheapestPackageId,
    data,
    packageSelected,
    selectFunction,
    primaryColor,
    secondaryColor
  } = props;
  const MUIClasses = useStyles();

  const { t, } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.grow}>
        <Card key={data.id} className={packageSelected ? MUIClasses.selectedCard : MUIClasses.card}>
          <CardMedia
            component="div"
            style={{ position: 'relative' }}
            children={(
              <>
                {data.is_promotion_available &&
                <div style={{ padding: '15px', display: 'flex', justifyContent: 'center', flexDirection: 'row', backgroundColor: '#114992', alignItems: 'center' }}>
                  <IconContext.Provider value={{ color: '#ffffff', size: 24 }}>
                    <ImPriceTag />
                  </IconContext.Provider>
                  <h3 style={{ fontWeight: 'normal', color: 'white', marginLeft: 10 }}>{data.promotion_amount} ₺ {t('SolarPackageCard.discount')}</h3>
                </div>}
                <div style={{ padding: '15px', display: 'flex', justifyContent: 'center' }}>
                  <h3>{data.name}</h3>
                </div>
                <img
                  src={data.package_image_presigned_url}
                  alt={data.name}
                  style={{
                    height: isTabletOrMobile ? 100 : 200,
                    objectFit: isTabletOrMobile ? 'contain' : 'fill',
                    width: '100%'
                    }} />
                {(Number(cheapestPackageId) === data.id) &&
                <div style={{ position: 'absolute', right: '2rem', top: '5rem' }}>
                  <Chip
                    style={{
                      boxShadow: '1px 1px 5px 1px #3f51b5',
                      height: '30px',
                      fontSize: '0.9rem',
                    }}
                    label={t('SolarPackageCard.cheapest')}
                    color="primary"
                    size="medium"
                  />
                </div>}
                {(Number(bestRoiPackageId) === data.id) &&
                <div style={{ position: 'absolute', right: '2rem', top: '3rem' }}>
                  <Chip
                    style={{
                      boxShadow: '1px 1px 5px 1px #3f51b5',
                      height: '30px',
                      fontSize: '0.9rem',
                    }}
                    label={t('SolarPackageCard.bestROI')}
                    color="primary"
                    size="medium"
                  />
                </div>}
              </>
            )}
          />
          <Divider variant="middle" />
          <StyledCardContent>
            <div style={{ display: 'flex', flexDirection: 'column', 'padding': 5 }}>
              <div className={MUIClasses.list}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
                  <span>{t('SolarPackageCard.dcPower')}</span>
                  <h3>
                    {data.dc_guc_kilo_watt} kWp
                  </h3>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
                  <span>{t('SolarPackageCard.price')}</span>
                  <h3>
                    {data.price} {data.currency}
                  </h3>
                </div>
              </div>
              <Divider style={{ marginTop: '16px', marginBottom: '10px' }} />
              <div>
                <TextWithIcon text={t('SolarPackageCard.including1')} />
                <TextWithIcon text={t('SolarPackageCard.including2')} />
                <TextWithIcon text={t('SolarPackageCard.including3')} />
                <Divider style={{ margin: '10px 0px' }} />
                {data.materials.map((material, index) => (
                  <>
                    <div style={{ fontSize: '0.875rem', display: 'flex', flexDirection: 'row', padding: '10px 0px' }}>
                      <span style={{ width: '50%' }}>{material.human_readable_material_category}</span>
                      <span style={{ width: '50%' }}>{material.product_name}</span>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </StyledCardContent>
          <Divider variant="middle" />
          <CardActions
            className={MUIClasses.action}
          >
            <StyledButton variant="contained" style={{ backgroundColor: primaryColor, color: 'white' }} onClick={handleClickOpen}>
              {t('SolarPackageCard.packageDetails')}
            </StyledButton>
            <StyledButton variant="contained" style={{ backgroundColor: secondaryColor, color: 'white' }} onClick={() => selectFunction(data.id)} >
              {t('SolarPackageCard.select')}
            </StyledButton>
          </CardActions>
        </Card>
        <StyledDialog onClose={handleClose} aria-labelledby="solar-package-dialog" open={open}>
          <StyledDialogContent dividers>
            <Container maxWidth="lg">
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2 style={{ fontWeight: 'normal' }}>{t('SolarPackageCard.aboutPackage')}</h2>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <Card>
                    <Box minWidth={300}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={data.package_image_presigned_url} style={{ width: 400, height: 300, padding: 20 }} alt={data.name} />
                      </div>
                      {data.info1 && <h3 style={{ padding: '5px 40px', marginTop: '20px' }}>{t('SolarPackageCard.packageInfo')}</h3>}
                      <div style={{ display: 'flex', flexDirection: isTabletOrMobile ? 'column' : 'row' }}>
                        {data.info1 &&
                        <div style={{ flex: 1 }}>
                          <ul style={{ padding: '5px 40px' }}>
                            {data.info1 && <li>{data.info1}</li>}
                            {data.info2 && <li>{data.info2}</li>}
                            {data.info3 && <li>{data.info3}</li>}
                          </ul>
                        </div>}
                      </div>
                      <h3 style={{ padding: '5px 40px', marginTop: '20px' }}>{t('SolarPackageCard.usedMaterials')}</h3>
                      <Container maxWidth={false}>
                        <Grid
                          container
                          spacing={2}
                        >
                          {data.materials.map((material) => (
                            <Grid
                              item
                              lg={6}
                              md={12}
                              xl={6}
                              xs={12}
                              key={material.id}
                            >
                              <MaterialCard material={material} />
                            </Grid>
                          ))}
                        </Grid>
                      </Container>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </StyledDialogContent>
          <DialogActions>
            <StyledButton onClick={handleClose} color="primary">
              {t('SolarPackageCard.close')}
            </StyledButton>
          </DialogActions>
        </StyledDialog>
      </div>
    </>
  );
}

export default SolarPackageComponent;