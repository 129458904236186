/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../../components/axios/axiosApi';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import {
  changeUserAPIStatus,
  changeUserId
} from '../../../services/users/userReducer';
import {
  changeDiscoveryRequest
} from '../../../services/products/product/moduleReducer';
import {
  changeLocation,
  changeTempLocation,
  changeCityName,
  changeIlce,
  changeArea,
  changePolygonPoints,
  changeMarker,
  changePanelAmount,
  changeStaticMapURL,
  changeDetailedAdress
} from '../../../services/products/product/reducer';
import { Container, Grid, Box, Button, withStyles, TextField } from '@material-ui/core';
import classes from './UserMainPage.module.css';
import ProcessTracker from '../../../components/ProcessTracker';
import UserLoginPage from '../../UserLoginPage/UserLoginPage';
import {
  changeIsim,
  changeMail,
  changeSoyisim,
  changeTelefon,
  changeTesis,
  changeFatura,
  changeSozlesmeGucu,
  changeSarj,
  changeBatarya,
  changeCephe,
  changeEgim,
  changeEndUserCompanyName,
  changeVKN,
  changeTCKN,
} from '../../../services/products/product/summaryReducer';
import { useMediaQuery } from 'react-responsive';
import MaskedInput from 'react-text-mask';
import Spinner from '../../../components/UI/Spinner/Spinner'; 
import download from 'downloadjs';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['0', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const StyledButton = withStyles({
    root: {
      textTransform: 'none',
      backgroundColor: 'var(--secondary-color)',
      color: 'white'
    }
  })(Button);

const MainPage = () => {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const {
    module,
    discoveryRequest,
    availableModules
  } = useSelector(state => state.module);
  const { firmaKodu, firstScreenAtEnd } = useSelector(state => state.companyInfo);
  const { userId, userStatus, loggedIn } = useSelector((state) => state.users);
  const { mail } = useSelector((state) => state.summary);
  const { staticMapURL, detailedAdress } = useSelector(state => state.location);

  const [ loading, setLoading ] = useState(false);
  const [ explorationContactToggle, setExplorationContactToggle ] = useState(false);
  /* const [contactInfo, setContactInfo] = useState(
    {
      name: '',
      surname: '',
      phoneNumber: '0(   )    -    ',
    }
  );

  const [validity, setValidity] = useState(
    {
      nameValid: true,
      surnameValid: true,
      phoneNumberValid: true,
    }
  ); */

  /* 
  Avaliable contact keys for now:
  address
  companyName
  vkn
  phoneNumber
  surname
  tckn
  name
  */
  const [contactInfo, setContactInfo] = useState({});
  const [contactInfoValues, setContactInfoValues] = useState({});
  const [validity, setValidity] = useState({});

  const mapContactInfo = (value) => {
    switch (value) {
      case 'name':
        return 'isim';
      case 'surname':
        return 'soy_isim';
      case 'phoneNumber':
        return 'telefon';
      case 'companyName':
        return 'customer_firm';
      case 'referenceCode':
        return 'subcon_reference_code';
      default:
        return value;
    }
  };

/*   const checkValidity = (value, inputArea) => {
    let isValid = true;
    if (inputArea === "name") {
        isValid = value && isValid;
        setValidity({
            ...validity,
            nameValid: isValid
        })
    }

    if (inputArea === "surname") {
      isValid = value && isValid;
      setValidity({
          ...validity,
          surnameValid: isValid
      })
    }

    if(inputArea === "phoneNumber") {
        // const pattern = /^\d+$/;
        const pattern = /^0[.\(\/]5[0-9][0-9][.\)\/][.\ \/][1-9]([0-9]){2}[.\-\/]([0-9]){4}$/;
        isValid = pattern.test(value) && isValid;
        setValidity({
            ...validity,
            phoneNumberValid: isValid
        })
    }

    if (inputArea === "address") {
      isValid = value && isValid;
      setValidity({
          ...validity,
          addressValid: isValid
      })
    }

    if (inputArea === "companyName") {
      isValid = value && isValid;
      setValidity({
          ...validity,
          companyNameValid: isValid
      })
    }

    if (inputArea === "vkn") {
      const pattern = /^[0-9]{10}$/; 
      isValid = pattern.test(value) && isValid;
      setValidity({
          ...validity,
          vknValid: isValid
      })
    }

    if (inputArea === "tckn") {
      const pattern = /^[1-9]{1}[0-9]{9}[02468]{1}$/; 
      isValid = pattern.test(value) && isValid;
      setValidity({
          ...validity,
          tcknValid: isValid
      })
    }
  } */

  const inputChangeHandler = (event) => {
    const value = event.target.value;
    // checkValidity(value, event.target.name);
    setContactInfoValues({
        ...contactInfoValues,
        [event.target.name]: value
    });
}

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  async function getReport(id) {
    // Define report type
    let reportType = 'default_tr';
    let reportName = 'GES Ön Fizibilite Raporu.pdf';
    if (localStorage.getItem('dil') === 'en' || String(i18n.language).split('-')[0] === 'en' || localStorage.getItem('dil') === 'de' || String(i18n.language).split('-')[0] === 'de' || localStorage.getItem('dil') === 'el' || String(i18n.language).split('-')[0] === 'el') {
      reportType = 'default_en';
      reportName = 'SPP Pre-Feasibility Report.pdf';
    }
    setLoading(true);
    await axiosInstance.get(`report/generate?musteri_id=${id}&format=json&is_admin_page=0&report_type=${reportType}`/* , {
      responseType: 'blob'
    } */)
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' }
        );
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        setLoading(false);
        download(file, reportName, );
      })
      .catch();
  }

  const fetchUserFeasibilityInfo = async (url) => {
    let roofArea;
    let staticMapURLTemp;
    let userIdTemp;
    let conctInfoTemp = {};

    await axiosInstance.get(`input/get_company/`)
      .then((response) => {
        if (response.data.status) {
          setContactInfo(response.data.active_inputs);
          conctInfoTemp = response.data.active_inputs;
        }
      })
      .catch();

    await axiosInstance.get('/customer/fetch_profile_info/?format=json')
      .then(response => {
        const data = response.data.data;
        let contactInfoValuesTemp = {};

        conctInfoTemp.map(tempContact => {
          contactInfoValuesTemp[tempContact.title] = data[mapContactInfo(tempContact.title)];
        });

        setContactInfoValues(contactInfoValuesTemp);

        if (data.hasOwnProperty('isim') && data.isim) dispatch(changeIsim({ isim: data.isim }));
        if (data.hasOwnProperty('soy_isim') && data.soy_isim) dispatch(changeSoyisim({ soyisim: data.soy_isim }));
        if (data.hasOwnProperty('telefon') && data.telefon) dispatch(changeTelefon({ telefon: data.telefon }));
        if (data.hasOwnProperty('tckn') && data.tckn) dispatch(changeTCKN({ tckn: data.tckn }));
        if (data.hasOwnProperty('vkn') && data.vkn) dispatch(changeVKN({ vkn: data.vkn }));
        if (data.hasOwnProperty('address') && data.address) dispatch(changeDetailedAdress({ detailedAdress: data.address }));
        if (data.hasOwnProperty('username') && data.username) dispatch(changeMail({ mail: data.username }));
      })
      .catch(error => {
        console.log(error);
      })
      
    await axiosInstance.get('get_user_feasibility_info/?format=json')
      .then((response) => {
        if (response.data.status) {
          const data = response.data.data;
          roofArea = data.alan;
          staticMapURLTemp = data.gmaps_link;
          userIdTemp = data.id;
          dispatch(changeUserId({ userId: data.id}));
          dispatch(changeLocation({ latitude: data.enlem, longitude: data.boylam }));
          dispatch(changeTempLocation({ tempLatitude: data.enlem, tempLongitude: data.boylam }));
          dispatch(changeCityName({ cityName: data.il}));
          dispatch(changeIlce({ ilce: data.ilce}));
          dispatch(changePolygonPoints({polygonPoints: data.drawing_points}));
          dispatch(changeArea({ roofArea: data.alan }));
          dispatch(changeTesis({ tesis: data.tesis_tipi }));
          dispatch(changeFatura({ fatura: data.fatura_tutari }));
          dispatch(changeSozlesmeGucu({ sGucu: data.sgucu }));
          dispatch(changeEgim({ roofTilt: String(data.aci) }));
          dispatch(changeCephe({cephe: String(data.yon)}));
          dispatch(changeBatarya({batarya: data.is_battery}));
          dispatch(changeSarj({sarj: data.is_charger}));
          dispatch(changeUserAPIStatus({userStatus: data.status}));
          if (data.status === 4) {
            dispatch(changeDiscoveryRequest(true));
          }
          if (!detailedAdress) {
            dispatch(changeDetailedAdress({detailedAdress: `${data.il}, ${data.ilce}`}))
          }
          dispatch(changeMarker({markerOn: true}));
          dispatch(changeStaticMapURL({staticMapURL: data.gmaps_link}));
        }
      })
      .catch(error => {
        console.log(error);
    });

    await axiosInstance.get(`panel_sayisi?format=json&alan=${roofArea}`)
      .then(response => {
          const data = response.data.data;
          dispatch(changePanelAmount({panelAmount: data['panel_sayisi']}));
      })
      .catch(() => {
          dispatch(changePanelAmount({panelAmount:  Math.round(roofArea * 0.35)}));
      })

    if ((firstScreenAtEnd === "summary")) {
      await axiosInstance.post('musteri/save?format=json', {
        musteri_id: userIdTemp,
        status: 3,
        png: staticMapURLTemp,
        mail: mail,
        language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase(),
      })
        .then(response => {
          const data = response.data.data;
          dispatch(changeUserAPIStatus({userStatus: 3}));
          // Send pageview with a custom path
          ReactGA.send({ hitType: "pageview", page: "/kullaniciya_ozel_ekran" });
          return data['musteri_id'];
        })
        .catch(error => console.log(error));
    }
  }

  const explorationRequest = async (event) => {
    event.preventDefault();

    const contactInfoValuesKeys = Object.keys(contactInfoValues);
    const sendData = {};
    contactInfoValuesKeys.map(key => {
      sendData[mapContactInfo(key)] = contactInfoValues[key];
    });

    await axiosInstance.post('/customer/save_profile_info/?format=json', sendData)
      .then((response) => {
        if (response.data.status) {
          /* 
          Avaliable contact keys for now:
          name
          surname
          phoneNumber
          address
          companyName
          vkn
          tckn
          */
          if (contactInfoValues.hasOwnProperty("name") && contactInfoValues.name) dispatch(changeIsim({ isim: contactInfoValues.name }));
          if (contactInfoValues.hasOwnProperty("surname") && contactInfoValues.surname) dispatch(changeSoyisim({ soyisim: contactInfoValues.surname }));
          if (contactInfoValues.hasOwnProperty("phoneNumber") && contactInfoValues.phoneNumber) dispatch(changeTelefon({ telefon: contactInfoValues.phoneNumber }));
          if (contactInfoValues.hasOwnProperty("address") && contactInfoValues.address) dispatch(changeDetailedAdress({ detailedAdress: contactInfoValues.address }));
          if (contactInfoValues.hasOwnProperty("companyName") && contactInfoValues.companyName) dispatch(changeEndUserCompanyName({ endUserCompanyName: contactInfoValues.companyName }));
          if (contactInfoValues.hasOwnProperty("vkn") && contactInfoValues.vkn) dispatch(changeVKN({ vkn: contactInfoValues.vkn }));
          if (contactInfoValues.hasOwnProperty("tckn") && contactInfoValues.tckn) dispatch(changeTCKN({ tckn: contactInfoValues.tckn }));
        }
      })
      .catch(error => {
        console.log(error);
      })

    await axiosInstance.post('musteri/save?format=json', {
      musteri_id: userId,
      status: 4,
      png: staticMapURL,
      language: (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase(),
      mail: mail,
      ...sendData,
    })
      .then((response) => {
        if (response.data.status) {
          dispatch(changeUserAPIStatus({userStatus: 4}));
          dispatch(changeDiscoveryRequest(true));
          // Send pageview with a custom path
          ReactGA.send({ hitType: "pageview", page: "/kesif_talebi" });
        }
      })
      .then(() => {
        if (module === "isbank") {
          window.location.href = "https://anindaislem.isbank.com.tr/Home?id=CompanyCreditCardApply";
        }
      })
      .catch((error) => {
        console.log(error);
        if (module === "isbank") {
          window.location.href = "https://anindaislem.isbank.com.tr/Home?id=CompanyCreditCardApply";
        }
      });
  }

  const decideExplanations = () => {
    if (module === "aksaelektrik") {
      return {
        "title": t('UserMainPage.teklifHizmeti'),
        "buttonText": t('UserMainPage.teklifButon'),
        "afterRequestText": t('UserMainPage.teklifHizmetiAlindi'),
        "beforeRequestText": t('UserMainPage.teklifMetin'),
      }
    } else if (availableModules.includes("bank")) {
      if (module === "isbank") {
        return {
          "title": t('UserMainPage.kesifHizmetiIsbank'),
          "buttonText": t('UserMainPage.kesifButonIsbank'),
          "afterRequestText": t('UserMainPage.kesifHizmetiAlindiIsbank'),
          "beforeRequestText": t('UserMainPage.kesifMetinIsbank'),
        }
      }
      return {
        "title": t('UserMainPage.kesifHizmetiBank'),
        "buttonText": t('UserMainPage.kesifButonBank'),
        "afterRequestText": t('UserMainPage.kesifHizmetiAlindiBank'),
        "beforeRequestText": t('UserMainPage.kesifMetinBank'),
      }
    } else if (availableModules.includes("subcon_detailed")) {
      return {
        "title": t('UserMainPage.kesifHizmetiSubcon'),
        "buttonText": t('UserMainPage.kesifButonSubcon'),
        "afterRequestText": t('UserMainPage.kesifHizmetiAlindiSubcon'),
        "beforeRequestText": t('UserMainPage.kesifMetinSubcon'),
      }
    } else {
      return {
        "title": t('UserMainPage.kesifHizmeti'),
        "buttonText": t('UserMainPage.kesifButon'),
        "afterRequestText": t('UserMainPage.kesifHizmetiAlindi'),
        "beforeRequestText": t('UserMainPage.kesifMetin'),
      }
    }
  };

  const contactItemTranslator = {
    tr: {
      "name": "İsim",
      "surname": "Soyisim",
      "phoneNumber": "Telefon Numarası",
      "address": "Adres",
      "companyName": "Şirket Adı",
      "vkn": "VKN",
      "tckn": "TCKN",
      "referenceCode": "Referans Kodu",
      "branchSelection": "Şube Seçimi",
    },
    en: {
      "name": "Name",
      "surname": "Surname",
      "phoneNumber": "Phone Number",
      "address": "Address",
      "companyName": "Company Name",
      "vkn": "VKN",
      "tckn": "TCKN",
      "referenceCode": "Reference Code",
      "branchSelection": "Branch Selection",
    },
    el: {
      "name": "Όνομα",
      "surname": "Επώνυμο",
      "phoneNumber": "Τηλέφωνο",
      "address": "Διεύθυνση",
      "companyName": "Όνομα Εταιρείας",
      "vkn": "VKN",
      "tckn": "TCKN",
      "referenceCode": "Κωδικός Αναφοράς",
      "branchSelection": "Επιλογή Υποκαταστήματος",
    }
  };


  useEffect(() => {
    fetchUserFeasibilityInfo("");
  }, [loggedIn]);

  if (!JSON.parse(localStorage.getItem('loggedIn'))) {
    let URLString = window.location.href;
    let url = new URL(URLString);
    if (!url.searchParams.get("token")){
        return <UserLoginPage />
    }
  }

  if (loading) {
    if (localStorage.getItem('dil') === 'tr' || String(i18n.language).split('-')[0] === 'tr') {
      return (
        <>
          <Spinner text="Raporunuz oluşturuluyor.."/>
        </>
      )
    } else {
      return (
        <>
          <Spinner text="Your report is being created.."/>
        </>
      )
    }
  }

  return (
      <Container 
          maxWidth={true}
          style={{ height: '100%', backgroundColor:'#F4F6F8', minHeight: '90vh' }}
      >
          <Grid 
              container
              spacing={3}
              style={{ justifyContent:'center' }}
          >
              <Grid
                  item
                  lg={5}
                  md={5}
                  xl={5}
                  xs={12}
              >
                  <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px' }} >
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                          <h2 className={classes.allh2}>{t('UserMainPage.reportReady')}</h2>
                          <p>{t('UserMainPage.reportText')}</p>
                          <Box my={2}>
                              <div style={{ textDecoration: 'none' }} onClick={() => getReport(userId)}>
                                  <StyledButton
                                      size="large"
                                      type="submit"
                                      variant="contained"
                                  >
                                      {t('UserMainPage.reportButton')}
                                  </StyledButton>
                              </div>
                          </Box>
                      </div>
                      {isDesktopOrLaptop &&
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                          {module !== "isbank" && <img src="./images/report.png" alt="GES Fizibilite Raporu" className={classes.icon}/>}
                          {module === "isbank" && <img src="./images/report-icon-alternative.png" alt="GES Fizibilite Raporu" className={classes.icon}/>}
                      </div>}
                  </div>
              </Grid>
              {module !== "akbank" && module !== "isbank" &&
              <Grid
                  item
                  lg={7}
                  md={7}
                  xl={7}
                  xs={12}
              >
                <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-between' }} >
                  {!discoveryRequest &&
                    <>
                      <div>
                        <h2 className={classes.allh2}>{decideExplanations().title}</h2>
                        <p>{decideExplanations().beforeRequestText}</p>
                        <form autoComplete='on' style={{ padding: '20px 0px' }}>
                          <Container maxWidth={true} style={{ padding: '0px' }}>
                            <Grid 
                              container
                              spacing={2}
                            >
                              {!(_.isEmpty(contactInfo)) && contactInfo.sort((a, b) => a.id - b.id).map((contactItem, index) => (
                              <Grid
                                item
                                lg={6}
                                md={6}
                                xl={6}
                                xs={12}
                              >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>{contactItemTranslator[(localStorage.getItem('dil') || String(i18n.language).split('-')[0])][contactItem.title]}</span>
                                {contactItem.type !== "select" &&
                                <TextField 
                                  fullWidth
                                  name={contactItem.title}
                                  type={contactItem.type}
                                  id={contactItem.autocomplete}
                                  autoComplete={contactItem.autocomplete}
                                  onChange={(event) => inputChangeHandler(event)}
                                  value={contactInfoValues[contactItem.title]}
                                  /* helperText={!validity[contactItem.title] ? contactItem.placeholder_tr : ""}
                                  error={!validity[contactItem.title]} */
                                  variant="outlined"
                                  size="small"
                                  InputProps={contactItem.title === "phoneNumber" ? {inputComponent: TextMaskCustom} : {}}
                                  inputComponent={contactItem.title === "phoneNumber" ? TextMaskCustom : {}}
                                />
                                }
                                {contactItem.type === "select" &&
                                <Autocomplete
                                  value={contactInfoValues[contactItem.title]}
                                  onChange={(event, newValue) => setContactInfoValues(prevState => ({
                                    ...prevState,
                                    [contactItem.title]: newValue
                                  }))}
                                  id={contactItem.title}
                                  options={contactItem.selection_choices}
                                  renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                                />
                                }
                                {(contactItem.title === "referenceCode" || contactItem.title === "branchSelection") &&
                                <p style={{ fontSize: '0.8rem' }}>{t('UserMainPage.optionalInput')}</p>
                                }
                                </div>
                              </Grid>
                              ))}
                            </Grid>
                          </Container>
                        </form>
                        <Box my={2}>
                          <div style={{ textDecoration: 'none' }}>
                            <StyledButton
                              size="large"
                              type="submit"
                              variant="contained"
                              onClick={(e) => explorationRequest(e)}
                              disabled={!Object.keys(contactInfoValues).filter(key => key !== 'branchSelection').filter(key => key !== 'referenceCode').map(key => contactInfoValues[key]).every(value => value)}
                            >
                              {decideExplanations().buttonText}
                            </StyledButton>
                          </div>
                        </Box>
                      </div>
                    </>}
                    {discoveryRequest &&
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2 className={classes.allh2} style={{ fontSize: '1.3rem' }}>{decideExplanations().afterRequestText}</h2>
                      </div>
                    }
                    {isDesktopOrLaptop &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src="./images/solar-cati-kesif.png" alt="Solar Keşif Hizmeti" className={classes.icon} style={{ width: 300 }} />
                    </div>}
                </div>
              </Grid>}
              {module === "akbank" &&
              <Grid
                  item
                  lg={7}
                  md={7}
                  xl={7}
                  xs={12}
              >
                <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-between' }} >
                  <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
                    <h2 className={classes.allh2} style={{ fontSize: '1.0rem' }}>
                      Hemen kredi talebinde bulunmak için formu doldurun!
                    </h2>
                    <a href="https://www.akbank.com/basvuru/cati-ges-fizibilite/">
                      <StyledButton
                          size="large"
                          type="submit"
                          variant="contained"
                      >
                        Kredi Formuna Git!
                      </StyledButton>
                    </a>
                  </div>
                </div>
              </Grid>}
              {module === "isbank" &&
              <Grid
                  item
                  lg={7}
                  md={7}
                  xl={7}
                  xs={12}
              >
                <div className={classes.card} style={{ marginTop: isDesktopOrLaptop ? '40px' : '20px', justifyContent: 'space-between' }} >
                  <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <h2 className={classes.allh2}>
                      {t('UserMainPage.kesifHizmetiIsbank')}
                    </h2>
                    <p>{t('UserMainPage.kesifMetinIsbank')}</p>
                    <Box my={2}>
                      <div style={{ textDecoration: 'none' }}>
                        <StyledButton
                          size="large"
                          type="submit"
                          variant="contained"
                          onClick={(e) => explorationRequest(e)}
                        >
                          {t('UserMainPage.kesifButonIsbank')}
                        </StyledButton>
                      </div>
                    </Box>
                  </div>
                </div>
              </Grid>}
              {/* Doküman yükleme component'i keşif talebinden sonra render'lansın */}
              {userStatus > 3 && !(module === "isbank" || module === "akbank") &&
              <Grid
                item
                lg={5}
                md={5}
                xl={5}
                xs={12}
              >
                  <div className={classes.card} style={{ marginBottom: isDesktopOrLaptop ? '40px': '0px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                          <h2 className={classes.allh2}>{t('UserMainPage.uploadNow')}</h2>
                          <p>{t('UserMainPage.uploadNowText')}</p>
                          <Box my={2}>
                              <Link style={{ textDecoration: 'none' }} to="photos">
                                  <StyledButton
                                      size="large"
                                      type="submit"
                                      variant="contained"
                                  >
                                      {t('UserMainPage.uploadButton')}
                                  </StyledButton>
                              </Link>
                          </Box>
                      </div>
                      {isDesktopOrLaptop &&
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src="./images/upload.png" alt="GES Fizibilite Dosyaları" className={classes.icon}/>
                      </div>}
                  </div>
              </Grid>}
              {!(module === "isbank" || module === "akbank") &&
              <Grid
                  item
                  lg={7}
                  md={7}
                  xl={7}
                  xs={12}
              >
                  <div className={classes.cardColumn}>
                      <div>
                          <h2 className={classes.allh2} style={{ textAlign: 'center' }}>{t('UserMainPage.progress')}</h2>
                      </div>
                      <div>
                          <ProcessTracker moduleType="industrial" />
                      </div>
                  </div>
              </Grid>}
          </Grid>
      </Container>
  );
};

export default MainPage;
