import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedPackageId: Number(localStorage.getItem('selectedPackageId')) || null,
};

export const solarPackageSlice = createSlice({
  name: 'solarPackage',
  initialState,
  reducers: {
    changeSelectedPackageId: (state, action) => {
      state.selectedPackageId = action.payload;
      localStorage.setItem('selectedPackageId', action.payload);
    },
    resetPackage: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const { changeSelectedPackageId, resetPackage } = solarPackageSlice.actions

export default solarPackageSlice.reducer