import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../../../../containers/AlternativeNavbar/Navbar';
import { useSelector } from 'react-redux';
import classes from './KVKKPagesStyle.module.css';
import { Helmet } from "react-helmet";
import Footer from '../../../../containers/AlternativeFooter/Footer';
import testCompanyInfo from './testData';

export default function PageWrapper(props) {
  const { t, } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    companyName,
    companyPhone,
    companyMail,
    companyAddress,
    vergiDairesi,
    vergiNo,
    mersisNo,
    companyContactPerson
  } = useSelector(state => state.companyInfo);

  return (
    <>
      <Helmet>
      <title>Kişisel Verilerin İşlenmesi ve Korunması Politikası</title>
      </Helmet>
      <main className={classes.mainWrapper}>
        <Navbar/>
        <div className={classes.outerWrapper}>
          <h2>Kişisel Verilerin İşlenmesi ve Korunması Politikası</h2>
          <p className={classes.boldItalicUnderlineParagraph}>1. Giriş</p>
          <p className={classes.standartParagraph}>“{companyAddress}” adresinde kurulu bulunan {companyName} (Kısaca "<span className={classes.boldItalicSpan}>ŞİRKET</span>” olarak anılacaktır) olarak bizim için kişisel verilerin korunması hususu büyük hassasiyet arz etmekte olup, önceliklerimiz arasındadır. İşbu Kişisel Verilerin İşlenmesi ve Korunması Politikası (“Politika”) ile kişisel verilerin işlenmesi ve korunması konusunda <span className={classes.boldItalicSpan}>ŞİRKET</span> tarafından benimsenen ve kişisel verilerin korunmasına ilişkin fiili uygulamada dikkat edilen ilkeler ortaya konulmakta ve şirketimizin 6698 sayılı Kişisel Verilerin Korunması Kanunu’nda (“Kanun”) öngörülen düzenlemelere uyumu hakkındaki temel ilkeler belirlenmektedir.</p>
          <p className={classes.boldItalicUnderlineParagraph}>2. Amaç ve Kapsam</p>
          <p className={classes.standartParagraph}>Bu Politika belgesinin temel amacı, kişisel verilerin toplanması, saklanması, işlenmesi, paylaşılması ve aktarılmasına ilişkin süreçlerimiz hakkında şirket yetkililerimizi, çalışanlarımızı, ortaklarımızı, kullanıcılarımızı, işbirliği içinde olduğumuz kurumları/kişileri ve bu kurumların çalışanlarını ve yetkililerini ve ayrıca kişisel verileri şirketimiz tarafından işlenen tüm kişileri bilgilendirmektir. Şirketimizin temel prensibi, kişisel verilerin işlenmesinde şeffaflığı sağlamak, farkındalığı yükseltmek, ilgili tüm tarafların haklarını ve sorumluluklarını açıkça ortaya koyup net bir şekilde belirtmektir.</p>
          <p className={classes.boldItalicUnderlineParagraph}>3. Tanımlar</p>
          <p className={classes.standartParagraph}>Açık Rıza: Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza</p>
          <p className={classes.standartParagraph}><span className={classes.boldItalicSpan}>Hizmet</span>: Şirketimiz, tarafımızca yönetilen ve idare edilen <span className={classes.boldItalicSpan}>ŞİRKET</span>’e ait adresli internet sitesi, mobil uygulama ve sair bağlı/ilgili dijital alanların tamamı</p>
          <p className={classes.standartParagraph}>İlgili Kullanıcı: Verilerin teknik olarak depolanması, korunması ve yedeklenmesinden sorumlu olan kişi ya da birim hariç olmak üzere veri sorumlusu organizasyonu içerisinde veya veri sorumlusundan aldığı yetki ve talimat doğrultusunda kişisel verileri işleyen kişiler</p>
          <p className={classes.standartParagraph}>İmha: Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi</p>
          <p className={classes.standartParagraph}>Kanun: 07.04.2016 tarihli ve 29677 sayılı Resmi Gazete’de yayımlanan 6698 sayılı Kişisel Verilerin Korunması Kanunu</p>
          <p className={classes.standartParagraph}>Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi</p>
          <p className={classes.standartParagraph}>Kişisel Verilerin Anonim Hale Getirilmesi: Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle getirilmesi</p>
          <p className={classes.standartParagraph}>Kişisel Verilerin İşlenmesi: Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi</p>
          <p className={classes.standartParagraph}>Kişisel Verilerin Silinmesi: Kişisel verilerin ilgili kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi</p>
          <p className={classes.standartParagraph}>Kişisel Verilerin Yok Edilmesi: Kişisel verilerin hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar kullanılamaz hale getirilmesi işlemi.</p>
          <p className={classes.standartParagraph}>Kurul: Kişisel Verileri Koruma Kurulu</p>
          <p className={classes.standartParagraph}>Kurum: Kişisel Verileri Koruma Kurumu</p>
          <p className={classes.standartParagraph}>Özel Nitelikli Kişisel Veri: Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri</p>
          <p className={classes.standartParagraph}>Şirket:{companyName}</p>
          <p className={classes.standartParagraph}>Veri İşleyen: Veri sorumlusu tarafından verilen yetkiye dayanarak ve onun adına veri işleyen kişidir.</p>
          <p className={classes.standartParagraph}>Veri Sahibi: Şirketimiz ve/veya şirketimizin bağlı şirketleri/iştiraklerinin ticari ilişki içinde bulunduğu çalışanları, kullanıcıları, müşterileri, iş ortakları, hissedarları, yetkilileri, potansiyel müşterileri, aday çalışanları, stajyerleri, ziyaretçileri, tedarikçileri, işbirliği içinde çalıştığı gerçek kişiler veya kurumların çalışanları, üçüncü kişiler ve burada sayılanlarla sınırlı olmamak üzere diğer kişiler gibi kişisel verisi işlenen / işlenebilecek olan gerçek kişi</p>
          <p className={classes.standartParagraph}>Veri Sorumlusu : Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişi</p>
          <p className={classes.boldItalicUnderlineParagraph}>4. Kişisel Verilerin Toplanması</p>
          <p className={classes.standartParagraph}><span className={classes.boldItalicSpan}>Hizmet</span> üzerinden yapılan kullanıcı kayıt başvurularında ve de <span className={classes.boldItalicSpan}>Hizmet</span>’in her türden kullanımında veri sahiplerince şirketimize doğrudan yazılı, sözlü veya elektronik olarak aktarılan kaynaklardan kişisel veri toplayabilmektedir. Ayrıca <span className={classes.boldItalicSpan}>Hizmet</span>’i ya da var olan/olabilecek diğer sair internet ortamlarımızı ziyaret eden/kullanan kişilerin de IP (internet protokol) adresleri ve sair logları tutulmakta, çerezler (cookies) ve de piksel etiketleri gibi sair teknolojiler aracılığı ile kullanımları kolaylaştırılabilmektedir. Diğer yandan, şirketimizin hukuki güvenliğini temin için, sözleşme ilişkisi kurduğumuz gerçek kişilerin veya tüzel kişilerin gerçek kişi temsilcilerinin de kişisel verileri, sözleşme eki olarak saklanmaktadır.</p>
          <p className={classes.standartParagraph}><span className={classes.boldItalicSpan}>Hizmet</span>’in alt yapısı, kullanıcıların IP adresleri, kullanılan cihazların türü ve dili, <span className={classes.boldItalicSpan}>Hizmet</span>’e giriş tarihi ve zamanı gibi kullanıcı hareketlerinin şirketimiz tarafından takip edilmesine ve eğilimlerin tespit edilmesine yardımcı olacak bilgileri otomatik olarak toplamaktadır.</p>
          <p className={classes.standartParagraph}>Ayrıca kullanıcıların cihazlarını, <span className={classes.boldItalicSpan}>Hizmet</span>’e erişimlerini kolaylaştırmak, <span className={classes.boldItalicSpan}>Hizmet</span>’e dair tüm servislerin, işlemlerin ve ara yüzlerin sorunsuz çalışır şekilde sunulabilmesi için gerekli temel fonksiyonları aktive etmek ve çevrimiçi deneyimlerini kişiselleştirmek için bir ya da daha fazla çerez (tanımlama bilgileri) gönderilebilmektedir. “Çerezler” kayıt tutma amacıyla kullanıcıların cihazlarında bulunan, kayıtların tutulabilmesini ve bu sayede kullanıcının belirlenebilmesini sağlayan çok küçük dosyalardır. Bu çerezler, <span className={classes.boldItalicSpan}>Hizmet</span> her hangi bir şekilde kullanıldığında girilen kimi bilgileri dikkatle izleyebilmek için kullanılır. Çerezler, kullanıcıların isim, cinsiyet veya adres gibi kişisel verilerini kaydetmemektedir.</p>
          <p className={classes.standartParagraph}><span className={classes.boldItalicSpan}>Hizmet</span>’i kullanan veri sahiplerinin, kullanmakta oldukları cihazların imkân tanıması halinde, cihaz ayarlarını değiştirerek çerezlerin kullanılmasını engelleme, çerezler kullanılmadan önce uyarı almayı tercih etme veya sadece bazı çerezleri devre dışı bırakma ya da silme imkânları bulunmaktadır. Şirketimiz işbu Politika ile <span className={classes.boldItalicSpan}>Hizmet</span> kullanıcılarına, çerez kullanımının kapsamı ve amaçları hakkında detaylı bilgilendirmeyi yapmayı hedeflemiştir. Kullanıcılarımızın cihaz ayarlarından çerezleri devre dışı bırakmamış olmaları ve <span className={classes.boldItalicSpan}>Hizmet</span>’i kullanmaya devam etmeleri halinde, çerez kullanımına izin verildiği kabul edilmektedir.</p>
          <p className={classes.boldItalicUnderlineParagraph}>5. Kişisel Verilerin İşlenmesinde Uygulanacak İlkeler</p>
          <p className={classes.standartParagraph}>Şirketimiz, kişisel verilerin korunması ve işlenmesiyle ilgili olarak mevzuatta düzenlenen genel ilkeler ışığında yer verilen şartları karşılamakta ve kişisel verilerin Kanun’a ve ilgili mevzuata uygun olarak işlenmesini sağlamak amacıyla aşağıda sıralanan ilkelere uygun hareket etmektedir:</p> 
          <li>Hukuka ve Dürüstlük Kuralına Uygun Kişisel Veri İşleme Faaliyetlerinde Bulunma</li>
          <p className={classes.standartParagraph}>Şirketimiz kişisel verilerin işlenmesi faaliyetleri kapsamında hukuka ve dürüstlük kurallarına uygun hareket etmektedir. Şirketimiz gerektiği kadar kişisel veriyi, veri işleme amaçlarıyla sınırlı olarak ve bu amaçlara uygun düşecek seviyede işlemektedir.</p>
          <li>Kişisel Verilerin Doğru ve Gerektiğinde Güncel Olmasını Sağlama</li>
          <p className={classes.standartParagraph}>Şirketimiz, işlemekte olduğu kişisel verilerin doğru ve güncel olmasına azami hassasiyet göstermekte ve bu kapsamda veri sahipleri ile iletişime geçerek veya veri sahiplerinden gelen talepleri değerlendirmeye alarak, gerekli güncellemeleri yapmaktadır.</p> 
          <li>Belirli, Açık ve Meşru Amaçlarla İşleme</li>
          <p className={classes.standartParagraph}>Şirketimiz, kişisel veri işleme amacını açık ve kesin olarak belirlemekte, bu sırada da meşruiyet ve hukuka uygunluk esasını benimsemektedir. Özellikle yürütmekte olduğumuz ticari faaliyet ile bağlantılı ve bu çerçevede gerekli olan kadar veri şirketimizce işlemektedir. Kişisel verilerin hangi amaçla işleneceği Aydınlatma Bildirimleri vasıtasıyla muhtelif veri sahibi gruplarına bildirilmektedir.</p> 
          <li>İşlendikleri Amaçla Bağlantılı, Sınırlı ve Ölçülü Olma</li>
          <p className={classes.standartParagraph}>Şirketimiz, topladığı kişisel verileri, ticari faaliyetlerinin devamını temin ve sözleşmesel ve yasal taahhüt ve yükümlülüklerini ifa etme, veri sahibi grupları ile olan ilişkilerinin yürütülmesini sağlama amaçlarının gerçekleştirilebilmesine elverişli bir biçimde işlemektedir. İşleme amacının gerçekleştirilmesiyle ilgili olmayan veya ihtiyaç duyulmayan kişisel veriler işlenmemektedir.</p> 
          <li>İlgili Mevzuatta Öngörülen veya İşlendikleri Amaç İçin Gerekli Olan Süre Kadar Muhafaza Etme</li>
          <p className={classes.standartParagraph}>Şirketimiz tarafından toplanan kişisel veriler, işlendikleri amaç için gerekli olan süreler ve her hâlükârda ilgili mevzuatta belirtilen yasal süreler ve/veya dava/talep zamanaşımı süreleri boyunca muhafaza etmektedir. Bu amaçla, öncelikle toplanan kişisel verilerin toplanma ve işlenme amacına yönelik olarak gerekli çalışmalar tamamlanmakta ve işlenmesini gerektiren sebeplerin ortadan kalkıp kalkmadığı düzenli olarak denetlenmektedir. İşlendikleri amaç için gerekli olan sürenin bittiği veya işlenmesini gerektiren sebeplerin ortadan kalktığı hallerde ve her hâlükârda mevzuatta ön görülen sürenin dolmasıyla birlikte toplanan kişisel veriler şirketimiz tarafından silinmekte, yok edilmekte veya anonim hale getirilmektedir.</p> 
          <p className={classes.boldItalicUnderlineParagraph}>6. Kişisel Verilerin İşlenme Amaçları</p>
          <p className={classes.standartParagraph}>Şirketimizin topladığı kişisel veriler, Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartlarından birine veya birkaçına dayalı olarak işlenebilmektedir. Dolayısıyla öncelikle kişisel veri işleme faaliyetlerinin bu şartlardan birinin kapsamına girip girmediği şirketimizce incelenmekte, bu şartlardan herhangi birinin mevcut olmaması halinde ise veri sahibinin daha önceden alınmış veya yeni alınan açık rızasına dayanılarak kişisel veriler işlemektedir.</p> 
          <p className={classes.standartParagraph}>Kanun’un 5. ve 6. maddelerinde sayılan şartlar ise şunlardır:</p> 
          <ul>
            <li>Kişisel verilerin işlenmesine ilişkin şirketimizin ilgili faaliyette bulunmasının kanunlarda açıkça öngörülmesi,</li>
            <li>Kişisel verilerin şirketimiz tarafından işlenmesinin bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili ve gerekli olması,</li>
            <li>Kişisel verilerin işlenmesinin şirketimizin hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</li>
            <li>Kişisel verilerin alenileştirilmiş olması şartıyla; alenileştirme amacıyla sınırlı bir şekilde şirketimiz tarafından işlenmesi,</li>
            <li>Kişisel verilerin şirketimiz tarafından işlenmesinin şirketimizin, verisi işlenen kişilerin veya üçüncü kişilerin haklarının tesisi, kullanılması veya korunması için zorunlu olması,</li>
            <li>Veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla şirketimizin meşru menfaatleri için kişisel veri işleme faaliyetinde bulunulmasının zorunlu olması,</li>
            <li>Veri sahibinin sağlığı ve cinsel hayatı dışındaki özel nitelikli kişisel veriler açısından kanunlarda öngörülmüş olması,</li>
            <li>Veri sahibinin sağlığına ve cinsel hayatına ilişkin özel nitelikli kişisel veriler açısından ise kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım işlerinin yürütülmesi, sağlık servisleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından işlenmesi.</li>
          </ul>
          <p className={classes.standartParagraph}>Bu kapsamda şirketimiz, kişisel verilerinizi aşağıdaki amaçlarla işlemektedir:</p> 
          <ul>
            <li>Şirketimizin ticari faaliyetini yürütebilmek için kurduğumuz sözleşmelere dair edimlerimizi ifa edebilmek;</li>
            <li>Kullanıcılarımızın <span className={classes.boldItalicSpan}>Hizmet</span>’le ilgili şikâyet ve sair talepleriyle ilgili olarak kullanıcılarla iletişim kurabilmek ve kullanıcı desteklerimizi etkin biçimde sağlayabilmek; </li>
            <li>Sunduğumuz işbirliklerinin ve servislerin kalitesini artırabilmek;</li>
            <li>Çeşitli kampanyalar oluşturabilmek;</li>
            <li>Kullanıcı tercihlerine özgülenmiş kampanyalarımız hakkında bilgi verebilmek;</li>
            <li>Ticari faaliyetlerimizin geliştirilmesi için çeşitli istatistiki verileri çıkarabilmek;</li>
            <li>Yasal ve/veya idari yükümlülüklerimizi yerine getirebilmek;</li>
            <li>Kullanıcılarımızın talep ettiği hallerde özel kampanyaları ve indirimleri tesis ve buna bağlı üyelik işlemlerini takip edebilmek;</li>
            <li>Çalışanlarımızla akdettiğimiz iş sözleşmeleri gereği işveren olarak üstlendiğimiz yükümlülükleri yerine getirmek;</li>
            <li>İş Kanunu, Sosyal Sigortalar ve Genel Sağlık Sigortası Kanunu, İş Sağlığı ve Güvenliği Kanunu ve ilgili mevzuattan kaynaklanan yasal ve/veya idari yükümlülüklerimizi yerine getirmek;</li>
            <li>İnsan kaynakları süreçlerinin planlanmak ve uygulamak;</li>
            <li>Personel özlük dosyalarının oluşturmak;</li>
            <li>İşçi sağlığı ve iş güvenliği süreçlerini yürütmek;</li>
            <li>İş ortaklarımız veya tedarikçilerimiz ile olan ilişkileri yönetmek;</li>
            <li>Şirketimizin hukuk, finansal raporlama ve risk yönetimi işlemlerini icra ve takip etmek;</li>
            <li>Kurumsal iletişim ve yönetim faaliyetlerini planlanmak ve uygulamak;</li>
            <li>Şirketimizin, çalışanlarımızın, kullanıcılarımızın ve ilişki içinde olduğumuz 3. tarafların güvenliğini sağlayabilmek;</li>
            <li>Mevzuattan doğan yükümlülüklerimizi yerine getirebilmek için, ilgili resmi ve idari kurumlardan ya da yargı mercilerinden gelen talepleri yanıtlamak, gerekliliklerini yerine getirmek.</li>
          </ul>
          <p className={classes.boldItalicUnderlineParagraph}>7. Kişisel Verilerin Güvenliği </p>
          <p className={classes.standartParagraph}>Şirketimiz, işlediği kişisel verilerin hukuka ve dürüstlük kurallarına aykırı olarak işlenmesini ve kişisel verilere yetkisiz ve haksız erişilmesini önlemek ve kişisel verilerin yeterli güvenlik seviyesinde muhafaza edilmesini, işlenmesini ve aktarılmasını temin etmek amacıyla gerekli her türlü teknik ve idari tedbirleri almıştır. Bu bağlamda Kişisel Verilerin Korunması Kurumunun Veri Güvenliği Rehberi kılavuzumuzdur, Kurul kararları ve Kurum rehberleri düzenli olarak takip edilmekte ve uyguladığımız tedbir ve yöntemler gereken hallerde güncellenmektedir.</p> 
          <p className={classes.standartParagraph}>Veri güvenliğinde öncelikle topladığımız kişisel verilere sadece yetkili birim ve kişilerce erişilmesine ve onlar tarafından işlenmesine izin veriyoruz. Kişisel verilerin gizliliğini, bütünlüğünü koruyor, erişilebilirliğini mümkün olduğunca kısıtlıyoruz. Kişisel verileri toplandıkları amaç ile sınırlı ve bağlantılı olarak, hukuken işlenebilir haller etrafında işliyoruz. Veriye erişim yetkisi olanların dışında hiç kimse ile şifre ve kullanıcı adlarını paylaşmıyoruz.</p> 
          <p className={classes.standartParagraph}>Otomatik olmayan yöntemlerle işlediğimiz ve kayıt altında tuttuğumuz verileri kilitli dolap ve kapalı zarflar içinde muhafaza ediyoruz. Bu dolap ve saklama yerlerinin kilitleri yine sadece ilgili birim ve yetkilendirilmiş personellerimizde mevcuttur.</p> 
          <p className={classes.standartParagraph}>Kullanıcılarmıza ya da sair ilgili kişilere ait veya onlar üzerinden ulaşarak işlediğimiz özel nitelikli kişisel veri yoktur. Çalışanlarımızın özel nitelikli kişisel veri kategorisinde kalan verilerini ise Kanun’un 6. maddesi uyarınca ve yeterli güvenlik önlemlerini alarak işliyoruz. Kurul’un 31.01.2018 tarihli ve 2018/10 sayılı “Özel Nitelikli Kişisel Verilerin İşlenmesinde Veri Sorumlularınca Alınması Gereken Yeterli Önlemler” kararı bu konudaki kılavuzumuzdur.</p> 
          <p className={classes.standartParagraph}>Aldığımız sair servisler ve yaptığımız işbirlikleri gereği olarak aramızda kişisel veri aktarımı olan üçüncü kişilerle yaptığımız sözleşmelere, gizlilik yükümlülüğünün yanı sıra kişisel verilerin korunması amacıyla Kanun düzenlemelerine uygun hükümler ekledik / ekliyoruz. Gerekli taahhütleri vermeyen ve güvenlik önlemlerini sağlamayan, verilerin gizlilik, bütünlük ve erişimine ilişkin Kanun düzenlemeleri ile Kurul kararlarına uymayan üçüncü kişilere kişisel veri aktarımı yapmıyoruz. </p> 
          <p className={classes.boldItalicUnderlineParagraph}>8. Kişisel Verilerin Aktarılması </p>
          <p className={classes.standartParagraph}>Şirketimiz, topladığı kişisel verileri, Kanun’da öngörülen zorunluluklara ve Kurul tarafından alınan kararlara ve ilan edilen ikincil düzenlemelere uygun olarak üçüncü kişilere aktarmaktadır.
Şirketimiz tarafından özel nitelikli kişisel verilerin açık rıza alınarak toplanması halinde, ancak yine veri sahibinin açık rızası olması şartıyla başka gerçek veya tüzel kişilere aktarılacaktır. Şu kadar ki bu veriler, Kanun veya diğer kanunların zorunlu kıldığı durumlarda veri sahibinin açık rızası olmadan da mevzuatta öngörülen şekilde ve sınırlarla bağlı olarak, yetkili kılınan idari veya adli kurum veya kuruluşa aktarılabilecektir.</p> 
          <p className={classes.standartParagraph}>Bununla birlikte, Kanun’un 5. ve 6. maddelerinde öngörülen durumlarda topladığımız kişisel veriler öngörülen hukuki sebebe bağlı olarak üçüncü kişilere aktarılabilmektedir. Şirketimiz, kişisel verileri Kanun’da ve ilgili mevzuatta öngörülen şartlara uygun olarak ve bu mevzuatta belirtilen tüm güvenlik önlemlerini alarak, Türkiye’de yerleşik kişilerle ve/veya kurumlarla, ifa yardımcıları, grup şirketleri ve ticari ilişki içinde bulunduğu üçüncü kişilerle paylaşmakta, sınıflandırmakta ve işlemektedir. Kişisel veriler bu surette üçüncü kişilere aktarılmadan önce de, gerekli gizlilik taahhütleri ilgili kişilerden talep edilmekte, mümkün olan tüm idari ve teknik tedbirlerin alınması sağlanmaktadır.</p> 
          <p className={classes.standartParagraph}>Yukarıda belirttiklerimiz çerçevesinde ve Kanun’un 8. maddesinde öngörülen düzenlemelere uyarak; yasal ve sözleşmesel yükümlülüklerimizi yerine getirebilmek, ticari amaçlarımız ve veri sahibinin temel hak ve özgürlüklerini ihlal etmeyecek şekilde meşru menfaatlerimiz doğrultusunda mali, hukuksal, işe alım, istatistik, arşivleme gibi servisleri alabilmek, kanun ve yargı kararları ile idari uygulamalardan doğan yükümlülüklerimizi yerine getirebilmek için ve kişisel verilerin işlenmesinin gerektirdiği sınırlar dâhilinde kişisel verileri ilgili servis/iş sunucuları, iş ortakları, ifa yardımcıları, adli, resmi ve idari makamlara aktarabiliyoruz. Kanun’un 8. maddesi ve işbu Politikamız etrafında yapacağımız veri aktarımı verinin Kanun’a aykırı şekilde işlendiği anlamında olmayıp, bu bağlamda veri aktarımı yaptığımız ve yapacağımız tüm gerçek ve tüzel kişilerden veri güvenliği ve gizliliğine dair gerekli taahhütleri alarak, veriyi güvenli yollarla aktarıyoruz. </p> 
          <p className={classes.boldItalicUnderlineParagraph}>9. Kişisel Verilerin Silinmesi, Yok Edilmesi Ve Anonimleştirilmesi</p>
          <p className={classes.standartParagraph}>Şirketimiz, kişisel verileri, Kanun’un 7. maddesine uygun olarak işlemekte ve işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kendiliğinden silmekte, yok etmekte veya anonim hale getirmektedir.</p> 
          <p className={classes.standartParagraph}>Bunun yanı sıra veri sahipleri de şirketimize yöneltecekleri talep ile, kendilerine ait kişisel verilerin silinmesini veya yok edilmesini talep edebilecektir. Söz konusu talebin bizlere ulaşması üzerine, şirketimiz:</p> 
          <p className={classes.standartParagraph}>-Kişisel verileri işleme şartlarının tamamı ortadan kalkmışsa, talebe konu kişisel verileri silmekte, yok etmekte veya anonim hale getirmektedir. Bu surette veri sahibinin talebi en geç otuz (30) gün içinde sonuçlandırılarak ve kendisine bilgi verilmektedir.</p> 
          <p className={classes.standartParagraph}>-Kişisel verileri işleme şartlarının tamamı ortadan kalkmamışsa, şirketimize yöneltilen talep, söz konusu durum hakkında gerekçeli bilgilendirme yapılarak, Kanun’un 13. maddesinin üçüncü fıkrası uyarınca reddedilmektedir. Böyle bir durumda red cevabımız veri sahibine en geç otuz (30) gün içinde yazılı olarak ya da elektronik ortamda bildirilmektedir.</p> 
          <p className={classes.standartParagraph}>-Kişisel verilerin işleme şartlarının tamamı ortadan kalkmış ve fakat veri sahibinin talebine konu olan kişisel veriler üçüncü kişilerle paylaşılmış ise, şirketimize yöneltilen talep verilerin paylaşıldığı üçüncü kişiye bildirilmekte ve üçüncü kişi tarafından bu Politika ve mevzuat çerçevesinde gerekli işlemlerin yapılması talep edilmektedir.</p> 
          <p className={classes.standartParagraph}>Bununla birlikte, şirketimiz, hukuka uygun olarak işlenen kişisel verilerin işlenmesini gerektiren sebepler ortadan kalktığında kişisel verileri anonimleştirebilmektedir. Kanun’un 28. maddesine uyarınca; anonim hale getirilmiş olan kişisel veriler araştırma, planlama ve istatistik gibi amaçlarla işlenebilir. Bu tür işlemeler Kanun kapsamı dışında olup, bu durumda kişisel veri sahibinin açık rızası aranmamaktadır.</p> 
          <p className={classes.boldItalicUnderlineParagraph}>10. Kişisel Veri Sahibinin Hakları Ve Bu Hakların Kullanılması</p>
          <p className={classes.standartParagraph}>Kişisel veri sahipleri, Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahiptirler:</p>
          <ul>
            <li>Kişisel verilerinin işlenip işlenmediğini öğrenme, işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
            <li>Yurt içinde veya yurtdışında aktarıldığı üçüncü kişileri bilme,</li>
            <li>Eksik/yanlış işlenmişse düzeltilmesini isteme,</li>
            <li>Gereken şartlar çerçevesinde silinmesini, yok edilmesini isteme,</li>
            <li>Yukarıda belirtilen düzeltme, silinme ve yok edilmeye ilişkin hakları uyarınca yapılan işlemlerin, kişisel verilerinin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>İşlenen kişisel verilerinizin münhasıran otomatik yöntemlerle analiz edilmesi suretiyle aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
            <li>Kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.
Kanun’un 28. Maddesinin ikinci fıkrası gereğince; aşağıda sıralanan hallerde kişisel veri sahipleri Kanun’un 11. maddesinde sayılan diğer haklarını ileri süremezler:</li>
            <li>Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç soruşturması için gerekli olması,</li>
            <li>Kişisel veri sahibi tarafından kendisi tarafından alenileştirilmiş kişisel verilerin işlenmesi,</li>
            <li>Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak görevli ve yetkili kamu kurum ve kuruluşları ile kamu kurumu niteliğindeki meslek kuruluşlarınca, denetleme veya düzenleme görevlerinin yürütülmesi ile disiplin soruşturma veya kovuşturması için gerekli olması,</li>
            <li>Kişisel veri işlemenin bütçe, vergi ve mali konulara ilişkin olarak Devletin ekonomik ve mali çıkarlarının korunması için gerekli olması.</li>
          </ul>
          <p className={classes.standartParagraph}>Ancak sayılan hallerde veri sahiplerinin zararlarının giderilmesini talep etme hakları saklıdır.
Kanun’un 28. maddesinin ilk fıkrası ise aşağıda belirtilen hallerin açıkça Kanun’un kapsamı dışında olduğu düzenlemektedir. Dolayısıyla kişisel veri sahipleri bu sayılan hallerde Kanun’un 11. maddesinde sayılan haklarını ileri süremezler:</p> 
          <ul>
            <li>Kişisel verilerin resmi istatistik ile anonim hâle getirilmek suretiyle araştırma, planlama ve istatistik gibi amaçlarla işlenmesi,</li>
            <li>Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini, ekonomik güvenliği, özel hayatın gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade özgürlüğü kapsamında işlenmesi,</li>
            <li>Kişisel verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini veya ekonomik güvenliği sağlamaya yönelik olarak kanunla görev ve yetki verilmiş kamu kurum ve kuruluşları tarafından yürütülen önleyici, koruyucu ve istihbari faaliyetler kapsamında işlenmesi,</li>
            <li>Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz işlemlerine ilişkin olarak yargı makamları veya infaz mercileri tarafından işlenmesi.</li>
          </ul>
          <p className={classes.standartParagraph}>Kişisel veri sahipleri yukarıda sıralanan haklarına ilişkin taleplerini, hak sahibi olduklarını belgelendirmek suretiyle, <span className={classes.boldItalicSpan}>Hizmet</span>’e ilişkin sair iletişim formları aracılığıyla elektronik ortamda veya yine <span className={classes.boldItalicSpan}>Hizmet</span> üzerinden edinebileceğiniz sair iletişim bilgilerimiz üzerinden, noter kanalıyla veya güvenli elektronik imza kullanmak kaydıyla kayıtlı elektronik posta adresleri, elektronik veya fiziksel evrak ile başvuru yaparak bize ücretsiz olarak iletebilir.</p> 
          <p className={classes.standartParagraph}>Veri sahibinin böyle bir talebinin bizlere iletilmesi halinde, talebin niteliğine göre en geç otuz (30) gün içinde ilgili talep sonuçlandırılmaktadır. Veri sahibinin talep ettiği işlemin ayrıca bir maliyeti gerektirmesi halinde veya yazılı olarak verilecek cevabımızın on (10) sayfayı aşması halinde, 10.03.2018 tarihli ve 30356 sayılı Resmi Gazete’de yayımlanmış Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’in 7. maddesinde belirtilen ücret alınabilecektir. Başvurunun <span className={classes.boldItalicSpan}>ŞİRKET</span>’in hatasından kaynaklanması hâlinde ise alınan ücret ilgiliye iade edilir.</p> 
          <p className={classes.standartParagraph}><span className={classes.boldItalicSpan}>ŞİRKET</span>, başvuruda bulunan kişinin veri sahibi olup olmadığını tespit etmek amacıyla başvurucudan bilgi ve belge talep etme ve yapılan başvuruda yer alan talepleri ve bilgileri açıklığa kavuşturmak amacıyla, veri sahibine başvurusu ile ilgili soru yöneltme haklarına sahiptir.
Aşağıda sayılan hallerde yapılan başvuru, gerekçesi açıklanarak reddedilebilir:</p> 
          <ul>
            <li>Yukarıda yer verildiği üzere, Kanun’un 28. maddesinin ilk fıkrası uyarınca, açıkça Kanun’un kapsamı dışında olduğu düzenlenen hallerden birinin söz konusu olması,</li>
            <li>Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç soruşturması için gerekli olması,</li>
            <li>Kişisel veri sahibinin talebinin diğer kişilerin hak ve özgürlüklerini engelleme ihtimali olması,</li>
            <li>Orantısız çaba gerektiren taleplerde bulunulmuş olması,</li>
            <li>Talep edilen bilginin kamuya açık bir bilgi olması,</li>
            <li>Kişisel verileri işleme şartlarının tamamının ortadan kalkmamış olması,</li>
            <li>Kanunlar (Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, İş Kanunu, Vergi Usul Kanunu, İş Sağlığı ve Güvenliği Kanunu, Sosyal Sigortalar ve Genel Sağlık Sigortası Kanunu gibi) gereği veriyi muhafaza etme süresinin dolmamış olması.</li>
          </ul>
          <p className={classes.boldItalicUnderlineParagraph}>11. Organizasyonel Yapılanma Ve Sürdürülebilirlik</p>
          <p className={classes.standartParagraph}>Şirketimiz, Kanun ve ilgili mevzuatın öngördüğü düzenlemelere uyumluluğun denetlenmesinden, işbu Politikamızın, ilişkili diğer politikalarımızın, sair belgelerimiz ile ilgili tüm <span className={classes.boldItalicSpan}>Hizmet</span> içi metinlerimizle, bildirimlerimizin içeriklerinin yönetilmesinden ve gerekli hallerde güncellenmesinden, kişisel verilerin korunması hakkında sürdürülebilirliğin sağlanmasından, yönetim tarafından alınan kararların yerine getirilmesinden ve konu hakkındaki diğer regülasyon ve iç denetimlerin yapılmasından sorumlu olmak üzere sorumlular atamıştır. Kanun’un uygulaması ve denetimi ile sürdürülebilirliğin sağlanması için düzenli takip ve kontrol, veri sorumlusu olarak şirketimizin bizzat yükümlülüğündedir ve tüm çalışanlarımızı ve iş ortaklarımızı bu yönde geliştirmek için çaba sarf edeceğiz.</p> 
          <p className={classes.standartParagraph}>Şirketimizin bu husustaki sorumlularının özel görevleri ise aşağıda belirtilmiştir:</p>
          <ul>
            <li>Kişisel verilerin işlenmesi ve korunması ile ilgili temel politikaları ve mevzuatla uyum sağlanması için yapılması gerekenleri belirlemek,</li>
            <li>Belirlenen temel politika ve eylem adımlarını şirket yönetiminin onayına sunmak, uygulanmasını gözetmek ve koordinasyonunu sağlamak,</li>
            <li>Kişisel verilerin işlenmesi ve korunmasına ilişkin politikaların ne şekilde uygulanacağına ve denetimin hangi sıklıkta, hangi şekilde yapılacağına karar vermek, şirket yönetiminin onayını aldıktan sonra gerekli görev ve yetki dağılımlarını gerçekleştirmek,</li>
            <li>Şirketin birimlerini ve çalışanlarını bilgilendirmek, konu hakkında farkındalıklarını yükseltmek,</li>
            <li>Şirketin kişisel veri işleme faaliyetlerinde oluşabilecek riskleri tespit ederek gerekli önlemlerin alınmasını temin etmek, iyileştirme önerilerini şirket yönetiminin onayına sunmak,</li>
            <li>Çalışanların kişisel verilerin korunması ve şirket politikaları konusunda eğitilmelerini sağlamak,</li>
            <li>Kişisel veri sahiplerinin başvurularını değerlendirmek ve karara bağlamak,</li>
            <li>Şirketin Kanun kapsamındaki yükümlülüklerini yerine getirmesi için Şirket regülasyonların yapılmasını sağlamak,</li>
            <li>Kişisel verilerin korunması konusundaki gelişmeleri takip etmek, bu gelişmeler kapsamında yapılması gerekenler konusunda şirket yönetimine önerilerde bulunmak,</li>
            <li>Kurum ve Kurul ile olan ilişkileri yönetmek.</li>
          </ul> 
          <p className={classes.standartParagraph}>Şirket Adı: {companyName}</p> 
          <p className={classes.standartParagraph}>Adres: {companyAddress}</p> 
          <p className={classes.standartParagraph}>Telefon Numarası: {companyPhone}</p> 
          <p className={classes.standartParagraph}>MERSİS Numarası: {mersisNo}</p> 
          <p className={classes.standartParagraph}>E-posta Adresi: {companyMail}</p> 

        </div>
        <Footer />
      </main>

    
  
  {/* 
  <span className={classes.boldItalicSpan}><span className={classes.boldItalicSpan}>Hizmet</span>ler</span>
  <span className={classes.boldItalicSpan}>Electravis</span>
  <p className={classes.boldItalicUnderlineParagraph}></p>
  <p className={classes.standartParagraph}></p> 
  */}
  
      
  </>
  )
}