import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firmaKodu: '',
  companyName: '',
  companyPhone: '',
  companyMail: '',
  companyAddress: '',
  companyPrimaryColor: '',
  companySecondaryColor: '',
  vergiDairesi: '',
  vergiNo: '',
  mersisNo: '',
  companyContactPerson: '',
  logoPreviewURL: '',
  kvkkLink: '',
  cookiesLink: '',
  firstScreenAtEnd: 'contact'
};

export const companyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState,
  reducers: {
    changeCompanyInfo: (state, action) => {
      state.firmaKodu = action.payload.firmaKodu;
      state.companyName = action.payload.companyName;
      state.companyPhone = action.payload.companyPhone;
      state.companyMail = action.payload.companyMail;
      state.companyAddress = action.payload.companyAddress;
      state.companyPrimaryColor = action.payload.companyPrimaryColor;
      state.companySecondaryColor = action.payload.companySecondaryColor;
      state.vergiDairesi = action.payload.vergiDairesi;
      state.vergiNo = action.payload.vergiNo;
      state.mersisNo = action.payload.mersisNo;
      state.companyContactPerson = action.payload.companyContactPerson;
      state.kvkkLink = action.payload.kvkkLink;
      state.cookiesLink = action.payload.cookiesLink;
      state.firstScreenAtEnd = action.payload.firstScreenAtEnd;
    },
    changeLogoPreviewURL: (state, action) => {
      state.logoPreviewURL = action.payload.logoPreviewURL;
    },
    resetCompanyInfo: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const { changeCompanyInfo, changeLogoPreviewURL, resetCompanyInfo } = companyInfoSlice.actions

export default companyInfoSlice.reducer