import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import classes from './Navbar.module.css';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import {
    withStyles,
    Select,
    MenuItem,
    InputBase
  } from '@material-ui/core';

const StyledSelect = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
})(Select);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
        marginTop: theme.spacing(3),
        },
        marginRight: 30,
        backgroundColor: 'whitesmoke',
        borderRadius: 4,
        height: 40,
        alignSelf: 'center'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'whitesmoke',
        // border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        'var(--font-fam)',
        'sans-serif',
        ].join(','),
        '&:focus': {
        borderRadius: 4,
        // borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


function Navbar(props) {

    const { t, } = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

    const [language, setLanguage] = React.useState(localStorage.getItem('dil') || String(i18n.language).split('-')[0]);

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
        localStorage.setItem('dil', event.target.value);
        i18n.changeLanguage(event.target.value);
      };

    const dispatch = useDispatch();
    const { module } = useSelector(state => state.module);
    const { logoPreviewURL, firmaKodu } = useSelector(state => state.companyInfo);

    return (
        <>
            {isTabletOrMobile && <header className={classes.mobileHeader}>
                <div className={classes.headerWrapper}>
                    <div className={classes.seperateItems}>
                        <div className={classes.hrefTag}>
                            <Link to="/" style={{ alignItems: 'center', display: 'flex' }}>
                                <img 
                                    src={logoPreviewURL}
                                    alt={module} 
                                    className={classes.logo}
                                />
                            </Link>
                        </div>
                        <StyledSelect
                            labelId="Change-language"
                            id="change-language"
                            value={language}
                            onChange={handleChangeLanguage}
                            label="Language"
                            variant="outlined"
                            input={<BootstrapInput />}
                        >
                            {(firmaKodu !== 'electraVis Global' && firmaKodu !== 'GreenEffect') && <MenuItem value="tr">
                                <img
                                    style={{ width: 20 }}
                                    alt="Turkey"
                                    src="/images/turkey.svg"
                                />
                            </MenuItem>}
                            <MenuItem value="en">
                                <img
                                    style={{ width: 20 }}
                                    alt="United States"
                                    src="/images/united-states.svg"
                                />
                            </MenuItem>
                            {(firmaKodu === 'electraVis Global' || firmaKodu === 'GreenEffect') &&
                            <MenuItem value="el">
                                <img
                                    style={{ width: 20 }}
                                    alt="γιουνάνκα"
                                    src="/images/greece.png"
                                />
                            </MenuItem>}
                            {(firmaKodu === 'electraVis Global') &&
                            <MenuItem value="ro">
                                <img
                                    style={{ width: 20 }}
                                    alt="Română"
                                    src="/images/romania.svg"
                                />
                            </MenuItem>}
                        </StyledSelect>
                    </div>
                </div>
            </header>}
            {isDesktopOrLaptop &&
                <header className={classes.headerWrapper}>
                    <header className= {classes.NavigationHeader}>
                        <div className={classes.hrefTag}>
                            <Link to="/" style={{ alignItems: 'center', display: 'flex' }}>
                                <img 
                                    src={logoPreviewURL} 
                                    alt={module} 
                                    className={classes.logo}
                                />
                            </Link>
                        </div>
                        <StyledSelect
                            labelId="Change-language"
                            id="change-language"
                            value={language}
                            onChange={handleChangeLanguage}
                            label="Language"
                            variant="outlined"
                            input={<BootstrapInput />}
                        >
                            {(firmaKodu !== 'electraVis Global' && firmaKodu !== 'GreenEffect') && <MenuItem value="tr">
                                <img
                                    style={{ width: 20, marginRight: 10 }}
                                    alt="Turkey"
                                    src="/images/turkey.svg"
                                />
                                Türkçe
                            </MenuItem>}
                            <MenuItem value="en">
                                <img
                                    style={{ width: 20, marginRight: 10 }}
                                    alt="United States"
                                    src="/images/united-states.svg"
                                />
                                English
                            </MenuItem>
                            {(firmaKodu === 'electraVis Global' || firmaKodu === 'GreenEffect') &&
                            <MenuItem value="el">
                                <img
                                    style={{ width: 20, marginRight: 10 }}
                                    alt="γιουνάνκα"
                                    src="/images/greece.png"
                                />
                                γιουνάνκα
                            </MenuItem>}
                            {(firmaKodu === 'electraVis Global') &&
                            <MenuItem value="ro">
                                <img
                                    style={{ width: 20, marginRight: 10 }}
                                    alt="Română"
                                    src="/images/romania.svg"
                                />
                                Română
                            </MenuItem>}
                        </StyledSelect>
                    </header>
                </header>}
        </>
    );
}

export default Navbar;
