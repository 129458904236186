import React from 'react';
import classes from '../UserMainPage.module.css';
import { useTranslation } from 'react-i18next';

import {
  makeStyles,
  Typography,
  Grid,
  Container,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  withStyles,
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

import UserInfoAccordion from '../../../../components/SolarPackage/UserInfoAccordion';
import axiosInstance from '../../../../components/axios/axiosApi';

import i18n from '../../../../i18n';

const StyledDialogTitle = withStyles({
  root: {
    fontSize: 20,
  }
})(DialogTitle);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none',
    fontSize: '1rem',
    width: '50%'
  },
}))(TableCell);

const AfterPayment = ({
  userInfo,
  imagePreview,
  expandUserInfo,
  setExpandUserInfo,
  purhasedPackageInfo
}) => {

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 991px)'});

  const [open, setOpen] = React.useState(false);

  const { t, } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const requestRefund = async () => {
    const lng = (localStorage.getItem('dil') || String(i18n.language).split('-')[0]).toUpperCase();
    await axiosInstance.post(`home/yapikredi/refundCredit`)
      .then((response) => {
        if (response.data.status) {
          window.location.reload();
        } else {
          alert('Bir hata ile karşılaştık. Şu an bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyin.');
        }
      })
      .catch((error) => console.log(error));
    console.log('xx');
  }

  return (
    <Container 
      maxWidth={true}
      style={{ height: '100%', backgroundColor:'#F4F6F8', paddingTop: '20px' }}
    >
      <Grid 
        container
        spacing={3}
      >
        <Grid
          item
          lg={8}
          md={12}
          xl={8}
          xs={12}
        >
          <UserInfoAccordion
            userInfo={userInfo}
            imagePreview={imagePreview}
            expandUserInfo={expandUserInfo}
            setExpandUserInfo={setExpandUserInfo}
            isAccordion={false}
          />
        </Grid>
        <Grid
          item
          lg={4}
          md={12}
          xl={4}
          xs={12}
        >
          <Container 
            maxWidth={true}
            style={{ borderRadius: '10px', backgroundColor:'#ffffff', padding: '40px' }}
          >
            <Grid 
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <h2 style={{ fontSize:'1.8rem', fontWeight: 'normal' }}>{t('AfterPayment.packageInfo')}</h2>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Table style={{ marginTop: 10 }}>
                    <TableBody>
                      <TableRow
                        key="name"
                      >
                        <StyledTableCell>
                          <strong>{t('AfterPayment.name')}</strong>
                        </StyledTableCell>
                        <StyledTableCell>
                          {purhasedPackageInfo.name}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        key="fullPrice"
                      >
                        <StyledTableCell>
                          <strong>{t('AfterPayment.vatPrice')}</strong>
                        </StyledTableCell>
                        <StyledTableCell>
                        $ {purhasedPackageInfo.priceTLKDV}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow
                        key="empty"
                      >
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                      <TableRow
                        key="refund"
                      >
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell style={{ fontSize: '12px' }}>
                          {t('AfterPayment.refundText1')}
                          <span
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={handleClickOpen}
                          >{t('AfterPayment.refundText2')}</span>
                          {t('AfterPayment.refundText3')}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title" disableTypography>{t('AfterPayment.refundDialogText1')}</StyledDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('AfterPayment.refundDialogText2')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          {t('AfterPayment.back')}
          </Button>
          <Button onClick={requestRefund} color="primary" variant="contained">
          {t('AfterPayment.refund')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default AfterPayment;