import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../../../../containers/AlternativeNavbar/Navbar';
import { useSelector } from 'react-redux';
import classes from './KVKKPagesStyle.module.css';
import { Helmet } from "react-helmet";
import Footer from '../../../../containers/AlternativeFooter/Footer';
import testCompanyInfo from './testData';

export default function PageWrapper(props) {
  const { t, } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    companyName,
    companyPhone,
    companyMail,
    companyAddress,
    vergiDairesi,
    vergiNo,
    mersisNo,
    companyContactPerson
  } = useSelector(state => state.companyInfo);

  return (
    <>
      <Helmet>
      <title>Kişisel Verilerin İşlenmesi Aydınlatma Metni</title>
      </Helmet>
      <main className={classes.mainWrapper}>
        <Navbar/>
        <div className={classes.outerWrapper}>
          <h2>Kişisel Verilerin İşlenmesi Aydınlatma Metni</h2>
          <p className={classes.standartParagraph}>
            “{companyAddress}” adresinde kurulu bulunan {companyName} (Kısaca <span className={classes.boldItalicSpan}>"ŞİRKET"</span> olarak anılacaktır) olarak tarafımıza ait olan  {window.location.host} adresli internet sitesi, mobil uygulama ve ilgili/bağlı sair dijital alanların tamamı (Kısaca <span className={classes.boldItalicSpan}>Hizmet</span> olarak anılacaktır) üzerinde 6698 sayılı Kişisel Verilerin Korunması Kanunu’nda (<span className={classes.boldItalicSpan}>Kanun</span>) tanımlı “Veri Sorumlusu” sıfatıyla kişisel verilerinizin güvenliğini ön planda tutuyoruz ve bu nedenle de işbu Aydınlatma Metni’nde yapacağımız bilgilendirmeler vasıtasıyla Kanun’un 10. maddesi gereği aydınlatma yükümlülüğümüzü yerine getirmek ve sizi kişisel verilerinizin korunması ve işlenmesi çerçevesinde aydınlatmayı amaçlıyoruz:
          </p>
          <p className={classes.boldItalicUnderlineParagraph}>
            Kişisel Verilerinizin İşlenme Amaçları
          </p>
          <p className={classes.standartParagraph}>
            Kişisel verileriniz Kanun ve Kanun’a bağlı sair ikincil düzenlemelere ve Kurum kararlarına uygun olarak aşağıda belirtilen amaçlar ve hukuki sebepler çerçevesinde işlenmektedir:
          </p>
          <p className={classes.standartParagraph}>
            <span className={classes.justBoldSpan}>Üyelik İşlemleri:</span> Kişisel verileriniz, üyeliğinizin tamamlanması ve yönetilmesi amacıyla işlenir. Sizlere <span className={classes.boldItalicSpan}>Hizmet</span>’e ilişkin kullanımlarınıza dair iletiler göndermek de bu amaç kapsamındadır. Üyelik işlemleri sırasında kişisel verilerini paylaştığınız -varsa- sair üçüncü kişilerin, kişisel verilerinin sizin tarafınızdan <span className={classes.boldItalicSpan}>ŞİRKET</span> ile paylaşıldığının farkında olmalarının ve <span className={classes.boldItalicSpan}>ŞİRKET</span>’un verileri kullanım biçimini kabul etmelerinin sağlanması mutlaka olarak sadece sizin sorumluluğunuzdadır.
          </p>
          <p className={classes.standartParagraph}>
          <span className={classes.justBoldSpan}>Üye Hesabı İşlemleri: </span><span className={classes.boldItalicSpan}>Hizmet</span>’e üye olarak üyelikle ilgili işlemleri yönetmeniz mümkündür. Üye hesabı oluşturmanız halinde paylaştığınız kişisel veriler, hesabınızdan en iyi şekilde faydalanmanız amacıyla işlenecektir. 
          </p>
          <p className={classes.standartParagraph}>
          <span className={classes.justBoldSpan}>İletişim: </span> <span className={classes.boldItalicSpan}>Hizmet</span> üzerinden bize sağladığınız kişisel verileri kullanarak sizinle çeşitli amaçlarla iletişime geçebiliriz. Hatırlatma ve uyarı iletilerinin gönderilmesi ve bize iletmiş olduğunuz mesajların yanıtlanması bunların başlıcalarıdır. 
          </p>
          <p className={classes.standartParagraph}>
          <span className={classes.justBoldSpan}>Hukuki Yükümlülükler: </span> Kişisel verileriniz <span className={classes.boldItalicSpan}>Hizmet</span>’in tabi olduğu herhangi bir mevzuat hükmünün gerektirmesi halinde işlenebilir, aktarılabilir ve ilgili mevzuatta öngörülen ya da amaç için gerekli olan süre boyunca saklanabilir. 
          </p>
          <p className={classes.standartParagraph}>
          <span className={classes.justBoldSpan}>Uyuşmazlıkların Çözümü: </span> <span className={classes.boldItalicSpan}>ŞİRKET</span>, ileride ortaya çıkabilecek uyuşmazlıklarda hukuka uygun işlemler gerçekleştirdiğini ve yasal yükümlülüklerini yerine getirdiğini ispatlamak ve uyuşmazlıkların çözümünü sağlamak amacıyla kişisel verilerinizi işleyebilir, ilgili yasal merciler ile paylaşabilir. 
          </p>
          <p className={classes.standartParagraph}>
          <span className={classes.justBoldSpan}>Sunulan Servislerin İyileştirilmesi ve Kullanıcı Memnuniyeti: </span> Kişisel verileriniz <span className={classes.boldItalicSpan}>Hizmet</span> üzerindeki deneyiminizi iyileştirmek amacıyla analitik çalışmaların yürütülmesi, talep, şikâyet ve önerilerinizin değerlendirilerek kullanıcı memnuniyetini ve servis kalitesini artırmaya yönelik aksiyonlar alınması amaçlarıyla işlenmektedir. Ayrıca soru/cevap ve benzeri her türden değerlendirmelerinizin, yorumlarınızın ve benzeri şekildeki içeriğinizin <span className={classes.boldItalicSpan}>Hizmet</span> kanalıyla <span className={classes.boldItalicSpan}>Hizmet</span> ya da sair sosyal medya siteleri ve uygulamaları üzerinde yayınlanması da <span className={classes.boldItalicSpan}>ŞİRKET</span>’un sair meşru menfaatleri kapsamında gerçekleştirilebilmektedir.
          </p>
          <p className={classes.standartParagraph}>
          <span className={classes.justBoldSpan}>Reklam: </span> Açık rıza vermeniz halinde bilgileriniz çeşitli reklam faaliyetleri için kullanabilecektir. Bu kapsamda kişisel verileriniz, potansiyel kullanıcı kaydı oluşturmak, kampanya ve fırsatlardan sizleri haberdar etmek, profilleme, hedefleme ve reklam faaliyetleri yürütmek, geçmiş tercihlerinize ve beğenilerinize en uygun teklifleri karşınıza çıkarmak, <span className={classes.boldItalicSpan}>Hizmet</span>’e ilişkin arayüzler üzerindeki kayıtlı tercihlerinize uygun olarak e-bülten göndermek vb. amaçlarıyla işlenebilecektir. Kişisel verileriniz, reklam faaliyetlerine onay vermediğiniz ya da onayınızı geri aldığınız durumlarda söz konusu amaçlarla işlenmez. 
          </p>
          <p className={classes.boldItalicUnderlineParagraph}>
          Kişisel Verilerinizin İşlenmesinin Hukuki Sebepleri
          </p>
          <p className={classes.standartParagraph}>
          Kişisel verileriniz Kanun’da yer alan hukuki sebeplerden en az birinin varlığı halinde <span className={classes.boldItalicSpan}>Hizmet</span>’e ilişkin arayüzler ve sair teknolojiler üzerinden/aracılığı ile işlenmektedir. Yukarıda belirtilen kişisel veri işleme amaçlarının dayandığı hukuki sebepler şu şekildedir:
          </p>
          <ul>
            <li>Üyelik işlemleri ve bağlı hareketler, Kanun’un 5/2(c) maddesi hükmünde belirtilen bir sözleşmenin kurulması ya da ifası hukuki sebebine dayalı olarak gerçekleştirilmektedir.</li>
            <li>Hukuki yükümlülüklerimizin yerine getirilmesi amacıyla gerçekleştirdiğimiz veri işleme faaliyetleri, Kanun’un 5/2 (ç) maddesinde açıkça belirtilen hukuki sebebe dayanır.</li>
            <li>Uyuşmazlıkların çözümü için kişisel verilerin işlendiği haller, Kanun’daki 5/2(e) hükmünde belirtilen bir hakkın tesisi, kullanılması veya korunması hukuki sebebine dayanır.</li>
            <li>Pazarlama ve reklam amaçlı faaliyetler, Kanun’un 5/1 maddesinde belirtilen açık rıza hukuki sebebine dayalı olarak gerçekleştirilebilir.</li>
            <li>Sunulan sair servislerin iyileştirilmesi ve müşteri memnuniyeti ile iletişim amaçlarına dayalı faaliyetler, Kanun’un 5/2(f) hükmünde düzenlenen meşru menfaat hukuki sebebine dayalı olarak yürütülmektedir.</li>
          </ul>
          <p className={classes.boldItalicUnderlineParagraph}>
          Kişisel Verilerin Aktarılması
          </p>
          <ul>
            <li><span className={classes.boldItalicSpan}>Hizmet</span> üzerinden gerçekleştirdiğiniz işlemlerin tamamlanması ve de <span className={classes.boldItalicSpan}>ŞİRKET</span> tarafından sair şekilde değerlendirilmesi ve de yönetilmesi amacıyla kişisel verileriniz ilgili kurum ve kuruluşlarla paylaşılabilir. </li>
            <li>Bunun yanı sıra kişisel verilerinizin Kanun’un 8. ve 9. maddelerinde belirtilen şartlar çerçevesinde <span className={classes.boldItalicSpan}>ŞİRKET</span>’in iş ortakları, dış kaynak alımı gerçekleştirdiği tedarikçileri ve yasal yükümlülüklerin yerine getirilmesi kapsamında talep edilmesi halinde ilgili kamu kurum ve kuruluşlarıyla paylaşılabilir. Kişisel verilerinizin pazarlama, profilleme ve reklam faaliyetlerinin yürütülmesi amacıyla işlenmesine izin vermiş olmanız halinde söz konusu faaliyetleri <span className={classes.boldItalicSpan}>ŞİRKET</span> adına gerçekleştiren çözüm ortaklarına da veri aktarımı yapılması mümkündür.</li>
          </ul>
          <p className={classes.boldItalicUnderlineParagraph}>
          Kişisel Verilerin Toplanma Yöntemleri
          </p>
          <ul>
            <li><span className={classes.boldItalicSpan}>ŞİRKET</span>, size sunduğu tüm servisler ve yukarıda belirtilen veri işleme amaçları kapsamında kişisel verilerinizi elektronik ortamda çağrı merkezi ve e-posta kanallarıyla ve de <span className={classes.boldItalicSpan}>Hizmet</span> üzerinde yer alan her türden veri gerişi imkânı olan boşluklar, yorum kutuları, cevap kısımları, üyelik kayıt formları ve sair formlar ya da olması halinde canlı destek paneli ve de ilgili analiz araçları vasıtasıyla toplayabilmektedir. Bunun yanı sıra <span className={classes.boldItalicSpan}>Hizmet</span> dâhilinde her şekilde kullanılabilen endüstri standardı olan çerezler aracılığıyla da kişisel verileriniz elde edilmektedir. </li>
            <li>Çerezlere ilişkin detaylı bilgi için de <span className={classes.boldItalicUnderlineSpan}>Çerezler ve Çerez Kullanımı İzinleri Hakkında Bildirim</span>’i ziyaret ediniz.</li>
          </ul>
          <p className={classes.boldItalicUnderlineParagraph}>
          Veri Sahibi Olarak Haklarınız
          </p>
          <ul>
            <li>Veri sahibi olarak bize info@solarvis.co e-posta adresi ya da resmi şirket adresi üzerinden başvurarak;</li>
            <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
            <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
            <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
            <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
            <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme haklarına sahipsiniz.</li>
            <li>Yukarıda sayılan haklarınıza ilişkin taleplerinizi <span className={classes.boldItalicUnderlineSpan}>Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ</span>’de öngörülen başvuru usullerine uygun olarak bize iletmeniz durumunda talebiniz niteliğine göre en kısa sürede ve en geç 30 (otuz) gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde objektif ve şeffaf bir politika çerçevesinde makul bir ücret talep edilebilir. </li>
            <li>Kişisel verilerinizin işlenmesi ile veri güvenliğinin sağlanması konularında daha detaylı bilgiye <span className={classes.boldItalicUnderlineSpan}>Kişisel Verilerin İşlenmesi ve Korunması Politikası</span>’ndan ulaşabilirsiniz.</li>
          </ul>
          <p className={classes.standartParagraph}>
          Kanun kapsamındaki haklarınızı kullanabilmek için,
          </p>
          <p className={classes.standartParagraph}>
          E-posta yöntemi ile erişmek için {companyMail} e-posta adresine,
          </p>
          <p className={classes.standartParagraph}>
          Yazılı talepleriniz için {companyName}, {companyAddress}, adresine başvurabilirsiniz.
          </p>
          <p className={classes.standartParagraph}>
          VERİ SORUMLUSU:{companyName}
          </p>
          <p className={classes.standartParagraph}>
          İrtibat Kişisi: {companyContactPerson}
          </p>
          <p className={classes.standartParagraph}>
          Adres: {companyAddress}
          </p>
          <p className={classes.standartParagraph}>
          Telefon Numarası: {companyPhone}
          </p>
          <p className={classes.standartParagraph}>
          Vergi Dairesi-Numarası: {vergiDairesi} - {vergiNo}
          </p>
          <p className={classes.standartParagraph}>
          MERSİS Numarası: {mersisNo}
          </p>
          <p className={classes.standartParagraph}>
          E-posta Adresi: {companyMail}
          </p>
          <p className={classes.standartParagraph}>
          Web Adresi: {window.location.host}
          </p>
        </div>
        <Footer />
      </main>
      
  </>
  )
}