import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  panel: null,
  fatura: JSON.parse(localStorage.getItem('faturaTutarı')) || 3,
  batarya: JSON.parse(localStorage.getItem('bataryaSecenegi')) || false,
  sarj: JSON.parse(localStorage.getItem('sarjSecenegi')) || false,
  tesis: localStorage.getItem('tesisTipi') || 'Mesken',
  meskenTipi: localStorage.getItem('meskenTipi') || 'Müstakil',
  roofTilt: localStorage.getItem('roofTilt') || '0',
  cephe: localStorage.getItem('cephe') || '0',
  baseType: localStorage.getItem('baseType') || 'roof',
  tlMaliyet: '-',
  yillikUretim: null,
  usdMaliyet: null,
  amortisman: null,
  yaklasikAmortisman: '-',
  amortismanYilAy: ['-', '-'],
  yaklasikMaliyet: '-',
  agac: null,
  co2: null,
  yillikTasarruf: '-',
  yillikKazanc: '-',
  sGucu: JSON.parse(localStorage.getItem('sozlesmeGucu')) || 5,
  trafoGucu: JSON.parse(localStorage.getItem('trafoGucu')) || 10,
  isim: localStorage.getItem('isim') || '',
  soyisim: localStorage.getItem('soyisim') || '',
  telefon: localStorage.getItem('telefon') || '',
  mail: localStorage.getItem('mail') || '',
  endUserCompanyName: localStorage.getItem('endUserCompanyName') || '',
  tckn: localStorage.getItem('tckn') || '',
  vkn: localStorage.getItem('vkn') || '',
  consumptionProfile: localStorage.getItem('consumptionProfile') || "family"
};

export const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    changePanel: (state, action) => {
      state.panel = action.payload.panel;
    },
    changeFatura: (state, action) => {
        state.fatura = action.payload.fatura;
        localStorage.setItem('faturaTutarı', JSON.stringify(action.payload.fatura));
    },
    changeBatarya: (state, action) => {
        state.batarya = action.payload.batarya;
        localStorage.setItem('bataryaSecenegi', JSON.stringify(action.payload.batarya));
    },
    changeSarj: (state, action) => {
        state.sarj = action.payload.sarj;
        localStorage.setItem('sarjSecenegi', JSON.stringify(action.payload.sarj));
    },
    changeTesis: (state, action) => {
        state.tesis = action.payload.tesis;
        localStorage.setItem('tesisTipi', action.payload.tesis);
    },
    changeMeskenTipi: (state, action) => {
      state.meskenTipi = action.payload.meskenTipi;
      localStorage.setItem('meskenTipi', action.payload.meskenTipi);
  },
    changeEgim: (state, action) => {
        state.roofTilt = action.payload.roofTilt;
        localStorage.setItem('roofTilt', action.payload.roofTilt);
    },
    changeCephe: (state, action) => {
      state.cephe = action.payload.cephe;
      localStorage.setItem('cephe', action.payload.cephe);
    },
    changeBaseType: (state, action) => {
      state.baseType = action.payload.baseType;
      localStorage.setItem('baseType', action.payload.baseType);
    },
    changeConsumptionProfile: (state, action) => {
      state.consumptionProfile = action.payload.consumptionProfile;
      localStorage.setItem('consumptionProfile', action.payload.consumptionProfile);
    },
    changeTLMaliyet: (state, action) => {
        state.tlMaliyet = action.payload.tlMaliyet;
    },
    changeUSDMaliyet: (state, action) => {
        state.usdMaliyet = action.payload.usdMaliyet;
      },
    changeUretim: (state, action) => {
        state.yillikUretim = action.payload.yillikUretim;
      },
    changeAmortisman: (state, action) => {
        state.amortisman = action.payload.amortisman;
      },
    changeYaklasikAmortisman: (state, action) => {
        state.yaklasikAmortisman = action.payload.yaklasikAmortisman;
      },
    changeAmortismanYilAy: (state, action) => {
        state.amortismanYilAy = action.payload.amortismanYilAy;
      },
    changeYaklasikMaliyet: (state, action) => {
        state.yaklasikMaliyet = action.payload.yaklasikMaliyet;
      },
    changeAgac: (state, action) => {
        state.agac = action.payload.agac;
      },
    changeCo2: (state, action) => {
        state.co2 = action.payload.co2;
      },
    changeYillikTasarruf: (state, action) => {
        state.yillikTasarruf = action.payload.yillikTasarruf;
      },
    changeYillikKazanc: (state, action) => {
        state.yillikKazanc = action.payload.yillikKazanc;
      },
    changeSozlesmeGucu: (state, action) => {
        state.sGucu = action.payload.sGucu;
        localStorage.setItem('sozlesmeGucu', JSON.stringify(action.payload.sGucu));
      },
    changeTrafoGucu: (state, action) => {
        state.trafoGucu = action.payload.trafoGucu;
        localStorage.setItem('trafoGucu', JSON.stringify(action.payload.trafoGucu));
      },
    changeIsim: (state, action) => {
      state.isim = action.payload.isim;
      localStorage.setItem('isim', action.payload.isim);
    },
    changeSoyisim: (state, action) => {
      state.soyisim = action.payload.soyisim;
      localStorage.setItem('soyisim', action.payload.soyisim);
    },
    changeTelefon: (state, action) => {
      state.telefon = action.payload.telefon;
      localStorage.setItem('telefon', action.payload.telefon);
    },
    changeEndUserCompanyName: (state, action) => {
      state.endUserCompanyName = action.payload.endUserCompanyName;
      localStorage.setItem('endUserCompanyName', action.payload.endUserCompanyName);
    },
    changeVKN: (state, action) => {
      state.vkn = action.payload.vkn;
      localStorage.setItem('vkn', action.payload.vkn);
    },
    changeTCKN: (state, action) => {
      state.tckn = action.payload.tckn;
      localStorage.setItem('tckn', action.payload.tckn);
    },
    changeMail: (state, action) => {
      state.mail = action.payload.mail;
      localStorage.setItem('mail', action.payload.mail);
    },
    resetSummary: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const { changePanel, changeFatura, changeBatarya, changeSarj, changeTesis, changeMeskenTipi, changeEgim, changeCephe, changeBaseType, changeConsumptionProfile, changeTLMaliyet, changeYillikKazanc, changeUSDMaliyet, changeUretim, changeAmortisman, changeYaklasikAmortisman, changeAmortismanYilAy, changeYaklasikMaliyet, changeAgac, changeCo2, changeYillikTasarruf, changeSozlesmeGucu, changeTrafoGucu, changeIsim, changeSoyisim, changeMail, changeTelefon, changeEndUserCompanyName, changeTCKN, changeVKN, resetSummary } = summarySlice.actions

export default summarySlice.reducer