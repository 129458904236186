import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#085b06',
  },
}))(LinearProgress);

export default function StepProgress(props) {
    const { stepNumber } = useSelector(state => state.steps);
    const { firstScreenAtEnd } = useSelector(state => state.companyInfo);
    const progressSettingsCompany = () => {
      if (firstScreenAtEnd === 'contact') {
        return 20;
      } else {
        return 16.66;
      }
    }
    return (
    <>
        <BorderLinearProgress variant="determinate" value={(stepNumber+1)*progressSettingsCompany()} />                 
    </>
    )
  }