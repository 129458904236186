import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../components/axios/axiosApi';
import { Navigate } from 'react-router-dom';
import classes from './QuoteSent.module.css';
import {
  Button,
  withStyles,
  Snackbar
} from '@material-ui/core';
import ReactCodeInput from 'react-code-input';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
// import MailingDialog from '../../components/Dialog/MailingDialog';
import SMSDialog from '../../components/Dialog/SMSDialog';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledButton = withStyles({
  root: {
    textTransform: 'none'
  }
})(Button);

export default function PageWrapper(props) {

  const { t, } = useTranslation();


  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarType, setSnackbarType] = useState('warning');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [ verificationCode, setVerificationCode ] = useState();
  /* const [ unreceivedMail, setUnreceivedMail ] = useState(false); */
  const [ unreceivedSMS, setUnreceivedSMS ] = useState(false);

  const { tesis } = useSelector(state => state.summary);
  const { module, availableModules } = useSelector(state => state.module);
  const { loginToken } = useSelector(state => state.users);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const handleOnChange = (event) => {
    setVerificationCode(event);
  }

  const checkAuthenticationCode = async () => {
    await axiosInstance.post('auth_verification_code?format=json', {
      verification_code: Number(verificationCode)
    })
      .then((response) => {
        const data = response.data.data;
        if (response.data.status) {
          window.location.href = "/login?token="+data["login_token"];
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarType('warning');
          setSnackbarState(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage('Şu anda bu işlemi gerçekleştiremiyoruz. Lütfen daha sonra tekrar deneyiniz.');
        setSnackbarType('warning');
        setSnackbarState(true);
        console.log(error)
      });
  }

  if (JSON.parse(localStorage.getItem('loggedIn'))) {
    return <Navigate to="/login" />
  }

  if (window.location.host === "calculator.solarvis.co") {
    return <Navigate to={`/login?token=${loginToken}`} />
  }

  return (
    <div 
      style={{
        backgroundColor: 'white',
        display:'flex',
        flexDirection: 'column',
        textAlign:'center',
        placeContent: 'center',
        placeItems: 'center'
      }}>
        <div>
          <img
            src={require('../../assets/resources/sms-verification-icon.png')}
            style={{ width: '200px', height: '200px' }}
            alt="sms-verification-icon"
          />
        </div>
        <div style={{ maxWidth: '500px' }}>
          <p style={{fontSize: '18px', padding: '0px 20px'}}>
            {t('QuoteSendPage.infoText1')}
            {t('QuoteSendPage.infoText2')}
          </p>
          {!(availableModules.includes("home") && tesis === "Mesken") &&
          <p style={{fontSize: '15px', padding: '20px', paddingBottom: '0px'}}>
            {t('QuoteSendPage.infoText3')}
          </p>}
        </div>
        <div style={{ padding: '20px 0px' }}>
          <ReactCodeInput
            type='number'
            fields={6}
            value={verificationCode}
            onChange={handleOnChange}
            autoFocus
          />
        </div>
        <div>
          <StyledButton
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={() => checkAuthenticationCode()}
          >
            {t('QuoteSendPage.verify')}
          </StyledButton>
        </div>
        <div style={{ padding: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setUnreceivedSMS(true)}>{t('QuoteSendPage.notReceivedSMS')}</span>
          {/* <span style={{ textDecoration: 'underline', cursor: 'pointer', marginTop: '10px' }} onClick={() => setUnreceivedMail(true)}>{t('QuoteSendPage.notReceivedEmail')}</span> */}
        </div>
        {/* <MailingDialog
          unreceivedMail={unreceivedMail}
          changeFunc={setUnreceivedMail}
          changeSnackbarMessage={setSnackbarMessage}
          changeSnackbarType={setSnackbarType}
          changeSnackbarState={setSnackbarState}
        /> */}
        <SMSDialog
          unreceivedSMS={unreceivedSMS}
          changeFunc={setUnreceivedSMS}
          changeSnackbarMessage={setSnackbarMessage}
          changeSnackbarType={setSnackbarType}
          changeSnackbarState={setSnackbarState}
        />
        <Snackbar open={snackbarState} autoHideDuration={5000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </div>
  )
}