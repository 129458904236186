import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  maxFatura: {
    Mesken: 2563,
    Ticarethane: 256339,
    Sanayi: 769020,
    TarımsalSulama: 128169,
  },
  avgFatura: {
    Mesken: 1000,
    Ticarethane: 100000,
    Sanayi: 300000,
    TarımsalSulama: 50000,
  },
  faturaSgucuOrani: 512
};

export const constantsSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {
    changeMaxFatura: (state, action) => {
      state.maxFatura.Mesken = action.payload.Mesken;
      state.maxFatura.Ticarethane = action.payload.Ticarethane;
      state.maxFatura.Sanayi = action.payload.Sanayi;
      state.maxFatura.TarımsalSulama = action.payload.TarımsalSulama;
    },
    changeAvgFatura: (state, action) => {
      state.avgFatura.Mesken = action.payload.Mesken;
      state.avgFatura.Ticarethane = action.payload.Ticarethane;
      state.avgFatura.Sanayi = action.payload.Sanayi;
      state.avgFatura.TarımsalSulama = action.payload.TarımsalSulama;
    },
    changeFaturaSgucuOrani: (state, action) => {
      state.faturaSgucuOrani = action.payload;
    },
    resetConstants: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const { 
  changeMaxFatura,
  changeAvgFatura,
  changeFaturaSgucuOrani,
  resetConstants
} = constantsSlice.actions

export default constantsSlice.reducer