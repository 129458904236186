import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  module: 'solarvis',
  discoveryRequest: JSON.parse(localStorage.getItem('discoveryRequest')) || false,
  availableModules: JSON.parse(localStorage.getItem('availableModules')) || [],
  solarvisBannerState: JSON.parse(localStorage.getItem('solarvisBannerState')) || false,
};

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    changeModule: (state, action) => {
      state.module = action.payload.module;
    },
    changeAvailableModules: (state, action) => {
      state.availableModules = action.payload;
      localStorage.setItem('availableModules', JSON.stringify(action.payload));
    },
    changeDiscoveryRequest: (state, action) => {
      state.discoveryRequest = action.payload;
      localStorage.setItem('discoveryRequest', JSON.stringify(action.payload));
    },
    changeSolarvisBannerState: (state, action) => {
      state.solarvisBannerState = action.payload;
      localStorage.setItem('solarvisBannerState', JSON.stringify(action.payload));
    },
    resetModule: () => initialState,
  }
})

// Action creators are generated for each case reducer function
export const { changeModule, changeModuleStatus, changeAvailableModules, changeDiscoveryRequest, changeSolarvisBannerState, resetModule } = moduleSlice.actions

export default moduleSlice.reducer