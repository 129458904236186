import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../components/axios/axiosApi';
import {
  Card,
  Container,
  Grid,
  Box,
  Button,
  withStyles,
  makeStyles,
  Divider,
  Typography,
  CardContent,
  CardMedia,
  CardActions,
  DialogContent,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from '@material-ui/core';

const StyledCardContent = withStyles({
  root: {
    position: 'relative'
  }
})(CardContent);

const StyledButton = withStyles({
  root: {
    textTransform: 'none'
  }
})(Button);

const StyledDivider = withStyles({
  root: {
    margin: '0px 6px'
  }
})(Divider);

const StyledTypography = withStyles({
  root: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    '@media (max-width:900px)': {
      fontSize: 14
    },
    textAlign: 'start',
    marginLeft: '10px'
  }
})(Typography);


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tabRoot: {
    width: 'fit-content'
  },
  list: {
    padding: '5px',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#f7e2e2',
    color: '#ad3737'
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row'
  },
  card: {
    width: '100%',
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    padding: 8,
    fontFamily: 'Poppins',
    borderBottom: 'none'
  },
}))(TableCell);

const SolarMaterial = (props) => {
  const { material } = props;
  const MUIClasses = useStyles();

  const { t, } = useTranslation();

  const excludeList = ['kalem', 'birim_1', 'deger_1', 'birim_2', 'deger_2', 'solar_package_id_id', 'id', 'image_path', 'name']

  const titles = {
    mainTitles: {
      solar_panel: t('SolarPackageCard.panel'),
      solar_cable: t('SolarPackageCard.cable'),
      inverter: t('SolarPackageCard.inverter'),
      construction_material: t('SolarPackageCard.construction'),
      electrical_panel: t('SolarPackageCard.board')
    },
    fieldTitles: {
      marka_model: t('SolarPackageCard.brand'),
      specs: t('SolarPackageCard.specs'),
      url: t('SolarPackageCard.productWebsite'),
      deger_1: t('SolarPackageCard.value1'),
      deger_2: t('SolarPackageCard.value2'),
    }
  };

  const units = {
    solar_panel: {
      birim_1: 'Wp',
      birim_2: t('SolarPackageCard.number')
    },
    inverter: {
      birim_1: 'kW',
      birim_2: t('SolarPackageCard.number'),
    },
    construction_material: {
      birim_1: '',
      birim_2: '',
    },
    electrical_panel: {
      birim_1: '',
      birim_2: '',
    },
    solar_cable: {
      birim_1: t('SolarPackageCard.meter'),
      birim_2: '',
    },
  };

  return (
    <>
      <Card className={MUIClasses.card}>
        <CardMedia
          component="img"
          alt={material.human_readable_material_category}
          height="200"
          image={material.package_material_image_presigned_url}
          title={material.human_readable_material_category}
          style={{ objectFit: 'contain' }}
        />
        <Divider variant="middle" />
        <StyledCardContent>
          <div className={MUIClasses.list}>
            {['solar_panel', 'inverter'].includes(material.material_category) &&
            <h3 style={{ marginLeft: '5px', marginBottom: '10px' }}>
              {material.human_readable_material_category} - {material.material_amount_value} {t('SummaryPage.adet')} {material.power_value} {material.power_unit}
            </h3>
            }
            {!['solar_panel', 'inverter'].includes(material.material_category) &&
            <h3 style={{ marginLeft: '5px', marginBottom: '10px' }}>
              {material.human_readable_material_category}
            </h3>
            }
            <Table>
              <TableBody>
                {material.product_name && <TableRow
                  key="product_name"
                >
                  <StyledTableCell>
                    <span>{t('SolarPackageCard.productModel')}</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <span>{material.product_name}</span>
                  </StyledTableCell>
                </TableRow>}
                {material.specs.length > 0 && material.specs.map((materialSpec) => (
                  <>
                    <TableRow
                      key={materialSpec.human_readable_spec_key}
                    >
                      <StyledTableCell>
                        <span>{materialSpec.human_readable_spec_key}</span>
                      </StyledTableCell>
                      <StyledTableCell>
                        {materialSpec.value.includes('http') && <a href={materialSpec.value} target="_blank">{materialSpec.value}</a>}
                        {!materialSpec.value.includes('http') && <span>{materialSpec.value}</span>}
                      </StyledTableCell>
                    </TableRow>
                  </>
                ))}
                {material.datasheet_presigned_url && <TableRow
                  key="datasheet_presigned_url"
                >
                  <StyledTableCell>
                    <span>{t('SolarPackageCard.tecnicalDocument')}</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    <a href={material.datasheet_presigned_url} target="_blank">{t('SolarPackageCard.download')}</a>
                  </StyledTableCell>
                </TableRow>}
              </TableBody>
            </Table>
          </div>
        </StyledCardContent>
      </Card>
    </>
  );
}

export default SolarMaterial;