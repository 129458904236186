/* eslint-disable prefer-template */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavbarMain from '../../containers/NavbarMainPage/NavbarMain';
import { Container, Grid } from '@material-ui/core';
import classes from './UserDocumentUploadPage.module.css';
import UserLoginPage from '../UserLoginPage/UserLoginPage';
import Tracker from '../../components/ZolarTracker/ZolarTracker';
import StartSubcon from './Subcontractor/StartPage';
import FileUploadPage from './Subcontractor/FileUploadPage';
import axiosInstance from '../../components/axios/axiosApi';
import UserPageFooter from '../../containers/UserPageFooter/UserPageFooter';

const DocumentUploadPage = () => {

  const dispatch = useDispatch();

  const { uploadStepNumber } = useSelector(state => state.steps);
  const { availableModules } = useSelector(state => state.module);

  const[documentCategoryList, setDocumentCategoryList] = React.useState([]);

  const fetchDocumentList = () => {
    axiosInstance.get('subcon/document/document_category_APIs/end_user_get_document_categories')
      .then((response) => {
        const { status } = response.data;
        if (status) {
          const { data, message } = response.data;
          setDocumentCategoryList(data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
      fetchDocumentList();
  }, [availableModules]);

  if (!JSON.parse(localStorage.getItem('loggedIn'))) {
    return <UserLoginPage />
  }

  return (
    <div className={classes.outerWrapper}>
        <NavbarMain />
        <Container 
            maxWidth={true}
            style={{ height: '100%', backgroundColor:'#F4F6F8', paddingBottom: (uploadStepNumber === 0) ? '14vh' : 0, minHeight: '85vh' }}
        >
            <Grid 
                container
                spacing={3}
            >
                {uploadStepNumber === 0 && <StartSubcon />}
                {documentCategoryList.map((category, index) => 
                    (uploadStepNumber === index+1 && <FileUploadPage stepNumber={index+1} documentCategory={category} /> )
                )}
            </Grid>
            {uploadStepNumber!==0 && <Tracker stepCount={documentCategoryList.length} />}
        </Container>
        <UserPageFooter />
    </div>
  );
};

export default DocumentUploadPage;
