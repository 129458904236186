import React from "react";
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from 'recharts';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const getTitle = (label, translationObject) => {
  if (label === "normalFatura") {
    return translationObject('SummaryPage.normalFatura');
  }
  if (label === "gesFatura") {
    return translationObject('SummaryPage.gesFatura');
  }
  if (label === "faturaFarki") {
    return translationObject('SummaryPage.faturaFarki');
  }
  if (label === "satis") {
    return translationObject('SummaryPage.satis');
  }
  return "";
};

const CustomTooltip = ({ active, payload, label, translationObject }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: "white", padding: "10px" }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>{`${label}`}</p>
        <p className="label">{`${getTitle(payload[0].dataKey, translationObject)}: ${payload[0].value} ₺`}</p>
        <p className="label">{`${getTitle(payload[1].dataKey, translationObject)}: ${payload[1].value > 0 ? payload[1].value : 0} ₺`}</p>
        <p className="label">{`${getTitle(payload[2].dataKey, translationObject)}: ${Math.abs(payload[2].value)} ₺`}</p>
        <p className="label">{`${getTitle(payload[3].dataKey, translationObject)}: ${Number(payload[3].value[0]) - Number(payload[3].value[1])} ₺`}</p>
      </div>
    );
  }

  return null;
};

const TooltipStardart = ({ active, payload, label, translationObject }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: "white", padding: "10px" }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>{`${label}`}</p>
        <p className="label">{`${getTitle(payload[0].dataKey, translationObject)}: ${payload[0].value} ₺`}</p>
        <p className="label">{`${getTitle(payload[1].dataKey, translationObject)}: ${payload[1].value > 0 ? payload[1].value : 0} ₺`}</p>
        <p className="label">{`${getTitle(payload[2].dataKey, translationObject)}: ${Number(payload[2].value[0]) - Number(payload[2].value[1])} ₺`}</p>
      </div>
    );
  }

  return null;
};

function ComposedLine(props) {
  const { t, } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <ResponsiveContainer width="100%" height="70%" minHeight="50%">
      <ComposedChart
        width={500}
        height={400}
        data={props.graphData}
        margin={{
          top: isTabletOrMobile ? 40 : 20,
          right: 20,
          bottom: 50,
          left: 50,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        {props.graphData?.some(item=> item.satis!=0) ? <Tooltip content={<CustomTooltip translationObject={t} />} /> : <Tooltip content={<TooltipStardart translationObject={t} />} /> }
        <XAxis
          dataKey="month"
          scale="band"
          angle={-45}
          height={30}
          tickMargin={20}
          minTickGap={0}
        />
        <YAxis type="number" unit=" ₺" tickMargin={10} width={80} domain={['auto', 'auto']}>
          <Label
            value={t('SummaryPage.faturaTutari')}
            offset={20}
            position="left"
            angle={-90}
          />
        </YAxis>
        <Tooltip />
        {isTabletOrMobile && <Legend verticalAlign="top" height={50} width={300} wrapperStyle={{ top: 1 }} />}
        {!isTabletOrMobile && <Legend verticalAlign="top" height={60} />}
        <Line
          name={t('SummaryPage.normalFatura')}
          type="monotone"
          dataKey="normalFatura"
          stroke="#CF0A2C"
          activeDot={{ r: 8 }}
          strokeWidth={3}
        />
        <Line
          name={t('SummaryPage.gesFatura')}
          type="monotone"
          dataKey="gesFatura"
          stroke="#4D4D4F"
          activeDot={{ r: 8 }}
          strokeWidth={3}
        />
        {/* <Area type="monotone" dataKey="gestenSonraFatura" stackId="1" stroke="#ffffff" fill="#ffffff" /> */}
        {props.graphData?.some(item=> item.satis!=0) && <Area name={t('SummaryPage.satis')} type="monotone" dataKey="satis" stroke="none" legendType="square" fill="#307941" />}
        
        <Area name={t('SummaryPage.tasarrufMiktari')} type="monotone" dataKey="faturaFarki" legendType="square" stroke="none" fill="#88cd98" /> 
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default ComposedLine;
